import React, { useEffect, useState } from "react";
import { BASE_URL_UI } from "../../../config/config";
import { CLIENT_ID } from "../../../config/config";
import { HandlesService } from '../../../core/services/handles.service';
import { connect } from 'react-redux';
import CommonPopUpCentered from "../../../components/popup/common-popup-centered";
import { Link, matchPath, Navigate, Outlet, useLocation } from "react-router-dom";
import { Loading } from "../../../redux/action";
import { InstagramDMAutomation } from "../../../core/services/dm-automation-instagram.service";
import * as gtUtil from "../../../core/utility";

const instagramDMAutomationService: InstagramDMAutomation = new InstagramDMAutomation();
const handlesService = new HandlesService();

interface Props {
  userData: any;
}

const InfluencerTools: React.FC<Props> = (props: Props) => {
  const [openConnectInstagram, setOpenConnectInstagram] = useState(false)
  const [openAdditionalSettingsModal, setOpenAdditionalSettingsModal] = useState(false)
  const [openPermissionMissingModal, setOpenPermissionMissingModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [instgramAllPermissions, setInstgramAllPermissions] = useState(false)
  const [influencerAccounts, setInfluencerAccounts] = useState<any>([]);
  const redirectUri = '/influencer/tools/new-automation'

  // let instgramAllPermissions: boolean = false// If all values are true it will be true else false

  const handleCloseConnectInstagramModal = () => {
    setOpenConnectInstagram(false);
  }

  const handleOpenConnectInstagramModal = () => {
    // influencerAccounts?.length < 1 ? setOpenConnectInstagram(true) : setOpenAdditionalSettingsModal(true)
    if (influencerAccounts?.length < 1) {
      setOpenConnectInstagram(true);
    } else if (!instgramAllPermissions) {
      setOpenPermissionMissingModal(true);
    } else {
      setOpenAdditionalSettingsModal(true);
    }
  }

  const handleCloseAdditionalSettingsModal = () => {
    setOpenAdditionalSettingsModal(false);
  }

  // const handleOpenAdditionalSettingsModal = () => setOpenAdditionalSettingsModal(true);

  const location = useLocation();

  // Check if we are not on the new-automation path
  const isExactToolsPath = matchPath('/influencer/tools', location.pathname);
  const handleCloseModal = () => {
    setOpenAdditionalSettingsModal(false);
    setOpenConnectInstagram(false);
    setOpenPermissionMissingModal(false)
  }

  // Get all accounts of the influencer
  async function getInfluencerAccounts() {
    try {
      setLoading(true);
      const res = await instagramDMAutomationService.getInfluencerAccounts({
        influencer_id: props.userData.influencer_id,
      });

      if (res.data.status) {
        setInfluencerAccounts(res.data.rows);

        // Check permissions only if accounts are present
        if (res.data.rows.length > 0) {
          checkInstagramBusinessPermissions(res.data.rows[0]);
        }
      }
    } catch (error) {
      console.error("Error fetching getInfluencerAccounts:", error);
    } finally {
      setLoading(false);
    }
  }

  //Function to check all instagram permission are true or not
  function checkInstagramBusinessPermissions(data: any) {
    try {
      // Check if all required properties are true
      const instgramPermissions =
        data?.instagram_business_basic &&
          data?.instagram_business_manage_messages &&
          data?.instagram_business_manage_comments
          ? true
          : false;
      if (instgramPermissions) {
        setInstgramAllPermissions(true)
      } else {
        setInstgramAllPermissions(false)
      }
    } catch (error) {
      console.log('error', error);
      setInstgramAllPermissions(false)
    }
  }

  useEffect(() => {
    getInfluencerAccounts()
  }, [props.userData.influencer_id])

  const handleInstagramProfile = () => {
    const clientId = `${CLIENT_ID}`;
    const redirectUri = `${BASE_URL_UI}/influencer/tools/new-automation`;
    const scope = "instagram_business_basic,instagram_business_content_publish,instagram_business_manage_messages,instagram_business_manage_comments";
    // const instagramAuthUrl = `https://www.instagram.com/oauth/authorize?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=code&scope=${encodeURIComponent(scope)}`;

    // const instagramAuthUrl = `https://www.instagram.com/oauth/authorize?enable_fb_login=0&force_authentication=1&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=code&scope=${encodeURIComponent(scope)}`;
    const instagramAuthUrl = `https://www.instagram.com/oauth/authorize?enable_fb_login=0&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=code&scope=${encodeURIComponent(scope)}`;

    window.location.href = instagramAuthUrl;
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code");
    if (code) {
      getInstagramAccessToken(code);
    }
  }, []);
  
  const getInstagramAccessToken = async (code: string) => {
    try {
      instagramDMAutomationService
        .connectInstagram({ code: code, influencer_id:props.userData.influencer_id, redirect_uri : redirectUri})
        .then((res: any) => {
          if (res.status === gtUtil.Constants.SUCCESS) {

          }
        })
        .catch((err: any) => console.log(err));
    } catch (err) {
      console.error("Failed to fetch Instagram access token", err);
    }
  };

  // useEffect(() => {
  //   const queryParams = new URLSearchParams(window.location.search);
  //   const code = queryParams.get("code");
  //   if (code) {
  //     getInstagramAccessToken(code);
  //   }
  // }, []);


  return (
    <>
      {isExactToolsPath && (
        <><section className="inf-tools">
          <button className="btn btn-purple" onClick={handleOpenConnectInstagramModal}>Instagram DM Automation</button>
          <button className="btn btn-disabled">Hashtag generator</button>
          <button className="btn btn-disabled">Generate caption</button>
        </section>
          <Link to={'/influencer/tools/new-automation'}>New Automation</Link>
          <Link to={'/influencer/tools/available-automation'}>Available Automation</Link>
        </>)}
      <Outlet />
      <CommonPopUpCentered
        centered
        className={`popup-instagram-automation modal-small  mx-auto`}
        openModal={openConnectInstagram}
        modalTitle="Connect Instagram"
        modalContent=""
        onClose={() => handleCloseConnectInstagramModal()}>
        <div className="popup-content">
          <p>Automation requires a connected account. Connect your Instagram account and set your permissions to use the feature.</p>
          <button className="btn btn-primary" onClick={handleInstagramProfile}>Connect</button>
        </div>
      </CommonPopUpCentered>
      <CommonPopUpCentered
        centered
        className={`popup-instagram-automation modal-medium  mx-auto`}
        openModal={openAdditionalSettingsModal}
        modalTitle="Important Instagram Setting"
        modalContent=""
        onClose={() => handleCloseAdditionalSettingsModal()}>
        <div className="popup-content">
          <p className="text-left">It looks like there's one key setting missing from your Instagram account. To enable Automation, please grant access to Instagram Partner messages.</p>
          <div className="text-left">
            <strong>Here’s how to do it:</strong>
            <ol className="">
              <li>Open your Instagram mobile app.</li>
              <li>{`Go to Menu > Settings and activity > Messages and story replies > Message Controls > Connected Tools.`}</li>
              <li>
                {`Toggle ON ‘Allow access to Messages’.`}</li>
            </ol>
          </div>
          <Link onClick={handleCloseModal} to="/influencer/tools/new-automation" className="btn btn-primary">It’s Done!</Link>
        </div>
      </CommonPopUpCentered>
      <CommonPopUpCentered
        centered
        className={`popup-instagram-automation modal-small  mx-auto`}
        openModal={openPermissionMissingModal}
        modalTitle="Permissions Missing"
        modalContent=""
        onClose={() => handleCloseModal()}>
        <div className="popup-content">
        <p>It looks like a few permissions are missing. Please either reconnect your account or add new one to proceed.</p>          
        <button className="btn btn-primary" onClick={handleInstagramProfile}>Connect</button>
        <p className="mt-4"><strong>Note:</strong> Connecting a new account will disconnect your currently linked account <strong>Account name</strong> and set the new account as your primary account.</p>
        </div>
      </CommonPopUpCentered>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.UserReducer.UserData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(InfluencerTools);
export { connectToRedux as InfluencerTools };
