
import React from 'react'
import { Methods } from '../../../core/utility';
import { IconButton, Skeleton, Tooltip } from '@mui/material';
import MaterialSymbol from '../../../components/icons/material-symbol';
import IconComment from '../../../components/icons/icon-comment';
import Slider from 'react-slick';

const methods = new Methods()

type Props = {
  data: []
  loading: boolean;
}

const YTMostViewedVideos = (props: Props) => {
    const settings = {
    dots: false, // Show dots for navigation
    infinite: false,
    speed: 500,
    slidesToShow: 4.25, // Show 4 posts at a time
    slidesToScroll: 1,
    swipeToSlide: true,
    centerMode: false,
    variableWidth: false,
    arrows: false,
    autoPlay: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToScroll: 1,
          variableWidth: false,
          initialSlide: 0.75,
          slidesToShow: 2.25,
          arrows: true,
        }
      },
      {
        breakpoint: 480,
        settings: {
          initialSlide: 0.75,
          slidesToScroll: 1,
          slidesToShow: 1.25,
          variableWidth: false,
          arrows: true,
        
        }
      }
    ]
  };
  return (
    <Slider key={props.loading ? "loading" : "loaded"} {...settings} className="custom-slick-slider">
  {props.loading ? (
    // Render Skeletons when loading
    Array.from({ length: 4 }).map((_, index) => (
      <div key={index} className="card-posts">
        <span className="card-posts-image embed-responsive embed-responsive-1by1">
          <Skeleton width="100%" variant="rectangular" height={315} style={{ marginBottom: "0.25rem" }} />
        </span>
        <div className="card-posts-info">
          <Skeleton width="100%" variant="rounded" height={79} />
        </div>
      </div>
    ))
  ) : props?.data?.length > 0 ? (
    props.data.map((img: any, index: number) => (
      <div key={index} className="card-posts">
        <div className="card-posts-image embed-responsive embed-responsive-1by1">
          <iframe
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            className="embed-responsive-item"
            frameBorder={0}
            height={315}
            src={`https://www.youtube.com/embed/${img?.video_id}`}
            title="YouTube video player"
            style={{ width: "100%" }}
          />
        </div>
        <div className="card-posts-info yt">
          <ul className="yt-ul-list">
            {img?.view_count > 0 && (
              <li>
                <Tooltip title="Views" arrow placement="top">
                  <IconButton className="p-0" size="small">
                    <MaterialSymbol iconName="visibility" fontweight="200" className="text-white" fontSize="24px" lineHeight="24px" />
                  </IconButton>
                </Tooltip>
                <span>{methods.convertNumber(img?.view_count)}</span>
              </li>
            )}
            {img?.comment_count > 0 && (
              <li>
                <Tooltip title="Comments" arrow placement="top">
                  <IconButton className="p-0" size="small">
                    <IconComment />
                  </IconButton>
                </Tooltip>
                <span>{methods.convertNumber(img?.comment_count)}</span>
              </li>
            )}
            {img?.estimated_minutes_watched > 0 && (
              <li>
                <Tooltip title="Est. min watched" arrow placement="top">
                  <IconButton className="p-0" size="small">
                    <MaterialSymbol iconName="timer_play" fontweight="200" className="text-white" fontSize="24px" lineHeight="24px" />
                  </IconButton>
                </Tooltip>
                <span>{methods.convertNumber(img?.estimated_minutes_watched)}</span>
              </li>
            )}
            {img?.average_view_duration > 0 && (
              <li>
                <Tooltip title="Avg. view duration" arrow placement="top">
                  <IconButton className="p-0" size="small">
                    <MaterialSymbol iconName="timelapse" fontweight="200" className="text-white" fontSize="24px" lineHeight="24px" />
                  </IconButton>
                </Tooltip>
                <span>{`${img?.average_view_duration} Mins`}</span>
              </li>
            )}
            {img?.like_count > 0 && (
              <li>
                <Tooltip title="Likes" arrow placement="top">
                  <IconButton className="p-0" size="small">
                    <MaterialSymbol iconName="thumb_up" fontweight="200" className="text-white" fontSize="24px" lineHeight="24px" />
                  </IconButton>
                </Tooltip>
                <span>{methods.convertNumber(img?.like_count)}</span>
              </li>
            )}
          </ul>
        </div>
      </div>
    ))
  ) : (
    <p>No Data Available</p>
  )}
</Slider>

  )
}

export default YTMostViewedVideos