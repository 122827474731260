// import React from 'react'

// const EngagedInfluencerTable = () => {
//   return (
//     <div>
      
//     </div>
//   )
// }

// export default EngagedInfluencerTable

import React, { useState } from 'react'
import { connect } from "react-redux";
import { Loading } from "../../../redux/action";
import "./brand-data.scss";

import { Grid, TableCell, TableRow } from '@mui/material';
import { SortingColumnsType,TableComponent } from '../../../components/table-component/table';
import { Methods } from "../../../core/utility";
import { DefaultUserProfile } from "../../../config/config";
const methods = new Methods();


interface Props {
    UserReducer: any;
    loading: (data: any) => void;
    detailedInfluencer?:any;
  }
  const columnDisplayName = {
    influencer_type: "Influencer Type",
    no_of_followers: "Followers",
    total_er: "ER",
    amount: "Amount Spent",
  };
  const sortingColumnType: SortingColumnsType[] = [
    {
      name: "influencer_type",
      columnSize: "70px",
    },
    {
      name: "no_of_followers",
      columnSize: "50px",
    },    {
      name: "total_er",
      columnSize: "70px",
    },
    {
      name: "amount",
      columnSize: "60px",
    }    
  ];

const EngagedInfluencerTable: React.FC<Props> = (props: Props) => {
  const [renderData, setRenderData] = useState<any>([]);
  const influencerColors:any = {
    "Nano": "#FFA5CB",
    "Micro": "#FFD232",
    "Micro Plus": "#63ABFD",
    "Macro": "#1CF7C2",
    "Macro Plus": "#F45DCE",
    "Mega": "#6CDBF3",
    "Mega Plus": "#48B4CB"
  };
  return (
    <div className='brand-pulse-influencer-details-table mb-3'>
      <Grid item xs={12} sm={12} md={12}>
        
        <TableComponent
          pagination={true}
          columnDisplayName={columnDisplayName}
          inputDataSource={props.detailedInfluencer}
          orderByColumnName=""
          sortingColumnType={sortingColumnType}
          renderData={renderData}
          setRenderData={(e: any) => setRenderData(e)}
        >
          {renderData.map((data: any, index: number) => {
            return (
              <TableRow key={index} >
                <TableCell>
                  <div className='d-flex'>
                  <span style={{width: '20px', height: '20px', backgroundColor: influencerColors[data.influencer_type], marginRight:'5px'}}></span>
                  {data.influencer_type}
                  </div>
                  
                </TableCell>
                <TableCell>{methods.formatInfluencerProfileNumber(data.no_of_followers)}</TableCell>
                <TableCell>{methods.formatInfluencerProfileNumber(data.total_er.toFixed(2))}%</TableCell>
                <TableCell>{methods.formatInfluencerProfileNumber(data.amount.toFixed(2))}</TableCell>
              </TableRow>
            );
          })}
        </TableComponent>
      </Grid>

    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(mapStateToProps, mapDispatchToProps)(EngagedInfluencerTable);

export { connectToRedux as  EngagedInfluencerTable };

