import React from 'react'
import { Doughnut } from "react-chartjs-2";
import {BrandErVerticalChart} from './brand-er-vertical-chart';
import { connect } from "react-redux";
import { Loading } from "../../../redux/action";
import { PieChart } from '../../../components/charts';

interface Props 
{
    UserReducer: any;
    loading: (data: any) => void;
    data:any;
    
}

const EngagedInfluencers: React.FC<Props> = (props: Props)  => {
  const donutChartOptions = {
            responsive: true,
            plugins: {
              legend: {
                display: false, // Removes the legend
              },
            },
            cutout: '70%', // This will create a donut effect by cutting out the center
          };
        
          const amountSpentDonutChartOptions = {
            responsive: true,
            plugins: {
              legend: {
                display: false, // Removes the legend
              },
            },
            cutout: '30%', // This will create a donut effect by cutting out the center
          };
const generateChartData = (engagement: any) => {
    const labels = ["Nano", "Micro", "Micro Plus", "Macro", "Macro Plus", "Mega", "Mega Plus"];
    const colors = ["#FFA5CB", "#FFD232", "#63ABFD", "#1CF7C2", "#F45DCE", "#6CDBF3", "#48B4CB"]; // Fixed colors

    return labels.map((label, index) => ({
        name: label,
        y: engagement[label] ?? 0, // Highcharts expects `{ name, y }`
        color: colors[index] ?? "#CCCCCC", // Assign a color safely
    }));
};
const generateChartDataVariablePie = (amountChart?: Record<string, number>) => {
  if (!amountChart) return []; // Return an empty array if it's undefined/null

  return Object.entries(amountChart).map(([name, y]) => ({
    name,
    y, // Assign y value from the object
    z: Math.round(y / 1000), // Example normalization
  }));
}; 
  return (
    <div className="d-flex flex-column" style={{minHeight:'655px'}}>
        <div className="row col-12 p-0 m-0">
          {props?.data?.influencerEngagement && Object.keys(props?.data?.influencerEngagement).length > 0 && (
            <div className="col-12 col-md-6 p-0" style={{}}>
                <div className="infl-engg-ratio" style={{ width: "100%", height: "",display:'flex',justifyContent:'center',paddingTop:'16px', borderBottom:'1px soild #D2D2D2' }}>
                    <div className="d-flex flex-column w-100" style={{borderBottom:'1px solid #D2D2D2'}} >
                        <p className="text-center" style={{fontWeight:'600',fontSize:'12.44px',color:'#3B5763',lineHeight:'16.99px'}}>Influencers Engagement Ratio</p>
                         {props?.data?.influencerEngagement && Object.keys(props?.data?.influencerEngagement).length > 0 && <>
                         <PieChart zMin={0} zMax={300} height={200} size='100%' innerSize="70%"  showInLegend={false} dataSource={
                            generateChartData(props?.data?.influencerEngagement)
                          } marginTop={10} spacingBottom={0} marginBottom={0}
                          
                          />
                        
                        </>
                        } 
                    </div>
               
                </div>
            </div>
          )} 
          {props?.data?.influencerEngagement && Object.keys(props?.data?.influencerEngagement).length > 0 && (
              <div className="col-12 col-md-6 p-0">
                <div className="infl-engg-er">
                    <div className="d-flex flex-column" style={{height:'',paddingLeft:'15px',paddingRight:'5px'}}>
                        <p className="text-center" style={{fontWeight:'600',fontSize:'12.44px',color:'#3B5763',lineHeight:'16.99px'}}>ER</p>
                        {props?.data?.influencerEngagement && Object.keys(props?.data?.influencerEngagement).length > 0 && <BrandErVerticalChart engagement={props?.data?.influencerEngagement} />}
                    </div>
              
                </div>
              </div>
           )}
            
        </div>
        {props.data?.amountChart && Object.keys(props.data.amountChart).length > 0 && (
          <div className="row col-12 p-0" style={{marginTop:'50px'}}>
              <div className="col-12 d-flex justify-content-center flex-column">
                <p className="text-center" style={{fontWeight:'600',fontSize:'12.44px',color:'#3B5763'}}>Amount spent </p>
                
                {/* <Doughnut data={generateChartData(props.data.amountChart)} options={amountSpentDonutChartOptions} /> */}
                <PieChart zMin={0} zMax={200} type="variablepie" height={250} innerSize='10%' itemMarginTop={0} spacingBottom={0} marginBottom={0} marginTop={10} showInLegend={false} dataSource={props.data?.amountChart ? generateChartDataVariablePie(props.data.amountChart) : []}
                
                />
              </div> 
          </div>
        )} 
        
    </div>
  )
}
const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(mapStateToProps, mapDispatchToProps)(EngagedInfluencers);

export { connectToRedux as  EngagedInfluencers };

