import React from 'react'
import MaterialSymbol from '../../../components/icons/material-symbol'
import { IconButton, Skeleton, Tooltip } from '@mui/material';

type Props = {
    title: string,
    value: any,
    multipleValue?: boolean;
    value1?:any,
    tooltipText?:string,
    tooltip?:boolean,
    loading?:boolean,
    className?:string
}

const FollowerCardInline = ({ multipleValue, title, value, value1, tooltip, tooltipText,loading, className }: Props) => {
    return (
        <div className={`card-follower-inline ${className}`}>
            <span className={`card-follower__title ${tooltip ? "d-flex align-items-center" : ""}`}>{title}
                {tooltip && 
                    <Tooltip title={tooltipText} arrow>
                        <IconButton className='p-0'>
                            <MaterialSymbol iconName='info' fontweight='200' />
                        </IconButton>
                    </Tooltip>
                }
            </span>
            {!multipleValue && <span className='card-follower__value'>{loading ? <Skeleton width={60}/> : value}</span>}
            {multipleValue && 
            <div className='card-follower-inline-list'>
                <span className='card-follower__value'>{loading ? <Skeleton width={60}/> : value1}</span>
                
            </div>
            }

            
        </div>
    )
}

export default FollowerCardInline