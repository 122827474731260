import React, { useState, useEffect } from "react";
// import "./detailed-analytics.scss";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Loading } from "../../../redux/action";
import { connect } from "react-redux";
import { AnalyticsService } from "../../../core/services/analytics.service"
import "./analytics.scss";
import {
  Skeleton,
  Tab} from "@mui/material";
import {
  TableComponent,
  SortingColumnsType,
} from "../../../../src/components/table-component/table"

import { Visibility, ThumbUp, Chat, YouTube } from '@mui/icons-material';
import AnalyticsDashboard from './components/analytics-dashboard';
import { MostEngagingPosts } from './components/most-engaging-posts';
import { MostEngagingInfluencer } from './components/most-engaging-influencer';
import DetailedInsightsPost from './components/detailed-insights-post';
import DetailedInsightsInfluencer from './components/detailed-insights-influencer';
import AddAnalyticsBrand from "./add_analytics_brand";
import UpgradeBrandPopUp from "../../../components/popup/upgrade-brand-popup";
import ROI from "./roi";
import dayjs from "dayjs";
import CommonPopUp from "../../../components/popup/common-popup";
import YouTubeEmbed from "../../../components/instagram-embed/youtube-embed";
import SampleDetailedAnalytics from "./components/sample-detailed-analytics";
import { createPortal } from "react-dom";
import FormShareAnalytics from "./components/form-share-analytics";
import { FormValidation } from "../../../core/utility";
import ConfirmPopup from "../../../components/confirm-popup/confirm-popup";
import { WhatsappIcon, WhatsappShareButton } from "react-share";
import { LocalService } from "../../../core/services";
import MaterialSymbol from "../../../components/icons/material-symbol";
import { sampleMostEngagingInfluencers, sampleInfluencerDetailedData, samplePostDetailedData, sampleMostEngagingPosts } from '../../../data/data';
import * as gtUtil from "../../../core/utility";


interface Props {
  UserReducer: any;
  UserID: any;
  loading: (data: any) => void;
  selectCampaign: any;
  getSubscriptionDetail:any;
}


interface ErrorTypes {
  status: boolean;
  message: string;
}

const analyticsService = new AnalyticsService();
const localService = new LocalService();
const columnDisplayNamePostDetailedData = {
  er: "ER",
  delete:'',
  video_views: "Views",
  likes: "Likes",
  comments: "Comments",
  created_at: "Date"

};
const columnDisplayNameInfluencerDetailedData = {
  total_post: "Post",
  total_er: "ER",
  total_views: "Views",
  like_count: "Likes",
  total_comments: "Comments"

};

const PostDetailedData: SortingColumnsType[] = [
  {
    name: "",
    columnSize: "45%",
  },
  {
    name: "",
    columnSize: "5%",
  },
  {
    name: "ER",
    columnSize: "10%",
  },
  {
    name: "Views",
    columnSize: "10%",
  },
  {
    name: "Likes",
    columnSize: "10%",
  },
  {
    name: "Comments",
    columnSize: "10%",
  },
  {
    name: "Posted on",
    columnSize: "15%",
  },
];

const InfluencerDetailedData: SortingColumnsType[] = [

  {
    name: "",
    columnSize: "50%",
  },
  {
    name: "Post",
    columnSize: "10%",
  },
  {
    name: "ER",
    columnSize: "10%",
  },
  {
    name: "Views",
    columnSize: "10%",
  },
  {
    name: "Likes",
    columnSize: "10%",
  },
  {
    name: "Comments",
    columnSize: "10%",
  },

];


const DetailedAnalyticsYoutube: React.FC<Props> = (props: Props) => {
  const formValidation = new FormValidation();
  const [value, setValue] = useState("1");
  const [value2, setValue2] = useState("1");
  const [value4, setValue4] = useState("1");
  const [analyticsCumulativeDataYtNumericData, setanalyticsCumulativeDataYtNumericData] = useState<any>([]);
  const [analyticsCumulativeDataYtMediaData, setAnalyticsCumulativeDataYtMediaData] = useState<any>([]);
  const [postFilterData, setPostFilterData] = useState<any>([]);
  const [influencerFilterData, setInfluencerFilterData] = useState<any>([]);
  const [graphData, setGraphData] = useState<any>([]);
  const [campaignId, setCampaignId] = useState(0)
  const [campaignPlatform, setCampaignPlatform] = useState("")
  const [totalEr, setTotalEr] = useState(0)
  const [smId, setSmId] = useState(0)
  const [postAnalyticsEr, setPostAnalyticsEr] = useState<any>([])
  const [influencerAnalyticsEr, setInfluencerAnalyticsEr] = useState<any>([])
  const [totalCost, setTotalCost] = useState(0);
  const [totalPost, setTotalPost] = useState(0);
  const [openUpgradeBrandModal, setOpenUpgradeBrandModal] = useState(false);
  const [openModalROI, setOpenModalROI] = useState<boolean>(false);
  const [addedLinksDetails, setAddedLinksDetails] = useState<any>([]);
  const [buttonClass, setButtonClass] = useState<string>('btn btn-primary disabled');
  const [openModal, setOpenModal] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);


  // -----------------Open Model-----------------------
  const [openModalAddPost, setOpenModalAddPost] = useState<boolean>();

  
  const [openAddUser, setOpenAddUser] = useState(false)
  const [sharedUserList, setSharedUserList] = useState<any>([]);

  // -------------
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedValue, setSelectedValue] = useState('');

  // -----------
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleChange2 = (event: React.SyntheticEvent, newValue: string) => {
    setValue2(newValue);
  };

  const handleChange4 = (event: React.SyntheticEvent, newValue: string) => {
    setValue4(newValue);
  };

  // -----------------------------------

  const handleOpenMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };



  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (value: any) => {
    setSelectedValue(value);
    handleCloseMenu();
  };




  // -----------------------------------------------------------------------------------------------

  const handleOpenModalAnalytics = (campaign_id: number, campaign_platform: string) => {
    setOpenModalAddPost(true);
    setCampaignId(campaign_id);
    setCampaignPlatform(campaign_platform);

  }


  useEffect(() => {
    analyticsData();
    getPostLinkData();
  }, []);

  const analyticsData = async () => {
    let campaign_id = props?.selectCampaign?.campaign_id;
    const res: any = await analyticsService.brandAnalyticsYoutube({ campaign_id: campaign_id, sm_id: null });
    if (res.status === gtUtil.Constants.SUCCESS) {
      setAnalyticsCumulativeDataYtMediaData(res?.data?.rows[0]?.analytics_data_yt_media[0])
      if(res?.data?.rows[0]?.analytics_data_yt_numeric.length > 0){
        setanalyticsCumulativeDataYtNumericData(res?.data?.rows[0]?.analytics_data_yt_numeric[0])
      }
      setPostFilterData(res?.data?.rows[0]?.post_data_content)
      setInfluencerFilterData(res?.data?.rows[0]?.influencer_data_content)
      setGraphData(res?.data?.rows[0]?.graph_data_array)
      setTotalEr(res?.data?.rows[0]?.total_er)
      setPostAnalyticsEr(res?.data?.rows[0]?.yt_post_analytic_er)
      setInfluencerAnalyticsEr(res?.data?.rows[0]?.yt_influencer_analytic_er)
      setTotalPost(res.data.rows[0].total_posts)
    }
  }

  const getPostLinkData = async () => {
    const campaign_id = props?.selectCampaign?.campaign_id
    const res = await analyticsService.getAnalyticsLinks(campaign_id)

    if (res.data.status) {
      setAddedLinksDetails(res.data.rows)

      //Today Date
      let currentDate = dayjs().format("YYYY-MM-DD HH:mm");
      // let currentDate = "2024-08-09 09:50"
      // Add 72 hours to the refreshed_at timestamp
      let refreshedAtTimestamp = dayjs(addedLinksDetails[0]?.refreshed_at).add(72, 'hour');
      // Format the new timestamp as "YYYY-MM-DD HH:mm"
      const newRefreshTimeStamp = refreshedAtTimestamp.format("YYYY-MM-DD HH:mm");

      if ((currentDate >= newRefreshTimeStamp) && addedLinksDetails[0]?.refreshed_count === 1) {
        setButtonClass('btn btn-primary')
      }
      else if ((currentDate >= newRefreshTimeStamp) && addedLinksDetails[0]?.refreshed_count === 2) {
        setButtonClass('btn btn-primary')
      }
      else if (addedLinksDetails[0]?.refreshed_count === 3 || addedLinksDetails[0]?.refreshed_count === 1) {
        setButtonClass('btn btn-primary disabled')
      }
    }
  }

  const refreshAnalytics = async () => {
    const campaign_id = props?.selectCampaign?.campaign_id
    const res = await analyticsService.analyticsRefreshInight({ campaign_id })

    if (res.data.status) {
      window.alert("Updated Successfully")
    }
    getPostLinkData();
  }

  const sampleGraphData = [
  {
    post_date: new Date('2024-01-01').getTime(),
    likes: 120,
    views: 1500,
    comments: 50,
  },
  {
    post_date: new Date('2024-01-02').getTime(),
    likes: 200,
    views: 1800,
    comments: 70,
  },
  {
    post_date: new Date('2024-01-03').getTime(),
    likes: 150,
    views: 1700,
    comments: 65,
  },
  {
    post_date: new Date('2024-01-04').getTime(),
    likes: 180,
    views: 1600,
    comments: 80,
  },
  {
    post_date: new Date('2024-01-05').getTime(),
    likes: 250,
    views: 2000,
    comments: 90,
  },
  {
    post_date: new Date('2024-01-06').getTime(),
    likes: 220,
    views: 2100,
    comments: 75,
  },
  {
    post_date: new Date('2024-01-07').getTime(),
    likes: 270,
    views: 2300,
    comments: 100,
  },
];

  const samplelikesOptions = {
    chart: {
      type: 'line',
    },
    title: {
      text: 'Likes Over Time',
    },
    xAxis: {
      type: 'datetime',
      categories: sampleGraphData?.map((entry: any) => entry.post_date),
    },
    yAxis: {
      title: {
        text: 'Total Likes',
      },
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        name: 'Total Likes',
        data: sampleGraphData?.map((entry: any) => entry.likes),
      },
    ],
    exporting: {
      enabled: false, // Disables exporting
    },
  };

  const sampleplaysOptions = {
    chart: {
      type: 'line',
    },
    title: {
      text: 'Views Over Time',
    },
    xAxis: {
      type: 'datetime',
      categories: sampleGraphData?.map((entry: any) => entry.post_date),
      
    },
    legend: {
      enabled: false,
    },
    yAxis: {
      title: {
        text: 'Total Views',
      },
    },
    credits: {
      enabled: false
    },
    
    series: [
      {
        name: 'Total Views',
        data: sampleGraphData?.map((entry: any) => entry.views),
      },
    ],
    exporting: {
      enabled: false, // Disables exporting
    },
  };
  const samplecommentOptions = {
    chart: {
      type: 'line',
    },
    title: {
      text: 'Comments Over Time',
    },
    xAxis: {
      type: 'datetime',
      categories: sampleGraphData?.map((entry: any) => entry.post_date),
    },
    yAxis: {
      title: {
        text: 'Total Comments',
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false
    },
    series: [
      {
        name: 'Total Comments',
        data: sampleGraphData?.map((entry: any) => entry.comments),
      },
    ],
    exporting: {
      enabled: false, // Disables exporting
    },
  };

  const likesOptions = {
    chart: {
      type: 'line',
    },
    title: {
      text: 'Likes Over Time',
    },
    xAxis: {
      type: 'datetime',
      categories: graphData?.map((entry: any) => entry.post_date),
    },
    yAxis: {
      title: {
        text: 'Total Likes',
      },
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        name: 'Total Likes',
        data: graphData?.map((entry: any) => entry.likes),
      },
    ],
    exporting: {
      enabled: false, // Disables exporting
    },
  };

  const playsOptions = {
    chart: {
      type: 'line',
    },
    title: {
      text: 'Views Over Time',
    },
    xAxis: {
      type: 'datetime',
      categories: graphData?.map((entry: any) => entry.post_date),
      
    },
    legend: {
      enabled: false,
    },
    yAxis: {
      title: {
        text: 'Total Views',
      },
    },
    credits: {
      enabled: false
    },
    
    series: [
      {
        name: 'Total Views',
        data: graphData?.map((entry: any) => entry.views),
      },
    ],
    exporting: {
      enabled: false, // Disables exporting
    },
  };
  const commentOptions = {
    chart: {
      type: 'line',
    },
    title: {
      text: 'Comments Over Time',
    },
    xAxis: {
      type: 'datetime',
      categories: graphData?.map((entry: any) => entry.post_date),
    },
    yAxis: {
      title: {
        text: 'Total Comments',
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false
    },
    series: [
      {
        name: 'Total Comments',
        data: graphData?.map((entry: any) => entry.comments),
      },
    ],
    exporting: {
      enabled: false, // Disables exporting
    },
  };

  const handleOpenUpgradeBrandModal = (value:boolean) => {
    setOpenUpgradeBrandModal(true)
  };

  function handleClosePopUps() {
    setOpenUpgradeBrandModal(false)
    setOpenModal(false)
  }

  const openAddShareUser = () => {
    setOpenAddUser(true);
  };
  const closeAddShareUser = () => {
    setOpenAddUser(false);
  };

  const [inviteInput, setInviteInput] = useState("");
  const [errorEmail, setErrorEmail] = useState<ErrorTypes>();
  const [userShareList, setUserShareList] = useState<any[]>([])
  const [inviteEnabled, setInviteEnabled] = useState<boolean>(false)
  const handleEmailChange = async (e:any) => {
    const value = e.target.value;
    setInviteInput(value);

    // Validate the email format
    let isValid = formValidation.GetEmailControlIsValid(value);
    setErrorEmail(isValid);
    if (isValid.status) {
      setInviteEnabled(true);

      // Check if the email already exists in the database
      try {
        const response = await analyticsService.checkEmailExists({
          campaign_id: props?.selectCampaign?.campaign_id,
          email: inviteInput
        });

        if (response.exists) {
          setErrorEmail({
            status: true,
            message: response.message,
          });
          setInviteEnabled(false);
        }
      } catch (error) {
        console.error("Error checking email existence", error);
      }
    } else {
      setInviteEnabled(false);
    }
  };


  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter' && inviteEnabled) {
      handleSubmitInvite(e); // Submit if Enter is pressed and input is valid
    }
  };
  const handleSubmitInvite = async (e:any) => {
    e.preventDefault();
    try{
      if(!inviteInput) {
        setErrorEmail({
          status: true,
          message: "Email is required",
        })
        return
      }
      const emailExists = userShareList.some(
        (user: { email: string }) => user.email === inviteInput
      );

      if (emailExists) {
        setErrorEmail({
          status: true,
          message: "Email already in the list",
        });
        return;
      }
      // Check if the email exists in the database
      const response = await analyticsService.checkEmailExists({
        campaign_id: props?.selectCampaign?.campaign_id,
        email: inviteInput
      });
      if (response.exists) {
        setErrorEmail({
          status: true,
          message: response.message,
        });
        return;
      }
      setUserShareList((prevList: any) => [
        ...prevList,
        { id: new Date().getTime(), email: inviteInput }
      ]);
      
      setInviteInput("");
      setInviteEnabled(false)
    }
    catch(err){
      console.log("error", err)
    }
    
    
  }

  const [openDeleteInfluencer, setOpenDeleteInfluencer] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);

  const [openSharedDeleteUser, setOpenSharedDeleteUser] = useState(false);
  const handleOpenDeleteInfluencer = (id: number) => {
    setOpenDeleteInfluencer(true)
    setSelectedUserId(id)
    document
      .getElementsByTagName('body')[0]
      .classList.add('custom-drawer-active');

  }
  const handleOpenSharedDeleteUser = (id:number) => {
    setOpenSharedDeleteUser(true)
    setSelectedUserId(id)
    document
      .getElementsByTagName('body')[0]
      .classList.add('custom-drawer-active');
  
  }
  const handleOpenSharedDeleteUserClose = () => {
    setOpenSharedDeleteUser(false);
    document
      .getElementsByTagName('body')[0]
      .classList.remove('custom-drawer-active');
  }
  // const handleDeleteInfluencer = (id: number) =>{
  //   const deletedInfluencer = userShareList.filter(user => {
  //     return user.id !== id
  //   })
  //   setUserShareList(deletedInfluencer)
  // }

  const handleDeleteInfluencer = (id: number) => {
    const updatedList = userShareList.filter((user) => user.id !== id);
    setUserShareList(updatedList);
    setSelectedUserId(id)
  };
  const handleDeleteInfluencerClose = () => {
    setOpenDeleteInfluencer(false);
    document
      .getElementsByTagName('body')[0]
      .classList.remove('custom-drawer-active');
  };
    const handleSendSharedList = async () => {
    const campaign_id = props?.selectCampaign?.campaign_id;
    const campaign_title = props?.selectCampaign?.campaign_title;
    const platformType = props?.selectCampaign?.campaign_platform;
    const planID = Number(urlParams.get('planID'));

    if (!campaign_id || !userShareList || userShareList.length === 0) {
      console.error("Invalid data: Campaign ID or user share list is missing.");
      return;
    }

    const payload = {
      campaign_id,
      planID,
      userShareList,
      platformType,
      campaign_title,
    };

    try {
      setLoading(true); // Start loading

      // Send the payload to the analytics service
      await analyticsService.analyticsShareLinks(payload);

      const response = await analyticsService.analyticsSharedList(campaign_id);
      setSharedUserList(response);

      if (props?.selectCampaign?.campaign_id) {
        fetchSharedList();
      }

      setUserShareList([]); // Clear the user share list
    } catch (error) {
      console.error("Error sending shared list:", error);
    } finally {
      setLoading(false); // End loading
    }
  };
  // const handleSendSharedList = async () => {
  //   // Prepare the payload with campaignId and userShareList
  //   const campaign_id = props?.selectCampaign?.campaign_id;
  //   const campaign_title = props?.selectCampaign?.campaign_title;
  //   const platformType = props?.selectCampaign?.campaign_platform;
  //   if (!campaign_id || !userShareList || userShareList.length === 0) {
  //     console.error("Invalid data: Campaign ID or user share list is missing.");
  //     return; // Early return if validation fails
  //   }
  //   const planID = Number(urlParams.get('planID'));
  //   const payload = {
  //     campaign_id, planID,  userShareList, platformType, campaign_title
  //   };
  //   // Send the payload to the analytics service
  //   await analyticsService.analyticsShareLinks(payload);
  //   const response = await analyticsService.analyticsSharedList(campaign_id);
    
  //   setSharedUserList(response)
  //   if (props?.selectCampaign?.campaign_id) {
  //     fetchSharedList();
  //   }
  //   setUserShareList([]);
  // };

  const fetchSharedList = async () => {
    const campaign_id = props?.selectCampaign?.campaign_id;
    const urlParams = new URLSearchParams(window.location.search);
    const urlPathname = window.location.pathname;
    const hasShareAnalytics = urlPathname.includes('share-analytics') || urlPathname.includes('share-analytics-yt');
    const planID = Number(urlParams.get('planID'));
    if (!campaign_id) return; // Prevent fetching if campaignId is undefined
    const data= {campaign_id, planID}
    // Fetch the shared list using the campaignId
    const res = await analyticsService.analyticsSharedList(data);
    try {
      if (res.data.status) {
        setSharedUserList(res.data.rows)
      }
    }
    catch (error) {
      console.log("FETCHING USER SHARED LIST", error)
    }
  };
  useEffect(() => {
    if (props?.selectCampaign?.campaign_id) {
      fetchSharedList();
    }
  }, [props?.selectCampaign?.campaign_id]);


  const handleDeleteSharedUser = async (id: number) => {
    const campaign_id = props?.selectCampaign?.campaign_id;
    try {
      const res = await analyticsService.deleteSharedUser({ campaign_id: campaign_id, id: id });
      if (props?.selectCampaign?.campaign_id) {
        fetchSharedList(); // Only call fetchSharedList when campaignId is defined
      }
      // Handle success response
    } catch (error) {
      console.log("Error deleting user:", error);
      // Handle error response
    }
  };
let shareCampaignId = props?.selectCampaign?.campaign_id;
let sharePlatformType = props?.selectCampaign?.campaign_platform;
  const [openInitalSharePopup, setOpenInitalSharePopup] = useState(false)
   const [openWhatsAppPopup, setOpenWhatsAppPopup] = useState(false)
  const openInitialSharePopup = () => {
    setOpenInitalSharePopup(true);
  };
  const closeInitialSharePopup = () => {
    setOpenInitalSharePopup(false);
  };
    const closeWhatsAppSharePopup = () => {
    setOpenWhatsAppPopup(false);
  };
  const [shareURL,setShareURL] = useState("")
  
const [loading, setLoading] = useState(false);
useEffect(() => {
    const fetchShareURL = async () => {
      try {
        const res = await analyticsService.analyticsSharePublicLink({
          campaign_id: shareCampaignId,
          campaignPlatform: sharePlatformType,
        });

        if (res?.data?.status) {
          const url = res?.data?.url;
          setShareURL(url || ""); // Update the URL state
        } else {
          console.error("Error fetching URL:", res?.data?.message);
        }
      } catch (error) {
        console.error("Error while fetching WhatsApp share URL:", error);
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchShareURL(); // Call the async function
  }, [shareCampaignId, sharePlatformType]);
  const openWhatsAppSharePopup = async () => {
  if (!shareCampaignId || !sharePlatformType) {
    console.error("Campaign ID or Platform Type is missing.");
    return;
  }

  try {
    const res = await analyticsService.analyticsSharePublicLink({
      campaign_id: shareCampaignId,
      campaignPlatform: sharePlatformType,
    });

    if (res.data.status) {
      const url = res.data.url;
      setShareURL(url); // Update the URL state
      // setOpenWhatsAppPopup(true); // Open the popup
    } else {
      console.error("Error fetching URL:", res.data.message);
    }
  } catch (error) {
    console.error("Error while fetching WhatsApp share URL:", error);
  }
};

  const handleCopyShareLink = async () =>{
    if (!shareCampaignId || !sharePlatformType) {
    console.error("Campaign ID or Platform Type is missing.");
    return;
  }

  try {
    const res = await analyticsService.analyticsSharePublicLink({
      campaign_id: shareCampaignId,
      campaignPlatform: sharePlatformType,
    });

    if (res.data.status) {
      const url = res.data.url;
      setShareURL(url); // Update the URL state
      navigator.clipboard.writeText(url);
      localService.toastify('Link Copied', 'success', 1000);
    } else {
      console.error("Error fetching URL:", res.data.message);
    }
  } catch (error) {
    console.error("Error while fetching WhatsApp share URL:", error);
  }
    
  }

  return (
    <>
      {openModalAddPost && (
        <AddAnalyticsBrand open={openModalAddPost}
          onClose={() => setOpenModalAddPost(false)}
          post_platform={campaignPlatform}
          campaign_id={campaignId}
          sm_id={smId}
          totalPost={totalPost}
          getSubscriptionDetail={props.getSubscriptionDetail}
          handleOpenUpgradeBrandModal={handleOpenUpgradeBrandModal}
          campaignType={props.selectCampaign.campaign_type}
          onUpdatePostLinks={getPostLinkData}
        />
      )}
      {openModal && (
        <CommonPopUp
          modalTitle={''}
          modalContent=""
          openModal={openModal}
          onClose={handleClosePopUps}
          className="modal-medium mx-auto"
        >
          <div className="">
            <div>
              <p>It takes a few minutes to get the data.Till we do the task, 
              sit back and relax or just have a cup of tea.</p>
            </div>

            <div className='popup-footer' style={{ marginTop: "32px" }}>
              <div className='row'>
                <div className='col text-end'>
                    <a className="btn btn-primary ms-2 btn-mui" onClick={handleClosePopUps}>
                      Ok
                    </a>
                </div>
              </div>
            </div>
          </div>
        </CommonPopUp>
      )}
      <div className="brand-analytics-youtube detailed-analytics">
        <div className="detailed-analytics-content">
          <div className="button-div">
            <span className="btn btn-outline-primary add"
              onClick={(e) => {
                handleOpenModalAnalytics(props.selectCampaign.campaign_id, props.selectCampaign.campaign_platform)
              }} >
              Add Post
            </span>

            {props.getSubscriptionDetail && props.getSubscriptionDetail.plan_id === 9 && (
              <button className={buttonClass} onClick={refreshAnalytics}>
                Refresh Insight
              </button>
            )}
            <div className="d-flex ms-auto ">
              <a href="#" className="btn btn-outline-primary" onClick={() => setOpenModalROI(true)}> ROI Generator</a>
              <span className="cursor-pointer btn btn-primary ms-2 d-flex align-items-center" onClick={openInitialSharePopup}>
                <MaterialSymbol className="me-1" iconName="share" fontweight="200" fontSize="18px" />
                SHARE
              </span>
              </div>
          </div>
          <div className="upper-grid">
            <AnalyticsDashboard postFilterData={postFilterData} analyticsCumulativeDataMediaData={analyticsCumulativeDataYtMediaData} campaign_platform={props?.selectCampaign?.campaign_platform} analyticsCumulativeDataNumericData={analyticsCumulativeDataYtNumericData} totalCost={totalCost} totalPost={addedLinksDetails?.length}/>
          </div>
        </div>
        {postFilterData.length === 0 && addedLinksDetails.length === 0 && 
        <>
        {
          (() => {
            if (postFilterData.length === 0 && addedLinksDetails.length === 0) {
              return (
                <>
                <strong className="pb-3 d-block">Sample analytics report <span className="fs-12">(Once the links are added, the analytics will be visible as shown below)</span></strong>
                  <SampleDetailedAnalytics sampleMostEngagingInfluencers={sampleMostEngagingInfluencers} sampleInfluencerDetailedData={sampleInfluencerDetailedData} samplePostDetailedData={samplePostDetailedData} sampleMostEngagingPosts={sampleMostEngagingPosts} platform={props.selectCampaign.campaign_platform}   />
                </>
              )
            }

          })()

        }
        </>}
        {((addedLinksDetails && addedLinksDetails.length>0) && postFilterData.length === 0) &&
        <>
          <strong className="d-block mb-2">Post Links:</strong>
           <div className="overflow-hidden">
          <div className="post-links row">{addedLinksDetails && addedLinksDetails?.map((postLink:any)=>{
            return <div className="col-md-3">{loading ? <Skeleton /> : 
            <YouTubeEmbed videoId={postLink?.post_url} />}
            
            </div>
            
            
          })}</div>
          </div>
        </>
        }
        {
          (() => {
            if (postFilterData.length > 0) {
              return (
                <>
                  <div className="toggle-button-div sec-stockchart-w-tab">

                    <TabContext value={value}>

                      <TabList
                        onChange={handleChange}
                        className='nav sub-tabs'
                      >
                        <Tab className='nav-link'
                          label={`Total Views`}
                          value="1"
                          icon={<span className="material-symbols-rounded">visibility</span>}
                          style={{
                            textTransform: "none",
                            fontSize: "16px",
                            flexGrow: 1,
                          }}
                        />
                        <Tab className='nav-link'
                          label={`Total Likes`}
                          value="2"
                          icon={<span className="material-symbols-rounded">favorite</span>}
                          style={{
                            textTransform: "none",
                            fontSize: "16px",
                            flexGrow: 1,
                          }}
                        />
                        <Tab className='nav-link'
                          label={`Total Comments`}
                          value="3"
                          icon={<span className="material-symbols-rounded">chat_bubble</span>}
                          style={{
                            textTransform: "none",
                            fontSize: "16px",
                            flexGrow: 1,
                          }}
                        />
                      </TabList>

                      <TabPanel className="px-0" value="1">
                        <HighchartsReact highcharts={Highcharts} options={playsOptions} />

                      </TabPanel>
                      <TabPanel className="px-0" value="2">
                        <HighchartsReact highcharts={Highcharts} options={likesOptions} />
                      </TabPanel>
                      <TabPanel className="px-0" value="3">
                        <HighchartsReact highcharts={Highcharts} options={commentOptions} />
                      </TabPanel>
                    </TabContext>

                  </div>
                  <div className='d-flex flex-column'>
                    <strong className='me-auto mb-3'>Most Engaging Posts</strong>
                    {/* <Button onClick={handleOpenMenu}>Most Engaging Posts</Button>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleCloseMenu}
                    >
                      <MenuItem onClick={() => handleMenuItemClick('er')}>ER</MenuItem>
                      <MenuItem onClick={() => handleMenuItemClick('reach')}>Reach </MenuItem>
                      <MenuItem onClick={() => handleMenuItemClick('impressions')}>Impressions</MenuItem>
                    </Menu> */}
                    {/* {selectedValue && (
                     
                    )} */}
                    <div>
                      {/* Selected Value: {selectedValue} */}
                      {(() => {
                        const sortedData = [...postFilterData].sort((a: any, b: any) => (b.selectedValue > a.selectedValue) ? 1 : (b.selectedValue < a.selectedValue) ? -1 : 0).slice(0, 5);
                        return (
                          <MostEngagingPosts sortedData={sortedData} selectCampaign={props?.selectCampaign} />
                          // <div>
                          //   <div className="Most-Engaging-Posts">
                          //     <Box sx={{ flexGrow: 1 }}>
                          //       <Grid container spacing={2}>
                          //         {sortedData.map((item: any) => (
                          //           <Grid item xs={12} md={6} lg={3}>
                          //             <div className="grid-div">
                          //               <img src={item.thumbnails} className="grid-image"></img>
                          //               <strong>{item.username}</strong>
                          //             </div>
                          //           </Grid>
                          //         ))}
                          //       </Grid>
                          //     </Box>
                          //   </div>
                          // </div>
                        );
                      })()}
                    </div>
                    {(
                      <div className="analytics-most-engaging">
                        <div className='d-flex flex-column'>
                          <strong className="mb-3">Most Engaging Influencers</strong>
                          {(() => {
                            const sortedData = influencerFilterData.sort((a: any, b: any) => (b.total_er > a.total_er) ? 1 : (b.total_er < a.total_er) ? -1 : 0).slice(0, 5);
                            return (
                              <MostEngagingInfluencer sortedData={sortedData} selectCampaign={props?.selectCampaign} />
                              // <div>
                              //   <div className="Most-Engaging-Posts">
                              //     <Box sx={{ flexGrow: 1 }}>
                              //       <Grid container spacing={2}>
                              //         {sortedData.map((item: any) => (
                              //           <Grid item xs={12} md={6} lg={2.5}>
                              //             <div className="grid-div">
                              //               <img src={item.profile_image_url} className="grid-image" />
                              //               <strong>{item.username}</strong>
                              //               <strong>ER {item.total_er}%</strong>
                              //             </div>
                              //           </Grid>
                              //         ))}
                              //       </Grid>
                              //     </Box>
                              //   </div>
                              // </div>

                            );
                          })()}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="toggle-button-div2">

                    <TabContext value={value2}>


                      <div className="toggle-button-div3">
                        <TabContext value={value4}>
                          <TabList
                            onChange={handleChange4}
                            aria-label="lab API tabs example"
                            className="filter-tab-list"
                          >
                            <Tab
                              label={`Posts`}
                              value="1"
                              style={{ textTransform: "none", fontSize: "16px" }}
                            />
                            <Tab
                              label={`Influencers`}
                              value="2"
                              style={{ textTransform: "none", fontSize: "16px" }}
                            />
                          </TabList>

                          <TabPanel className='px-0' value="1">
                            <div className="post-table">
                              <DetailedInsightsPost columnDisplayName={columnDisplayNamePostDetailedData}
                                orderByColumnName=""
                                inputDataSource={postFilterData}
                                sortingColumnType={PostDetailedData}
                                renderData={postFilterData}
                                setRenderData={(e: any) => setPostFilterData(e)}
                                selectCampaign={props?.selectCampaign}
                                postAnalyticsEr={postAnalyticsEr}
                                />
                            </div>
                          </TabPanel>
                          <TabPanel className='px-0' value="2">
                            <div className="influencer-table">
                              <DetailedInsightsInfluencer columnDisplayName={columnDisplayNameInfluencerDetailedData}
                                orderByColumnName=""
                                inputDataSource={influencerFilterData}
                                sortingColumnType={InfluencerDetailedData}
                                renderData={influencerFilterData}
                                setRenderData={(e: any) => setInfluencerFilterData(e)}
                                selectCampaign={props?.selectCampaign} />
                              {/* {(() => {
                                if (influencerFilterData.length > 0) {
                                  return (
                                    <>
                                      <TableComponent
                                        columnDisplayName={columnDisplayNameInfluencerDetailedData}
                                        orderByColumnName=""
                                        inputDataSource={influencerFilterData}
                                        sortingColumnType={InfluencerDetailedData}
                                        renderData={influencerFilterData}
                                        setRenderData={(e) => setInfluencerFilterData(e)}
                                      >
                                        {influencerFilterData.map((data: any, index: number) => {
                                          return (
                                            <TableRow key={index} hover role="checkbox">
                                              <TableCell>
                                                <div className="grid-div-post">
                                                  <img src={data.profile_image_url} className="grid-image"></img>
                                                  <p>{data.custom_url} </p>
                                                </div>
                                              </TableCell>
                                              <TableCell>{data?.total_post}</TableCell>
                                              {
                                                (() => {
                                                  const matchingItem = influencerAnalyticsEr.find((item: any) => item.custom_url === data.custom_url)

                                                  return (
                                                    <TableCell>
                                                      {matchingItem ? matchingItem.result.toFixed(2) : "N A"}
                                                    </TableCell>
                                                  );
                                                })()
                                              }
                                              <TableCell>{data?.total_views}</TableCell>
                                              <TableCell>{data?.total_likes}</TableCell>
                                              <TableCell>{data?.total_comments}</TableCell>
                                            </TableRow>
                                          );
                                        })}
                                      </TableComponent>
                                    </>
                                  )
                                }

                              })()

                              } */}

                            </div>
                          </TabPanel>
                        </TabContext>

                      </div>
                    </TabContext>

                  </div>
                </>
              )
            }

          })()

        }
{openUpgradeBrandModal && (
        <UpgradeBrandPopUp
          openUpgradeBrandModal={openUpgradeBrandModal}
          onClose={() => handleClosePopUps()}
          popupMinWidth='630px'
        >
        </UpgradeBrandPopUp>
      )}

      </div>
      {openModalROI && <ROI open={openModalROI} likes={analyticsCumulativeDataYtNumericData.total_likes}  views = {analyticsCumulativeDataYtNumericData.total_views} er ={0} comments={analyticsCumulativeDataYtNumericData.total_comments} plays ={0} user_id ={props.UserReducer.user_id} account_id ={props.UserReducer.account_id} brand_id ={props.selectCampaign.brand_id} influencer_id ={props.UserReducer.influencer_id} campaign_id ={props.selectCampaign.campaign_id} platform = {"YouTube"} onClose={()=>setOpenModalROI(false)}/>}
      {createPortal(
        <CommonPopUp 
        className="modal-medium share-container"
          openModal={openAddUser}
          modalTitle="Share"
          onClose={closeAddShareUser}
      >
        <FormShareAnalytics userShareList={userShareList} handleEmailChange={handleEmailChange} errorEmail={errorEmail} inviteEnabled={inviteEnabled} handleOpenDeleteInfluencer={handleOpenDeleteInfluencer} handleSubmitInvite={handleSubmitInvite} handleKeyPress={handleKeyPress} inviteInput={inviteInput} handleSendSharedList={handleSendSharedList} handleOpenSharedDeleteUser={handleOpenSharedDeleteUser} sharedUserList={sharedUserList} loading={loading}  />
          
        
        </CommonPopUp>
      , document.body)} 
      <> {selectedUserId !== null && createPortal(
        <ConfirmPopup popupId="delete-influencer" extraClass="delete-added-influencer" confirmPopupInnerText="Are you sure want to delete the invited influencer." openPopup={openDeleteInfluencer} confirmClosePopup={handleDeleteInfluencerClose} confirmClickAction={() => handleDeleteInfluencer(selectedUserId!)} drawerTitle="" />, document.body)
      }</>
      <> {selectedUserId !== null && createPortal(
        <ConfirmPopup popupId="delete-influencer" extraClass="delete-added-influencer" confirmPopupInnerText="Are you sure want to delete the shared influencer." openPopup={openSharedDeleteUser} confirmClosePopup={handleOpenSharedDeleteUserClose} confirmClickAction={() => handleDeleteSharedUser(selectedUserId)} drawerTitle="" />, document.body)
      }</>
      {createPortal(
        <CommonPopUp 
        className="modal-medium share-container"
          openModal={openInitalSharePopup}
          modalTitle="Share"
          onClose={closeInitialSharePopup}
      >
        <div className="analytic-share-option d-flex align-items-center mb-3">
          <strong className="analytic-share-option-title">Share via:</strong>
          <div className="analytic-share-option-container">
            <button disabled onClick={openAddShareUser} className="btn btn-outline-primary d-inline-flex">
              <MaterialSymbol iconName="email" fontweight="200" />
              Email
            </button>
            <span className="btn btn-outline-primary d-inline-flex react-share-btn">
            <WhatsappShareButton
                url={shareURL || ""}
                className='Demo__some-network__share-button'
                disabled={!shareURL}
                onClick={() => {
                console.log("WhatsApp share clicked");
              }}
              >
                <WhatsappIcon style={{ color: '#25D366' }} />
                <span className='share-titles'>WhatsApp</span>
              </WhatsappShareButton>
            </span>
            {/* <button onClick={openWhatsAppSharePopup}  className="btn btn-outline-primary d-inline-flex react-share-btn">
              <WhatsappShareButton
                url={shareURL}
                className='Demo__some-network__share-button'
                
              >
                <WhatsappIcon style={{ color: '#25D366' }} />
                <span className='share-titles'>WhatsApp</span>
              </WhatsappShareButton>
            </button> */}
            <button onClick={handleCopyShareLink} className="btn btn-outline-primary d-inline-flex">
              <MaterialSymbol iconName="link" fontweight="200" />              
              Copy Link
            </button>
          </div>
        </div>
        </CommonPopUp>
      , document.body)} 
         {/* SHARE WHATSAPP Popup */}
      {createPortal(
        <CommonPopUp 
        className="modal-medium share-container"
          openModal={openWhatsAppPopup}
          modalTitle="Share"
          onClose={closeWhatsAppSharePopup}
      >
        <strong className="d-block mb-2">Share via</strong>
        <div className="d-flex gap-3 mb-3">
            <WhatsappShareButton
              url={shareURL}
              className='Demo__some-network__share-button'
            >
              <WhatsappIcon style={{ color: '#25D366' }} />
              <span className='share-titles'>WhatsApp</span>
            </WhatsappShareButton>
        </div>
          
        
        </CommonPopUp>
      , document.body)} 
    </>
    
  );
};


const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
    UserID: state.UserID.userID,
    selectCampaign: state.InfSelectCamp.campaign,
    getSubscriptionDetail: state.Subscription.subscription_detail,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailedAnalyticsYoutube);

export { connectToRedux as DetailedAnalyticsYoutube };
