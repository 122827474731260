import { Skeleton, Tooltip } from '@mui/material'
import React from 'react'

type City = {
  name: any;
  percentage: number;
}


type Props = {
  loading: boolean;
  data: City[]
}

const CityList = (props: Props) => {
  return (
    <div className="cities-list">
      
        {props?.data?.length < 1 && <>No Data</>}
        {props.loading ? (
  // Show skeleton loaders while loading
  [...Array(5)].map((_, index) => (
    <div className="cssProgress" key={`skeleton-${index}`}>
      <p className="cities">
        <Skeleton style={{ width: '100%', minWidth: '220px' }} />
      </p>
      <div className="progress-bars">
        <div className="progress2">
          <Skeleton style={{ width: '100%' }} />
        </div>
        <div className="percent-bar">
          <Skeleton style={{ width: '40px' }} />
        </div>
      </div>
    </div>
  ))
) : (
  // Render actual data once loading is false
  props?.data?.length > 0 &&
  props?.data?.slice(0, 5).map((cityName: City) => (
    <div className="cssProgress" key={cityName?.name}>
      <p className="cities">
        <Tooltip arrow placement="top-start" title={cityName?.name}>
          {cityName?.name}
        </Tooltip>
      </p>
      <div className="progress-bars">
        <div className="progress2" style={{ width: `${cityName?.percentage}%` }}>
          <div
            className="cssProgress-bar"
            data-percent={cityName?.percentage}
            style={{ width: '100%' }}
          ></div>
        </div>
        <div className="percent-bar">
          <span className="p-span">
            {cityName?.percentage < 1 ? '< 1%' : `${Math.floor(cityName?.percentage)}%`}
          </span>
        </div>
      </div>
    </div>
  ))
)}

      

    </div>
  )
}

export default CityList