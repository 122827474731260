
/* CHILDREN COMPONENT */
import React, { Fragment, useState, useEffect } from 'react';
import TablePagination from "@mui/material/TablePagination";
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer, TableSortLabel, Paper } from "@mui/material";
export interface SortingColumnsType {
    name: string | "element key Ex. instagram_handle_ig",
    columnSize: string | "columnSize Ex. 120px",
};
export interface ColumnDisplayNames {
    [key: string]: string; //{instagram_handle_ig: Instagram Handle}
};
interface Props {
    pagination?: boolean;
    filter?: string;
    orderByColumnName: string;
    columnDisplayName?: ColumnDisplayNames;
    sortingColumnType?: SortingColumnsType[];
    inputDataSource: any[];
    setRenderData: (data: any[]) => void;
    renderData: any[];
    children: any
    tableMinWidth?: number;
    className?:string;
};

export const TableComponent: React.FC<Props> = (
    { pagination = false, filter, orderByColumnName, columnDisplayName, sortingColumnType, inputDataSource, setRenderData, renderData, children, tableMinWidth = 650, className }: Props) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [paginationData, setPaginationData] = useState([]);
    const [orderBy, setOrderBy] = React.useState(orderByColumnName);
    const [order, setOrder] = React.useState<"asc" | "desc">("asc");
    const [sorted, setSortedArray] = useState<any[]>([]);
    const handleSort = (property: any) => {
        const isAsc = orderBy === property && order === "asc";
        setOrderBy(property);
        setOrder(isAsc ? "desc" : "asc");
    };
    React.useMemo(() => {
        if (Array.isArray(inputDataSource)) {
            const sortedArray = [...inputDataSource].sort((a, b) => {
                if (a[orderBy] < b[orderBy]) return order === "asc" ? -1 : 1;
                if (a[orderBy] > b[orderBy]) return order === "asc" ? 1 : -1;
                return 0;
            });
            //Output Sorting Data store in State
            setSortedArray(sortedArray);
            setPagination(sortedArray);
        }
    }, [inputDataSource, orderBy, order]);
    // Update Update Parent State
    useEffect(() => {
        if (JSON.stringify(paginationData) !== JSON.stringify(renderData)) {
            setRenderData(paginationData);
        };
    }, [paginationData, renderData, setRenderData]);

    //set Pagination data
    useEffect(() => {
        setPagination(sorted)
    }, [page, rowsPerPage])
    function setPagination(data: any) {
        if (pagination) {
            setPaginationData(data.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
            ));
        } else {
            setPaginationData(data);
        }
    };


    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    return (
        <Fragment>
            <div className={`table-component ${className}`}>
                <TableContainer className="common-table ">
                    <Table
                        sx={{ minWidth: tableMinWidth }}
                        aria-label="custom pagination table"
                    >
                        <TableHead className="common-table-header">
                            <TableRow>
                                {sortingColumnType?.map((column) => (
                                    <TableCell
                                        className="table_cell"
                                        style={{
                                            minWidth: column.columnSize,
                                        }}
                                        align="left"
                                        key={column.name}
                                    >
                                        <TableSortLabel
                                            active={orderBy === column.name}
                                            direction={order}
                                            onClick={() => handleSort(column.name)}
                                        >
                                            {(columnDisplayName && columnDisplayName[column.name]) || column.name}

                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody className='common-table-body'>
                            {children}
                        </TableBody>
                    </Table>
                </TableContainer>

                {pagination && <TablePagination className='common-pagination'
                    component="div"
                    rowsPerPageOptions={[5, 10, 25]}
                    count={sorted?.length ?? 0}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{
                        color: "#00aae5",
                        marginTop: "10px",
                    }}
                />}
            </div>
        </Fragment>
    )
};
