import { useState, useEffect, Fragment, useCallback } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import {
  Button,
  List,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import {
  LocalService,
  LoginService,
  SubscriptionService,
} from '../../core/services';
import ClanConnectLogo from '../../assets/images/clan_connect_logo.png';
import './header.scss';
import { DefaultUserProfile } from '../../config/config';
import { SubscriptionDetail, setCart, setGuestUser } from '../../redux/action';
import { useCart } from '../../hooks/useCart';
import { BrandHeaderMenu, InfluencerHeaderMenu } from '../../data/data';
import { AnimatePresence, motion } from 'framer-motion';
import SampleProfile from '../../assets/images/avtar_male.png';
import CommonPopUp from '../popup/common-popup';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { LetUsHelpYou } from '../let-us-help-you/let-us-help-you';
import { BASE_URL_WEB } from '../../config/config';
import { BASE_URL_UI } from '../../config/config';
import  UpgradeBrandPopUp from '../popup/upgrade-brand-popup'
import  UpgradeInfluencerPopUp from '../popup/upgrade-influencer-popup'
import MaterialSymbol from '../icons/material-symbol';
import * as gtUtil from "../../core/utility";

const subscriptionService: SubscriptionService = new SubscriptionService();

const localService = new LocalService();
const loginService = new LoginService();
const agencyUrls = [
  '/agency/settings/personal-info',
  '/agency/settings/companydetails',
  '/agency/settings/security',
  '/agency/settings/subscription',
  '/agency/settings/subscription-history',
  '/agency/settings/notifications',
  '/brand/admin-dashboard/influencer-signups',
  '/brand/admin-dashboard',
  '/brand/admin-dashboard/userbase',
  '/brand/admin-dashboard/campaign-verification',
  '/brand/admin-dashboard/campaign-details',
  '/brand/admin-dashboard/influencer-db',
  '/brand/admin-dashboard/crud',
  '/brand/admin-dashboard/agency-brands'
];

const talentPartnerUrls = [
  '/talent-partner/settings/personal-info',
  '/talent-partner/settings/companydetails',
  '/talent-partner/settings/brand-list',
  '/talent-partner/settings/security',
  '/talent-partner/settings/subscription',
  '/talent-partner/settings/subscription-history',
  '/talent-partner/settings/notifications'
];


const brandUrls = [

  '/brand/admin-dashboard/influencer-signups'
];

const brandDashboardUrls = [

  '/brand/dashboard'
];

const baseUrls = ['/switch/account', '/switch/user'];
interface Props {
  isLogin: any;
  UserReducer: any;
  guest_user: any;
  user_email_id: any;
  CartReducer: any;
  updateSubscriptionDetail?: (data: any) => void;
  getSubscriptionDetail: any;
  accounts: any;
  switchLayer2: any;
  switchLayer1: any;
}

function logoNavigate<Props>(props: Props) {
  // console.log(props);
}

const Header: React.FC<Props> = ({ accounts, ...props }: Props) => {
  useCart();
  const [toggles, setToggles] = useState(false);
  const [profileMenu, setProfileMenu] = useState(false);
  const body = document.body;
  const scrollUp = 'scroll-up';
  const scrollDown = 'scroll-down';
  const dispatch = useDispatch<any>();
  let lastScroll = 0;
  const product_ids = localService.getStorageItem('product_id');
  const navigate = useNavigate();
  const location = useLocation();
  const isClanshopUrl = location.pathname.includes('/clanshop');
  const [isAnimating, setIsAnimating] = useState(false);
  const [isBasicUser, setBasicUser] = useState(false);
  const [disableHeaderonPath] = useState(['welcome'])
  const [validUrls, setValidUrls] = useState<any>([...baseUrls]);
  const [headerPictureUrl, setHeaderPictureUrl] = useState(DefaultUserProfile.influencer_unknown_default);
  const [openModal, setOpenModal] = useState(false);
  const showLoginSignUp = location.pathname !== '/subscription/invoice';

  window.addEventListener('scroll', () => {
    const currentScroll = window.pageYOffset;
    if (currentScroll <= 0) {
      body.classList.remove(scrollUp);
      body.classList.remove(scrollDown);
      return;
    }
    if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
      body.classList.remove(scrollUp);
      body.classList.add(scrollDown);
    } else if (
      currentScroll < lastScroll &&
      body.classList.contains(scrollDown)
    ) {
      body.classList.remove(scrollDown);
      body.classList.add(scrollUp);
    }
    lastScroll = currentScroll;
  });
  const user_email_id: any = localService.getStorageItem('user_email_id');
  const guest_user_login = localService.getStorageItem('guest_user_login');
  if (guest_user_login) {
    dispatch(setGuestUser(true));
  }

  // set upgrade influencer Modal variables and usestate
  // const [openUpgradeInfluencerModal, setopenUpgradeInfluencerModal] = useState(false);
  const [openUpgradeBrandInboxModal, setopenUpgradeBrandInboxModal] = useState(false);
  const [openUpgradeInfluencerInboxModal, setopenUpgradeInfluencerInboxModal] = useState(false);

  // const handleOpenUpgradeInfluencerModal = () => setopenUpgradeInfluencerModal(true);
  // const handleOpenUpgradeBrandInboxModal = (data:boolean) => setopenUpgradeBrandInboxModal(data);
  // const handleOpenUpgradeInfluencerInboxModal = (data:boolean) => setopenUpgradeInfluencerInboxModal(data);

  // function handleCloseUpradeInfluencerModal() {
  //   setopenUpgradeInfluencerInboxModal(false);
  // }

  // useEffect(() => {}, [dispatch]);
  useEffect(() => {
    // To show props.UserReducer?.photo or props.UserReducer?.gcp_profile_image_url if present
    if (![undefined, null, ""].includes(props.UserReducer?.photo)) {
      setHeaderPictureUrl(props.UserReducer?.photo);
    } else if (![undefined, null, ""].includes(props.UserReducer?.gcp_profile_image_url)) {
      setHeaderPictureUrl(props.UserReducer?.gcp_profile_image_url);
    }
    async function getLeftDays() {
      const { account_id, user_id, influencer_id } = props.UserReducer;
      try {
        const response: any =
          await subscriptionService.getSubscription_Remaining_Days({
            account_id: account_id,
            user_id: user_id,
            influencer_id: influencer_id
          });
        if (response.status === gtUtil.Constants.SUCCESS) {
          //update subscription details
          if (props.updateSubscriptionDetail) {
            setBasicUser(false);
            props.updateSubscriptionDetail(response.data.rows[0]);
          }
        } else {
          setBasicUser(true);
          if (props.updateSubscriptionDetail) {
            props.updateSubscriptionDetail(response.data.rows[0]);
          }
        }
      } catch (error) {
        console.log('Influencer, TP, Header Error :: ' + JSON.stringify(error));
      }
    }

    if (props.UserReducer) {
      getLeftDays();
    }

    if (props.UserReducer?.account_type === "Agency") {
      setValidUrls([...validUrls, ...agencyUrls]);
    }

    if (props.UserReducer?.account_type === "Talent Partner") {
      setValidUrls([...validUrls, ...talentPartnerUrls]);
    }
  }, [props.UserReducer, dispatch]);
  const toggle = () => {
    setToggles((prevState) => !prevState);
  };
  const [selectedIndex, setSelectedIndex] = useState(1);

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
  };
  const handleNavigation = () => {
    navigate('/brand/settings/subscription');
  };
  const BrandMenu = props?.UserReducer?.view_admin_dashboard === "True" ? BrandHeaderMenu.mainMenu : BrandHeaderMenu.mainMenu.filter((item: any) => (item.id !== 6 && item.id !== 8) );
  let InfluencerMenu = props?.UserReducer?.view_admin_dashboard === "True" ? InfluencerHeaderMenu.mainMenu : InfluencerHeaderMenu.mainMenu.filter((item: any) => (item.id !== 6 && item.id !== 8));
  // To filter Influencer Tools header for production 
  InfluencerMenu = (BASE_URL_UI.includes('app.clanconnect.ai')) ? InfluencerMenu.filter((item: any) => item.id !== 4) : InfluencerMenu;
  const HeaderMenu = ['Brand', 'Agency'].includes(props?.UserReducer?.account_type) ? BrandMenu : InfluencerMenu
  // To Filter admin dashboard tab if permission not granted................
  const outsideClick = useCallback(() => {
    const handleOutsideClick = (event: any) => {
      const todoInputContainer = document.querySelector('._header_profile_picture');
      if (todoInputContainer && !todoInputContainer.contains(event.target)) {
        setProfileMenu(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [setProfileMenu]);

  useEffect(() => {
    const cleanup = outsideClick();

    return () => {
      cleanup();
    };
  }, [outsideClick]);

  const getDashboardLink = () => {
    if (isClanshopUrl && !props.isLogin) {
      return '/clanshop'; // Redirect to clanshop if isClanshopUrl is present
    } else {
      if (props.UserReducer?.account_type === 'Brand') {
        return '/brand/dashboard';
      } else if (props.UserReducer?.account_type === 'Influencer') {
        return '/influencer/dashboard';
      } else {
        return '/switch/user';
      }
    }
  };
  const listVars = {
    initial: {
      scale: 0,
      width: '10px',
      height: '10px',
      x: '0px',
      y: 0,

    },
    animate: {
      scale: 1,
      y: 20,
      transformOrigin: 'right top',
      width: '220px',
      height: 'auto',
      transition: {
        duration: 0.4,
        ease: [0.45, 0, 0.55, 1],
      },

    },
    exit: {
      scale: 0,
      x: '0',
      y: 0,
      width: '10px',
      height: '10px',
      transformOrigin: 'right top',
      transition: {
        delay: 0.4,
        duration: 0.3,
        ease: [0.22, 0, 0.39, 1],
      },
    }
  }


  const menuLinkVars = {
    initial: {
      y: "220px",
      transition: {
        duration: 0.5,
        ease: [0.37, 0, 0.63, 0.63]
      },
    },
    open: {
      y: 0,
      transition: {
        duration: 0.7,
        ease: [0, 0.55, 0.45, 1]
      },
    }
  };
  const headerProfileToggle = () => {
    setProfileMenu(prev => !prev)
    setIsAnimating(isAnimating => !isAnimating)
  }

  const handleNavLinkClick = (menu: any) => {
    if (isBasicUser && (menu.title === 'Profile View' || menu.title === 'Inbox') 
      && (props.UserReducer.account_type == 'Influencer' || props.UserReducer.account_type == 'Talent Partner')) {
      setopenUpgradeInfluencerInboxModal(!openUpgradeInfluencerInboxModal);
    }
  };

  // const handleClickInbox = () => {
  //   if (isBasicUser) {
  //     if(props.UserReducer.account_type == 'Brand' || props.UserReducer.account_type == 'Agency'){
  //     setopenUpgradeBrandInboxModal(!openUpgradeBrandInboxModal);
  //     }else{
  //       setopenUpgradeInfluencerInboxModal(!openUpgradeInfluencerInboxModal);
  //     }
  //   }
  // }

  const onClose = (value: boolean) => {
    setOpenModal(value)
  }

  // CHECK THE USER TYPE IS TALENT PARTNER
  const shouldHideMenu = (
    (props?.UserReducer?.account_type === 'Talent Partner' && agencyUrls.includes(location.pathname)) || // Condition 1
    (props?.UserReducer?.account_type === 'Talent Partner' &&
      props?.UserReducer?.influencer_ids?.length > 1 &&
      agencyUrls.includes(location.pathname)) // Condition 2
  );


  const agencyPaths = ['/brand/dashboard', '/brand/list', '/brand/plans', '/brand/discover'];
  const talentPartnerPaths = ['/influencer/dashboard', '/influencer/mycampaigns', '/influencer/profile-view'];

  const isAgencyPath = props.UserReducer?.account_type === 'Agency' && agencyPaths.includes(location.pathname);
  const isTalentPartnerPath = props.UserReducer?.account_type === 'Talent Partner' && talentPartnerPaths.includes(location.pathname);




  return (
    <>
      {/* Upgrade influencer popup */}
      {/* {openUpgradeInfluencerInboxModal && ( */}
        {/* <> */}
          {/* <CommonPopUp
            className={`modal-lg  mx-auto`}
            openModal={openUpgradeInfluencerModal}
            modalTitle="Upgrade to Premium:"
            onClose={() => handleCloseUpradeInfluencerModal()}>
            <div className="upgrade-premium-list">
              <ul>
                <li>Get discovered by Brands.</li>
                <li>Priority over unsubscribed influencers.</li>
                <li>Unlimited brand campaigns to apply on.</li>
                <li>Concise Analytics for your influencer profile.</li>
                <li>Work on Paid and Barter campaigns.</li>
                <li>Send Invoice effortlessly.</li>
              </ul>
            </div>

            <div className="col text-end plan_camp_btn_group popup-footer">
              <Link to="/influencer/settings/subscription" className='btn btn-primary' onClick={handleCloseUpradeInfluencerModal}>
                Upgrade
              </Link>
            </div>
          </CommonPopUp> */}
          {/* <UpgradeInfluencerPopUp
          openUpgradeInfluencerModal={openUpgradeInfluencerInboxModal}
          onClose={() =>setopenUpgradeInfluencerInboxModal(!openUpgradeInfluencerInboxModal)}
          popupMinWidth='630px'
        >
        </UpgradeInfluencerPopUp>
        </>
      )} */}
      {openUpgradeBrandInboxModal && (
        <>
        <UpgradeBrandPopUp
          openUpgradeBrandModal={openUpgradeBrandInboxModal}
          onClose={() => setopenUpgradeBrandInboxModal(!openUpgradeBrandInboxModal)}
          popupMinWidth='630px'
        >
        </UpgradeBrandPopUp>
        </>
      )}
       {openUpgradeInfluencerInboxModal && (
        <>
        <UpgradeInfluencerPopUp
          openUpgradeInfluencerModal={openUpgradeInfluencerInboxModal}
          onClose={() => setopenUpgradeInfluencerInboxModal(!openUpgradeInfluencerInboxModal)}
          popupMinWidth='630px'
        >
        </UpgradeInfluencerPopUp>
        </>
      )}
      {/* Header - Brand, Influencer, TP and Agency */}
      {!location.pathname.split('/').map(k => disableHeaderonPath.map(l => l === k)[0]).includes(true) &&
        <header
          className={
            `${!props.isLogin ? 'site-header' : 'app-header_brand site-header'} ${location.pathname} `
          }
        >
          <div className='container _header-container'>
            {!props.isLogin
              ?
              (
                <>
                  <div className='app-header_brand_left_side'>
                    <a
                      className='logo'
                      href={BASE_URL_WEB}
                    >
                      <img src={ClanConnectLogo} alt='Clan Connect' />
                    </a>
                  </div>
                  {props.guest_user ? (
                    <>
                      <span className='guest_user_avatar'>
                        <span className='guest_user_pic _header_profile_picture'>
                          <img
                            src={DefaultUserProfile.influencer_unknown_default}
                            alt='profile'
                            className='_header_profile_img'
                          />
                        </span>
                        <span className='email'>{JSON.parse(user_email_id)}</span>
                      </span>
                      {!['/share-analytics?'].includes(location.pathname) &&
                        <ul className='mobile_view_none'>
                          <li>
                            <NavLink to='/'>Sign Up</NavLink>
                          </li>
                          <li>
                            <NavLink to='/login'>Login</NavLink>
                          </li>
                        </ul>}
                    </>
                  ) : (
                    <>
                      <nav
                        className={`nav-menu mx-auto ${toggles ? 'active_header' : ''
                          }`}
                        onClick={() => setToggles(false)}
                      >
                        <List
                          className='p-0 d-lg-none'
                          component='nav'
                          aria-label='mailbox folders'
                        >
                          <ListItemButton
                            divider
                            selected={selectedIndex === 1}
                            onClick={(event) => handleListItemClick(event, 1)}
                          >
                            <ListItemText>
                              <NavLink to='/'>Sign Up</NavLink>
                            </ListItemText>
                          </ListItemButton>
                          <ListItemButton
                            divider
                            selected={selectedIndex === 2}
                            onClick={(event) => handleListItemClick(event, 2)}
                          >
                            <ListItemText>
                              <NavLink to='/login'>Login</NavLink>
                            </ListItemText>
                          </ListItemButton>
                        </List>
                      </nav>
                      {!isClanshopUrl && (
                          <>
                          {!location.pathname.includes('/share-analytics') && <>
                            {showLoginSignUp &&
                              <Link
                                to='/request-a-demo'
                                className='btn btn-outline-primary btn-rounded ml-auto d-md-flex d-none'
                              >
                                Request a demo
                              </Link>
                            }
                            <ul className='mobile_view_none'>
                              <li>
                                <NavLink to='/'>{showLoginSignUp && `Sign Up`}</NavLink>
                              </li>
                              <li>
                                <NavLink to='/login'>{showLoginSignUp && `Login`}</NavLink>
                              </li>
                            </ul>
                            <div
                              onClick={toggle}
                              className={
                                toggles
                                  ? 'header-toggle-button toggle-active'
                                  : 'header-toggle-button'
                              }
                            >
                              <span></span>
                              <span></span>
                              <span></span>
                            </div>
                          </>
}</>
                      )}
                    </>
                  )}
                </>
              )
              :
              (
                <>
                  <div className='app-header_brand_left_side'>
                    <a
                      className='logo'
                      href={getDashboardLink()}
                    >
                      <img src={ClanConnectLogo} alt='Clan Connect' />
                    </a>
                    {(validUrls.includes(location.pathname) && (props.UserReducer?.account_type === "Agency" && props.UserReducer?.view_admin_dashboard === 'True')) &&
                      <nav
                        className={`nav-menu`}
                        onClick={() => setToggles(false)}
                      >
                        <ul className='app_header_ul d-none d-lg-flex'>
                          <li
                            onClick={() => setProfileMenu(false)}>
                            <NavLink className='header_links position-relative' to="brand/admin-dashboard"> Admin Dashboard
                            </NavLink>

                          </li>
                        </ul>
                      </nav>
                    }

                    {!validUrls.includes(location.pathname) &&
                      <nav
                        className={`nav-menu ${toggles ? 'active_header' : 'diable_header'
                          }`}
                        onClick={() => setToggles(false)}
                      >
                        <ul className='app_header_ul d-none d-lg-flex'>
                          <>
                            {/* to not display talentdashboard in header of talent partner and settings */}

                            {
                              !shouldHideMenu && (
                                <>
                                  {HeaderMenu.map((menu: any) => {
                                    return (
                                      <li
                                        key={menu?.id}
                                        onClick={() => setProfileMenu(false)}
                                      >
                                        <NavLink
                                          className='header_links position-relative'
                                          to={isBasicUser && (menu.title === 'Profile View' || menu.title === 'Inbox') 
                                            && (props.UserReducer.account_type == 'Influencer' || props.UserReducer.account_type == 'Talent Partner') 
                                            ? 'javascript:void(0);' : menu?.url}
                                          onClick={() => handleNavLinkClick(menu)}
                                        >
                                          {menu?.url === location.pathname && (
                                            <motion.span
                                              style={{
                                                position: "absolute",
                                                width: '100%',
                                                height: '1px',
                                                top: '100%',
                                                left: 0,
                                                background: "#000",
                                              }}
                                              layoutId="underline"
                                            ></motion.span>
                                          )}
                                          <span>{menu?.title}</span>
                                        </NavLink>
                                      </li>
                                    );
                                  })}
                                </>
                              )}
                          </>
                        </ul>
                      </nav>
                    }
                  </div>

                  <AnimatePresence>
                    <motion.div className='app-header-brand_right_side' >
                      <>
                        <div className='header-profile-left'>

                          {!validUrls.includes(location.pathname) && ((props.UserReducer?.account_type === "Agency" && props.UserReducer?.role_name !== "Editor" && props.UserReducer?.role_name !== "Report") || (props.UserReducer?.account_type === "Talent Partner")) && (
                            <Fragment>
                              <ul className='header-premium'>

                                <li>
                                  {props.UserReducer?.account_type === "Agency" ? props.UserReducer?.brand_name : props.UserReducer?.name}
                                  {props.getSubscriptionDetail && !isBasicUser &&
                                    <span className='pro-tag ms-2' style={{ top: '34px', right: '35px', position: 'static' }}>PRO</span>
                                  }
                                </li>
                              </ul>
                              {/* <span className='brand_header_profile_picture '>
                              <img
                                src={
                                  props.UserReducer?.logo_url ? props.UserReducer?.logo_url :
                                  DefaultUserProfile.influencer_unknown_default
                                }
                                alt='pic'
                              />
                            </span> */}
                            </Fragment>
                          )}
                          {
                            <div className='d-flex flex-column'>
                              <ul className='header-premium'>
                                <li>{[...talentPartnerUrls, '/switch/account', '/switch/user'].includes(location.pathname) && (props.UserReducer?.account_type === "Talent Partner") && (
                                  <li>{props.UserReducer?.account_name}</li>
                                )}

                                  {[...agencyUrls, '/switch/account', '/switch/user'].includes(location.pathname) && (props.UserReducer?.account_type === "Agency" && props.UserReducer?.role_name !== "Editor" && props.UserReducer?.role_name !== "Report") && (
                                    <li>{props.UserReducer?.account_name}</li>
                                  )}
                                  {/* {(props.UserReducer?.account_type === "Agency" && props.UserReducer?.role_name !== "Editor" && props.UserReducer?.role_name !== "Report") && <>{props.UserReducer?.account_name}</>} */}
                                  {(props.UserReducer?.account_type === "Brand" || props.UserReducer?.account_type === "Influencer") && (
                                    <>{props.UserReducer?.full_name}
                                      {!['/switch/account', '/switch/user'].includes(location.pathname) &&
                                        <>
                                          {(props.UserReducer?.account_type === "Brand" || props.UserReducer?.account_type === "Influencer") && <>
                                            {props.getSubscriptionDetail && !isBasicUser &&
                                              <><span className='pro-tag ms-2' style={{ top: '34px', right: '35px', position: 'static' }}>PRO</span></>
                                            }
                                          </>
                                          }

                                        </>
                                      }
                                    </>)}

                                  {!['/switch/account', '/switch/user'].includes(location.pathname) &&
                                    <><li>
                                      {(props.UserReducer?.account_type === "Brand" || props.UserReducer?.account_type === "Influencer" || props.UserReducer?.account_type === "Talent Partner") && <>

                                        {isBasicUser &&
                                          <Button
                                            onClick={handleNavigation}
                                            variant='contained'
                                            className='btn btn-primary btn-let-us-help'
                                          >
                                            Upgrade
                                          </Button>
                                        }
                                      </>}

                                    </li>
                                    </>
                                  }
                                </li>
                              </ul>
                              {(props.UserReducer?.account_type === "Brand" || props.UserReducer?.account_type === "Agency") &&
                                <Button
                                  variant='contained'
                                  className='btn btn-primary btn-let-us-help'
                                  onClick={(e) => setOpenModal(true)}>
                                  Let us help you
                                </Button>
                              }
                            </div>
                          }
                          {/* {(props.UserReducer?.account_type === "Talent Partner" || props.UserReducer?.account_type === "Agency") && <Fragment>
                          <ul className='header-premium'>
                            <li>
                              {props.UserReducer?.account_type === "Agency" ? props.UserReducer?.brand_name : props.UserReducer?.name}
                            </li>
                          </ul>
                          <span className='brand_header_profile_picture '>
                            <img
                              src={
                                props.UserReducer?.logo_url ? props.UserReducer?.logo_url :
                                  DefaultUserProfile.influencer_unknown_default
                              }
                              alt='pic'
                            />
                          </span>
                          <div className="vr mx-3" ></div>
                        </Fragment>} */}
                        </div>
                        <span className='_header_profile_picture'>
                          {!(props.UserReducer?.account_type === "Talent Partner" || props.UserReducer?.account_type === "Agency") &&
                            <img
                              src={
                                headerPictureUrl
                              }
                              alt='profile'
                              onClick={headerProfileToggle}
                            />
                          }
                          {(props.UserReducer?.account_type === "Talent Partner" || props.UserReducer?.account_type === "Agency") && (
                            <>
                              {props.UserReducer?.account_type === "Agency" &&
                                ([...agencyUrls, '/switch/account', '/switch/user'].includes(location.pathname)) ? (
                                <img
                                  onClick={() => setProfileMenu(!profileMenu)}
                                  src={props.UserReducer?.photo || DefaultUserProfile.influencer_unknown_default}
                                  alt='pic'
                                />
                              ) : props.UserReducer?.account_type === "Talent Partner" &&
                                (![...talentPartnerUrls, '/switch/account', '/switch/user'].includes(location.pathname)) ? (
                                <>
                                  <img
                                    onClick={() => setProfileMenu(!profileMenu)}
                                    src={props.UserReducer?.photo || DefaultUserProfile.influencer_unknown_default}
                                    alt='pic'
                                  />
                                </>
                              ) : (props.UserReducer?.account_type === "Talent Partner" &&
                                ([...talentPartnerUrls, '/switch/user'].includes(location.pathname))) ? (
                                <>
                                  <img
                                    onClick={() => setProfileMenu(!profileMenu)}
                                    src={props?.switchLayer2?.photo || DefaultUserProfile.influencer_unknown_default}
                                    alt='pic'
                                  />
                                </>
                              ) : (
                                <>
                                  <img
                                    onClick={() => setProfileMenu(!profileMenu)}
                                    src={props.UserReducer?.logo_url || DefaultUserProfile.influencer_unknown_default}
                                    alt='pic'
                                  />
                                </>
                              )}
                            </>
                          )}




                          <ul
                            className='profile_image_toggle'
                            onClick={() => setProfileMenu(!profileMenu)}
                          >
                            <li></li>
                            <li></li>
                            <li></li>
                          </ul>

                        </span>

                        <AnimatePresence>
                          <motion.ul
                            className={`${profileMenu
                              ? 'profile_image_menu active'
                              : 'profile_image_menu'
                              }`}
                            variants={listVars}
                            initial="initial"
                            animate={profileMenu ? "animate" : "exit"}
                            exit="exit"
                          >
                            {(validUrls.includes(location.pathname) && (props.UserReducer?.account_type === "Agency" && props.UserReducer?.view_admin_dashboard === 'True')) &&

                              <div className='overflow-hidden d-lg-none'>
                                <motion.li
                                  variants={menuLinkVars}
                                  initial="initial"
                                  animate={profileMenu ? "open" : "initial"}
                                  onClick={() => setProfileMenu(false)}
                                >
                                  <NavLink
                                    className='header_links'
                                    to="brand/admin-dashboard"
                                  >
                                    <span>Admin Dashboard</span>
                                  </NavLink>
                                </motion.li>
                              </div>
                            }
                            {!['/switch/account', '/switch/user'].includes(location.pathname) && HeaderMenu.map((menu: any) => {
                              return (
                                <div className='overflow-hidden d-lg-none'>
                                  <motion.li
                                    variants={menuLinkVars}
                                    initial="initial"
                                    animate={profileMenu ? "open" : "initial"}
                                    key={menu?.id}
                                    onClick={() => setProfileMenu(false)}
                                  >
                                    <NavLink
                                      className='header_links'
                                      to={isBasicUser && (menu.title === 'Profile View' || menu.title === 'Inbox') 
                                        && (props.UserReducer.account_type == 'Influencer' || props.UserReducer.account_type == 'Talent Partner') ? 'javascript:void(0);' : menu?.url}
                                      onClick={() => handleNavLinkClick(menu)}
                                    >
                                      <span>{menu?.title}</span>
                                    </NavLink>
                                  </motion.li>
                                </div>
                              );
                            })}
                            {/* {['Brand'].includes(props.UserReducer?.account_type) &&
                              <div className='overflow-hidden'>
                                <motion.li
                                  variants={menuLinkVars}
                                  initial="initial"
                                  animate={profileMenu ? "open" : "initial"}
                                  onClick={() => setProfileMenu(false)}
                                >
                                  <NavLink
                                    className='header_links'
                                    to='/welcome/brand'
                                    rel="noopener noreferrer"
                                  >
                                    <span>Get Started</span>
                                  </NavLink>

                                </motion.li>
                              </div>
                            } */}
                            {(location.pathname === '/switch/user' || (talentPartnerUrls || agencyUrls)) && (
                              props.UserReducer?.account_type === 'Agency' ||
                              props.UserReducer?.account_type === 'Talent Partner') &&
                              <div className='overflow-hidden'>
                                <motion.li
                                  variants={menuLinkVars}
                                  initial="initial"
                                  animate={profileMenu ? "open" : "initial"}

                                  onClick={() => {
                                    setProfileMenu(false);
                                    navigate('/switch/user');
                                  }}
                                >
                                  {' '}
                                  {/* <SwitchAccountIcon />  */}
                                  <span>{props.UserReducer?.account_type === 'Agency' ? 'All Brands' : 'All Influencers'}</span>
                                </motion.li>
                              </div>
                            }                         

                            {location.pathname === '/switch/user' && (
                              props.UserReducer?.account_type === 'Agency' ||
                              props.UserReducer?.account_type === 'Talent Partner') && (
                                <div className='overflow-hidden'>
                                  <motion.li
                                    variants={menuLinkVars}
                                    initial="initial"
                                    animate={profileMenu ? "open" : "initial"}
                                    onClick={() => setProfileMenu(false)}
                                  >
                                    {props.UserReducer?.account_type === 'Agency' ? (
                                      <NavLink
                                        className='header_links'
                                        to={'/agency/settings/personal-info'}
                                      >
                                        <span>Settings</span>
                                      </NavLink>
                                    ) : props.UserReducer?.account_type === 'Talent Partner' ? (
                                      <NavLink
                                        className='header_links'
                                        to={'/talent-partner/settings/personal-info'}
                                      >
                                        <span>Settings</span>
                                      </NavLink>
                                    ) : null} {/* No need for additional cases here */}
                                  </motion.li>
                                </div>
                              )}

                            {/* {['Influencer'].includes(props.UserReducer?.account_type) &&
                              <div className='overflow-hidden'>
                                <motion.li
                                  variants={menuLinkVars}
                                  initial="initial"
                                  animate={profileMenu ? "open" : "initial"}
                                  onClick={() => setProfileMenu(false)}
                                >
                                  <NavLink
                                    className='header_links'
                                    to={isBasicUser ? 'javascript:void(0);' : 'influencer/campaign-msg'}
                                    onClick={() => handleClickInbox()}
                                  >
                                   <span>Inbox</span>
                                 </NavLink>
                                </motion.li>
                              </div>
                            }
                               {['Brand'].includes(props.UserReducer?.account_type) &&
                              <div className='overflow-hidden'>
                                <motion.li
                                  variants={menuLinkVars}
                                  initial="initial"
                                  animate={profileMenu ? "open" : "initial"}
                                  onClick={() => setProfileMenu(false)}
                                >
                                  <NavLink
                                    className='header_links'
                                    // to={isBasicUser ? 'javascript:void(0);' : 'brand/campaign-msg'}
                                    to={'brand/campaign-msg'}
                                    // onClick={() => handleClickInbox()}
                                  >
                                   <span>Inbox</span>
                                 </NavLink>
                                </motion.li>
                              </div>
                            } */}
                                    {props.UserReducer?.account_type === 'Brand' ? (
                                      <div className='overflow-hidden'>
                                <motion.li
                                  variants={menuLinkVars}
                                  initial="initial"
                                  animate={profileMenu ? "open" : "initial"}
                                  onClick={() => setProfileMenu(false)}
                                >
                                      <NavLink
                                        className='header_links'
                                        to={'/brand/settings/personal-info'}
                                      >
                                        <span>Settings</span>
                                      </NavLink>
                                      </motion.li>
                                      </div>
                                    ) : props.UserReducer?.account_type === 'Influencer' ? (
                                      <div className='overflow-hidden'>
                                <motion.li
                                  variants={menuLinkVars}
                                  initial="initial"
                                  animate={profileMenu ? "open" : "initial"}
                                  onClick={() => setProfileMenu(false)}
                                >
                                      <NavLink
                                        className='header_links'
                                        to={'/influencer/settings/personal-info'}
                                      >
                                        <span>Settings</span>
                                      </NavLink>
                                      </motion.li>
                                      </div>
                                    ) : null} {/* No need for additional cases here */}
                                  
                            

                            <div className='overflow-hidden'>
                              <motion.li
                                variants={menuLinkVars}
                                initial="initial"
                                animate={profileMenu ? "open" : "initial"}
                                onClick={() => setProfileMenu(false)}
                              >
                                {/* <LiveHelpIcon />  */}
                                <NavLink
                                  className='header_links'
                                  to={`${BASE_URL_WEB}/faqs`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <span>FAQ</span>
                                </NavLink>
                              </motion.li>
                            </div>
                            {!['Agency', 'Brand', 'Talent Partner'].includes(props.UserReducer?.account_type) &&
                              <div className='overflow-hidden'>
                                <motion.li
                                  variants={menuLinkVars}
                                  initial="initial"
                                  animate={profileMenu ? "open" : "initial"}
                                  onClick={() => setProfileMenu(false)}
                                >
                                  <NavLink
                                    className='header_links'
                                    to='https://api.whatsapp.com/send?phone=9289315858'
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <span>Chat with us <WhatsAppIcon style={{ marginLeft: '5px', color: '#25D366' }} /></span>
                                  </NavLink>

                                </motion.li>
                              </div>
                            }

                            {accounts?.length > 1 && (
                              <div className='overflow-hidden'>
                                <motion.li
                                  variants={menuLinkVars}
                                  initial="initial"
                                  animate={profileMenu ? "open" : "initial"}

                                  onClick={() => {
                                    setProfileMenu(false);
                                    navigate('/switch/account');
                                  }}
                                >
                                  {' '}
                                  {/* <SwitchAccountIcon />  */}
                                  <span>Switch Account</span>
                                </motion.li>
                              </div>
                            )}
                            <div className='overflow-hidden d-md-none'>
                              <motion.li
                                variants={menuLinkVars}
                                initial="initial"
                                animate={profileMenu ? "open" : "initial"}
                                onClick={() => setProfileMenu(false)}
                              >
                                {/* <LiveHelpIcon />  */}
                                <NavLink
                                  className="header_links"
                                  to={`${BASE_URL_WEB}/privacy_policy`}
                                  target="_blank"
                                >
                                  <span className='d-flex align-items-center'>
                                    Privacy Policy
                                     <MaterialSymbol iconName='open_in_new' className='ps-1' fontweight='200' fontSize='16px' />
                                  </span>
                                </NavLink>
                              </motion.li>
                            </div>
                            <div className='overflow-hidden'>
                              <motion.li
                                variants={menuLinkVars}
                                initial="initial"
                                animate={profileMenu ? "open" : "initial"}
                                onClick={() => localService.logout()}
                              >
                                {/* <LogoutIcon />  */}
                                <span>Logout</span>
                              </motion.li>
                            </div>
                          </motion.ul>
                        </AnimatePresence>

                      </>
                    </motion.div>
                  </AnimatePresence>
                  <CommonPopUp
                    modalTitle="Let us help you"
                    modalContent=""
                    openModal={openModal}
                    onClose={() => onClose(false)}
                    className="modal-medium mx-auto"
                  >
                    <LetUsHelpYou userReducer={props.UserReducer} setOpenModal={setOpenModal} />
                  </CommonPopUp>
                </>
              )
            }
          </div>
        </header>
      }
    </>
  );
};
/*
    Connect all type of headers with redux
*/

// Get Redux State Data

const mapStateToProps = (state: any) => {
  return {
    isLogin: state.UserReducer.IsUserLogged,
    UserReducer: state.UserReducer.UserData,
    guest_user: state.guestUserReducer.guest_user,
    user_email_id: state.guestUserReducer.user_email_id,
    CartReducer: state.CartReducer,
    getSubscriptionDetail: state.Subscription.subscription_detail,
    accounts: state.SwitchLayer1Reducer.switchAccount,
    switchLayer2: state.SwitchLayer2Reducer.switchUser,
    switchLayer1: state.SwitchLayer1Reducer.switchAccount,
  };
};

// Update Redux State

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateSubscriptionDetail: (data: any) => dispatch(SubscriptionDetail(data)),
  };
};

// Common Header
const Common = connect(mapStateToProps, mapDispatchToProps)(Header);
export { Common as Header };
