import React from 'react'
import { Loading } from '../../../redux/action';
import { connect } from 'react-redux';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
interface Props{
    UserReducer: any;
    loading: (data: any) => void;
    subsGrowthStockChartData:any;
}
const FollowersGrowthChart:React.FC<Props> = (props: Props) => {
  const monthAbbreviations: { [key: string]: string } = {
    January: "Jan", February: "Feb", March: "Mar", April: "Apr",
    May: "May", June: "Jun", July: "Jul", August: "Aug",
    September: "Sep", October: "Oct", November: "Nov", December: "Dec"
  };

  const monthOrder: { [key: string]: number } = {
    Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6,
    Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12
  };

  // Format and sort months
  const sortedData = [...props.subsGrowthStockChartData]
    .map((entry: any) => {
      const [fullMonth, year] = entry.month.split(" ");
      const shortMonth = monthAbbreviations[fullMonth] || fullMonth; // Convert full month name to abbreviation
      return { 
        ...entry, 
        formattedMonth: `${shortMonth} ${year.slice(-2)}`, // "January 2025" → "Jan 25"
        year: parseInt(year), // Convert year to number
        monthNumber: monthOrder[shortMonth], // Get month number
      };
    })
    .sort((a, b) => b.year - a.year || b.monthNumber - a.monthNumber); // Sort by year first, then month

    const playsOptions = {
      chart: {
        type: "line",
        height:230
      },
      title: {
        text: "",
        align:'left'

      },
      xAxis: {
        type: "datetime",
        categories: sortedData.map((entry:any) => entry.formattedMonth),
      },
      yAxis: {
        title: {
          text: "Followers",
        },
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false, // Hides the export menu (dropdown)
      },
      series: [
        {
          name: "followers",
          data: sortedData.map((entry:any) => entry.followers_count),
        },
      ],
      
    };
  return (
    <>
                  <section className="brand-insight-highchart">
                <div className="brand-insight-highchart-top">
                  <strong className="brand-insight-highchart-title">Followers</strong>
                  {/* <FormControl className="ms-auto" variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      // value={age}
                      // onChange={handleChange}
                      label="Age"
                    >
                      <MenuItem value={1} selected>1 Month</MenuItem>
                      <MenuItem value={3}>3 Months</MenuItem>
                      <MenuItem value={6}>6 Months</MenuItem>
                    </Select>
                  </FormControl> */}
                </div>
                <div className="brand-insight-highchart-data">
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={playsOptions}
                  />
                </div>
              </section>
                  {/* <Grid item xs={12} sm={12} md={12}>
              <div className="run-camp-card mt-5 mb-5">
                <div
                  className="section-header brand-chart sml"
                  style={{ paddingTop: 10, margin: 0 }}
                >
                </div>
                <div className="col-md-12">
                <HighchartsReact
                  highcharts={Highcharts}
                  options={playsOptions}
              />
                </div>
              </div>
            </Grid> */}
    </>
  )
}

const mapStateToProps = (state: any) => {
    return {
      UserReducer: state.UserReducer.UserData,
    };
  };
  
  const mapDispatchToProps = (dispatch: any) => {
    return {
      loading: (data: any) => dispatch(Loading(data)),
    };
  };
  
  const connectToRedux = connect(mapStateToProps, mapDispatchToProps)(FollowersGrowthChart);
  
  export { connectToRedux as  FollowersGrowthChart };
  
  