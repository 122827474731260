import React, { useEffect, useState } from 'react';
import HighchartsExporting from 'highcharts/modules/exporting';
import Highcharts from 'highcharts';
import highchartsNoData from 'highcharts/modules/no-data-to-display';




import './chart.scss';
import Switch from '@mui/material/Switch';
import { FormControl, InputLabel, MenuItem, Select, Skeleton } from '@mui/material';
import { MenuPropsSelect, MenuPropsSelectSmall } from '../../core/utility/style';
import { Link } from 'react-router-dom';

const label = { inputProps: { 'aria-label': 'Switch demo' } };
interface Props {
  dataSource?: any[];
  title?: string;
  categories?: any[];
  chartType?: string;
  exporting?: boolean;
  dropdown?: boolean;
  height?: string;
  yAxisTitle?:string;
  legend?:boolean
  toolTipName?:string;
  emptyChart?:boolean;
  planId?:number;
  campaignId?:number;
  loading?:boolean;
}


if (typeof Highcharts === 'object') {
  HighchartsExporting(Highcharts)
}
export const LineChartDropDown: React.FC<Props> = (props: Props) => {
  const [chartId] = useState(Math.round(Math.random() * 1000));
  const [selectedLegend, setSelectedLegend] = useState<string>("");
  const [chartType, setChartType] = useState<string>(props?.chartType || 'spline'); // Default chart type is "spline"
  const [labelsEnabled, setLabelsEnabled] = useState<boolean>(false);
  const [data, setData] = useState<any>(props?.dataSource || []);

  useEffect(() => {
    try {
      highchartsNoData(Highcharts)

      let options: Highcharts.Options
      options = {
        chart: {
          type: chartType || 'spline', // Dynamically set chart type
          height: props.height
        },
        title: {
          text: props.title || '',
        },
        xAxis: {
          labels: {
            style: {
                textOverflow: 'none'
            }
        } ,
          categories: props.categories || [],
        },
        yAxis: {
          title: {
            text: props.yAxisTitle || 'Values',
          },
        },
        legend: {
          enabled: props.legend
      }, 
        plotOptions: {
          bar: {
            pointPadding: 0.2,
            borderWidth: 0,
            enableMouseTracking: true,
            dataLabels: {
              enabled: labelsEnabled
            }
          },
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
            enableMouseTracking: true,
            dataLabels: {
              enabled: labelsEnabled
            }
          },
          line: {
            lineWidth: 0,
            enableMouseTracking: true,
            dataLabels: {
              enabled: labelsEnabled
            }
          },
          spline: {
            lineWidth: 0,
            enableMouseTracking: true,
            dataLabels: {
              enabled: labelsEnabled
            }
          }
        },
        lang: {
          noData: 'No data to display!'
        },
        noData: {
          position: {
            "x": 0,
            "y": 0,
            "align": "center",
            "verticalAlign": "middle"
          }
        },
        series: data || [],
        exporting: {
          enabled: props?.exporting,
          buttons: {
            contextButton: {
              menuItems: [
                'viewFullscreen',
                'printChart',
                //'downloadPNG',
                'downloadJPEG',
                'downloadPDF',
                //'downloadSVG',
                'downloadCSV',
                'downloadXLS',
              ]
            }
          }
        },
        credits: {
          enabled: false
        }
      };

      const chart = Highcharts.chart(`container-${chartId}`, options);
      Highcharts.chart(`container-${chartId}`, options);
    } catch (error) {
      throw new Error(`StockChart component failed :: ${JSON.stringify(error)}`);
    }
  }, [chartId, props.title, chartType, labelsEnabled, props.categories, data]);

  useEffect(() => {
    setData(props.dataSource);
    handleLegendChange()
  }, [props.dataSource, selectedLegend
  ])


  const handleLegendChange = () => {

    if (selectedLegend) {
      const filteredData = props.dataSource?.filter(dataset => dataset.name === selectedLegend);
      setData(filteredData || []);

    } else {
      setData(props.dataSource || []);  // Reset data state to props.dataSource
    }
    setSelectedLegend(selectedLegend);
  };

  const handleLabelsCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLabelsEnabled(!labelsEnabled); // Update labelsEnabled state based on checkbox status
  };

  return (
    <div className='chart-container'>
      <div className='chart-option'>
      {(props?.emptyChart && props?.campaignId && props?.campaignId > 0) ? (
        <span style={{marginRight:"auto"}}>*Add relevant post link to view campaign analytics <Link rel="stylesheet" to={`/brand/campaigns/analytics?planID=${props.planId}&campaignID=${props.campaignId}`}> here</Link></span>):(<></>)
      }
        {(props.loading || (props?.dropdown && data?.length > 0)) && (
          <FormControl size="small">
           <Select
             labelId="demo-select-small-label"
             id="demo-select-small"
             value={selectedLegend || ""}
             onChange={(e) => setSelectedLegend(e.target.value)}
             displayEmpty
             MenuProps={MenuPropsSelectSmall}
             className='dropdown-small'
           >
              <MenuItem value={""}>All</MenuItem>
              {props.dataSource?.map((dataset, index) => (
                <MenuItem key={index} value={dataset.name}>{dataset?.name}</MenuItem>
              ))}
           </Select>
         </FormControl>
        )}
        {
          (props.loading || data.length > 0) && (
            <div className='chart-label'>
              <label>Show Values</label>
              {props.loading  ?  <Skeleton width={45} /> : <Switch {...label} checked={labelsEnabled} onChange={handleLabelsCheckboxChange} />}
            </div>
          )
        }

        {/* <div>
          <button onClick={() => setChartType('column')}>Column</button>
          <button onClick={() => setChartType('line')}>Line</button>
          <button onClick={() => setChartType('spline')}>Spline</button>
          <button onClick={() => setChartType('bar')}>Bar</button>
       </div> */}
      </div>
      <div id={`container-${chartId}`} />
    </div>
  );
};

