import { Grid, Skeleton } from '@mui/material';
import React from 'react'
import { Methods } from '../../../../core/utility';
import MaterialSymbol from '../../../../components/icons/material-symbol';

type Props = {
  postFilterData: any;
  analyticsCumulativeDataMediaData: any;
  campaign_platform: any;
  analyticsCumulativeDataNumericData: any;
  totalCost?: any;
  creatives?: any;
  UserReducer?: any;
  loading?: any;
  totalPost?:any;
}

const methods: Methods = new Methods();

const AnalyticsDashboard = (props: Props) => {
  return (
    <>
      <Grid container spacing={2}>
        {props?.UserReducer?.account_type !== 'Influencer' &&
          <Grid item xs={12} md={6} lg={4}>
            <div className="upper-grid-div">
              <span> Total Influencers</span>
              <strong>
                {props?.loading ? <Skeleton /> : <>{(props?.analyticsCumulativeDataMediaData?.total_influencers === null || props?.analyticsCumulativeDataMediaData?.total_influencers === undefined) ? "NA" : methods.formatInfluencerProfileNumber(props?.analyticsCumulativeDataMediaData?.total_influencers)}</>}</strong>
            </div>
          </Grid>
        }
        {props?.UserReducer?.account_type === 'Influencer' &&
          <Grid item xs={12} md={6} lg={4}>
            <div className="upper-grid-div">
              <span> Total Creatives</span>
              <strong>
                {(props?.creatives === null || props?.creatives === undefined || props?.creatives.length === 0) ? "NA" : methods.formatInfluencerProfileNumber(props?.creatives)}</strong>
            </div>
          </Grid>
        }
        <Grid item xs={12} md={6} lg={4}>
          <div className="upper-grid-div">
            <span>Total Posts </span>
            <strong>{props?.totalPost === 0 ? "NA" : methods.formatInfluencerProfileNumber(props?.totalPost || 0)}</strong>
          </div>
        </Grid>
        {/* {props?.campaign_platform === 'Instagram' &&
          <Grid item xs={12} md={6} lg={4}>
            <div className="upper-grid-div">
              <span>Total Reach</span>
              <strong>{props?.analyticsCumulativeDataMediaData?.total_reach > 0 ? methods.formatInfluencerProfileNumber(props?.analyticsCumulativeDataMediaData?.total_reach) : "NA"}</strong>
            </div>
          </Grid>
        } */}
        <Grid item xs={12} md={6} lg={4}>
          <div className="upper-grid-div">
            <span>Engagement Rate</span>
            <strong>{props?.analyticsCumulativeDataNumericData?.avg_er_without_plays?.toFixed(2)}%
            </strong>
          </div>
        </Grid>
        {props?.campaign_platform === 'Youtube' &&
          <Grid item xs={12} md={6} lg={4}>
            <div className="upper-grid-div">
              <span>Views</span>
              <strong>{props?.analyticsCumulativeDataNumericData?.total_views == null ? "NA" : methods.formatInfluencerProfileNumber(props?.analyticsCumulativeDataNumericData?.total_views)}</strong>
            </div>
          </Grid>
        }
        {/* {props?.UserReducer?.account_type !== 'Influencer' &&
          <Grid item xs={12} md={6} lg={4}>
            <div className="upper-grid-div">
              <span>Amount Spent</span>
              <strong className='d-flex'>{props?.totalCost > 0 ? <><MaterialSymbol iconName="currency_rupee" fontweight="200" />{props?.totalCost}</> : "NA"}</strong>
            </div>
          </Grid>
        } */}
        {props?.campaign_platform === 'Instagram' &&
          <Grid item xs={12} md={6} lg={4}>
            <div className="upper-grid-div">
              <span>Views</span>
              <strong>{(props?.analyticsCumulativeDataNumericData?.total_plays === null || props?.analyticsCumulativeDataNumericData?.total_plays === undefined) ? "NA" : methods.formatInfluencerProfileNumber(props?.analyticsCumulativeDataNumericData?.total_plays)}</strong>
            </div>
          </Grid>
        }
        <Grid item xs={12} md={6} lg={4}>
          <div className="upper-grid-div">
            <span>Likes</span>
            <strong>{(props?.analyticsCumulativeDataNumericData?.total_likes === null || props?.analyticsCumulativeDataNumericData?.total_likes === undefined) ? "NA" : methods.formatInfluencerProfileNumber(props?.analyticsCumulativeDataNumericData?.total_likes)}</strong>
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <div className="upper-grid-div">
            <span>Comments</span>
            <strong>{(props?.analyticsCumulativeDataNumericData?.total_comments === null || props?.analyticsCumulativeDataNumericData?.total_comments === undefined) ? "NA" : methods.formatInfluencerProfileNumber(props?.analyticsCumulativeDataNumericData?.total_comments)}</strong>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default AnalyticsDashboard