
import IconComment from '../../../components/icons/icon-comment';
import { Link } from 'react-router-dom';
import { Grid, IconButton, Skeleton, Tooltip } from '@mui/material';
import MaterialSymbol from '../../../components/icons/material-symbol';
import IconImpression from '../../../components/icons/icon-impression';
import { DummyCampaignImage } from '../../../config/config';
import { Methods } from '../../../core/utility';
import Slider from 'react-slick';
type Reel = {
  shortcode: string;
  gcp_media_url: string;
  like_count: number;
  comments_count: number;
  saved: number;
  impressions: number;
  engagement: number;
  plays: number;
  reach: number;
};

type Props = {
  influencerData: {
    most_viewed_reels: Reel[];
    most_liked_carousel: Reel[]
    // Add other properties if necessary
  };
  loading?:boolean;
};
const methods = new Methods()
const InsightMediaReelsCard = (props: Props) => {
    const settings = {
    dots: false, // Show dots for navigation
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Show 4 posts at a time
    slidesToScroll: 1,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          // centerPadding: '30px',
          infinite: false,
          initialSlide: 0.75,
          slidesToScroll: 1,
          slidesToShow: 1.25
        }
      }
    ]
  };
  return (
    <Slider {...settings} className='custom-slick-slider'>
      {props.loading ? // Render Skeletons when loading
      Array.from({ length: 4 }).map((_, index) => (
        <Grid key={index} item className='posts-story-grid'>
          <div className="card-posts card-posts-stories">
            <span className="card-posts-image embed-responsive embed-responsive-1by1">
              <Skeleton width={218} variant="rectangular" height={264} style={{marginBottom:'0.25rem'}}  />
            </span>
          </div>
          <div className="card-posts-info">
            <Skeleton width={218} variant="rounded" height={79}  />
          </div>
        </Grid>
      ))
    : 
      props.influencerData && props.influencerData.most_viewed_reels?.length > 0 && props.influencerData.most_viewed_reels?.map((reel: any) => {
        return <div key={reel?.shortcode} className="card-posts">
            <Link
              to={`https://www.instagram.com/reel/${reel?.shortcode}`}
              className="card-posts-image embed-responsive embed-responsive-1by1"
              target="_blank"
            >
              <img
                className="embed-responsive-item"
                src={(reel?.gcp_media_url === "undefined" || reel?.gcp_media_url === "") ? DummyCampaignImage.default_campaign_image : reel?.gcp_media_url}
                width="100%"
                alt="Reels"
              />

            </Link>
            {((reel?.like_count != 0) || (reel?.comments_count != 0) || (reel?.plays != 0) || (reel?.reach != 0)) &&
              <div className="card-posts-info">
                <ul>
                  {reel?.like_count > 0 &&
                  <li>
                    {/* <strong>Likes</strong> */}
                    <Tooltip title="Likes" arrow placement='top'>
                      <IconButton className='p-0' size='small'>
                        <MaterialSymbol iconName='favorite' fontweight='200' className='text-white' />
                      </IconButton>
                    </Tooltip>
                    <span>{methods.convertNumber(reel?.like_count)}</span>
                  </li>
                  }
                  {reel?.comments_count > 0 &&
                  <li>
                    {/* <strong>Comments</strong> */}
                    <Tooltip title="Comments" arrow placement='top'>
                      <IconButton className='p-0' size='small'>
                        <IconComment />
                      </IconButton>
                    </Tooltip>
                    <span>{methods.convertNumber(reel?.comments_count)}</span>
                  </li>
                  }
                  {reel?.plays > 0 &&
                  <li>
                    {/* <strong>Plays</strong> */}
                    <Tooltip title="Views" arrow placement='top'>
                      <IconButton className='p-0' size='small'>
                        <MaterialSymbol iconName='play_arrow' fontweight='200' className='text-white' fontSize='38px' lineHeight='24px'/>
                      </IconButton>
                    </Tooltip>
                    <span>{methods.convertNumber(reel?.plays)}</span>
                  </li>
                  }
                  {reel?.reach > 0 &&
                    <li>
                      {/* <strong>Reach</strong> */}

                      <Tooltip title="Reach" arrow placement='top'>
                        <IconButton className='p-0' size='small'>
                          <IconImpression />
                        </IconButton>
                      </Tooltip>
                      <span>{methods.convertNumber(reel?.reach)}</span>
                    </li>
                  }
                  {/* <li>
                    <strong>Impressions</strong>
                    <Tooltip title="Impressions" arrow placement='top'>
                      <IconButton className='p-0' size='small'>
                        <MaterialSymbol iconName='fingerprint' fontweight='200' className='text-white'/>
                      </IconButton>
                    </Tooltip>
                    <span>{reel?.impressions === 0 ? "NA" : methods.convertNumber(reel?.impressions)}</span>
                  </li>
                  <li>
                    <strong>Saved</strong>
                    <Tooltip title="Saved" arrow placement='top'>
                      <IconButton className='p-0' size='small'>
                        <MaterialSymbol iconName='bookmark' fontweight='200' className='text-white'/>
                      </IconButton>
                      </Tooltip>

                    <span>{reel?.saved === 0 ? "NA" : methods.convertNumber(reel?.saved)}</span>
                  </li>
                  
                  <li>
                    <strong>Engagement</strong> 
                    <Tooltip title="Engagement" arrow placement='top'>
                      <IconButton className='p-0' size='small'>
                        <MaterialSymbol iconName='touch_app' fontweight='200' className='text-white'/>
                      </IconButton>
                    </Tooltip>
                    <span>{reel?.engagement === 0 ? "NA" : methods.convertNumber(reel?.engagement)}</span>
                  </li> */}
                </ul>
              </div>
           }
          </div>
        
      })}
    </Slider>

  )
};


export default InsightMediaReelsCard;