import React from 'react'
import { Loading } from '../../../redux/action';
import { connect } from 'react-redux';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import Grid from '@mui/material/Grid';
interface Props{
    UserReducer: any;
    loading: (data: any) => void;
    subsGrowthStockChartData:any;
}
const ContentTypeChart:React.FC<Props> = (props: Props) => {
    const processedData = props.subsGrowthStockChartData.map((item:any) => {
      if (item.media_type === 'CAROUSEL_ALBUM') {
        return {
          ...item,
          media_type: 'IMAGE',
        };
      }
      return item;
    });
    // Prepare categories (months)
    const monthOrder:any = {
      Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6,
      Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12
    };
    const months:any = [...new Set(
      processedData.map((item: any) => {
        const [month, year] = item.month_year.split(" ");
        return `${month} ${year.slice(-2)}`; // Convert "2025" to "25"
      })
    )]
    .sort((a:any, b:any) => {
      const [monthA, yearA] = a.split(" ");
      const [monthB, yearB] = b.split(" ");
      return yearB - yearA || monthOrder[monthB] - monthOrder[monthA]; // Sort by year first, then by month
    });
    // Prepare series data
    const seriesData:any= {
      IMAGE: { counts: [], ers: [] },
      VIDEO: { counts: [], ers: [] },
    };
    // Populate series data
    months.forEach((month:any) => {
      const filteredData = processedData.filter((item:any) => {
        const [m, y] = item.month_year.split(" ");
        return `${m} ${y.slice(-2)}` === month;
      });

      Object.keys(seriesData).forEach(key => {
        const typeData = filteredData.find((item:any) => item.media_type === key) || { media_type_count: 0, total_er_val: 0 };
        seriesData[key].counts.push(typeData.media_type_count);
        seriesData[key].ers.push(typeData.total_er_val);
      });
    });
    // Highcharts options
    const options = {
      chart: {
        type: 'bar',
        height: '230px'
      },
      title: {
        text: '',
        align:''

      },
      xAxis: {
        categories: months,
      },
      yAxis: {
        title: {
          text: 'Count / ER%',
        },
      },
      exporting: {
        enabled: false, // Hides the export menu (dropdown)
      },
      plotOptions: {
        series: {
          color: '#FF0000'
        },
        column: {
          stacking: 'normal'
        }
      },
       series: [{
      type: 'column',
      name: 'Video',
      data: seriesData.VIDEO.counts,
      stack: 'Europe',
      color: '#63ABFD',
      pointWidth: 5,
    }, {
      type: 'column',
      name: 'Image',
      data: seriesData.IMAGE.counts,
      stack: 'Europe',
      color: '#FFDC82',
      pointWidth: 5,
    }]
      // series: [
      //   {
      //     name: 'IMAGE',
      //     data: seriesData.IMAGE.counts,
      //   },
      //   {
      //     name: 'IMAGE ER%',
      //     data: seriesData.IMAGE.ers,
      //   },
      //   {
      //     name: 'VIDEO',
      //     data: seriesData.VIDEO.counts,
      //   },
      //   {
      //     name: 'VIDEO ER%',
      //     data: seriesData.VIDEO.ers,
      //   },
      // ],
      
    };
    
  return (
    <>
              <section className="brand-insight-highchart">
                <div className="brand-insight-highchart-top">
                  <strong className="brand-insight-highchart-title">Content Type</strong>
                </div>
                <div className="brand-insight-highchart-data">
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                  />
                </div>
              </section>
    </>
  )
}

const mapStateToProps = (state: any) => {
    return {
      UserReducer: state.UserReducer.UserData,
    };
  };
  
  const mapDispatchToProps = (dispatch: any) => {
    return {
      loading: (data: any) => dispatch(Loading(data)),
    };
  };
  
  const connectToRedux = connect(mapStateToProps, mapDispatchToProps)(ContentTypeChart);
  
  export { connectToRedux as  ContentTypeChart };
  
  