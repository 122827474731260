import React, { useEffect, useRef, useState } from 'react';
import Highcharts, { AlignValue, OptionsLayoutValue, VerticalAlignValue } from 'highcharts';
import HighchartsVariablePie from 'highcharts/modules/variable-pie';

HighchartsVariablePie(Highcharts);



interface Props {
    dataSource: any[];
    title?: string;
    showInLegend?: boolean;
    height?: number;
    width?: number;
    verticalAlign?: VerticalAlignValue;
    align?: AlignValue;
    layout?: OptionsLayoutValue
    property?: string
    size?: string
    className?: string;
    innerSize?: string
    itemMarginBottom?: number;
    itemMarginTop?: number;
    spacingBottom?:number;
    marginBottom?:  number;
    marginTop?:  number;
    distance?: number,
    itemWidth?: number
    responsiveItemWidth?: number
    responsiveLegendWidth?: any;
    responsiveSize?:any;
    type?:any;
    zMin?:any;   
    zMax?:any;
    options?:any;
}

export const PieChart: React.FC<Props> = (props: Props) => {
    try {
        // const colors = (Highcharts.getOptions()?.colors ?? []).map((c, i) =>
        //     Highcharts.color((Highcharts.getOptions()?.colors ?? [])[0])
        //         .brighten((i - 3) / 7)
        //         .get()
        // );
        const colors = [
            '#f9d1d1',
            '#ffa4B6',
            '#f765a3',
            '#a155b9',
            '#165baa',
            '#0b1354',
        ];

        const chartOptions: Highcharts.Options = {
            chart: {
                type:props.type || 'pie',
                backgroundColor: "transparent",
                height: props.height || 400,
                width: props.width,
                spacingBottom: props?.spacingBottom || -10, // Reduce space below the chart
                marginBottom: props?.marginBottom || 20,
                marginTop:props?.marginTop || -25,
            },
            legend: {
                layout: props.layout || 'horizontal',
                align: props.align || "center",
                verticalAlign: props.verticalAlign || 'bottom',
                itemMarginTop: props?.itemMarginTop || 10,  // Reduce space between legend items
                itemMarginBottom: props?.itemMarginBottom || 10, // Reduce space between legend and chart
                labelFormat: '<b>{name}</b> <br> {percentage:.1f}%',
                width: props?.width || 'auto',
                itemWidth: props?.itemWidth || 75

            },
            title: {
                text: props.title,
            },
            credits: {
                enabled: false,
            },
            tooltip: {
                pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
            },
            accessibility: {
                point: {
                    valueSuffix: "%",
                },
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    innerSize: props.innerSize,
                    cursor: "pointer",
                    borderRadius: 0,
                    center: ['50%', '50%'],
                    size: props.size || '80%', // Reduce size to avoid overlap
                    // showInLegend: props.showInLegend,
                    dataLabels: {
                        enabled: true,
                        format: "<b>{point.name}</b><br>{point.percentage:.1f} %",
                        distance: props.distance || -50,
                        filter: {
                            // property: props.property,
                            operator: ">",
                            value: 4,
                        },
                        
                    },
                    colors
                },
                variablepie: { // This handles the variable pie type
                    minPointSize: 10,
                    maxPointSize: 300,
                    zMin: props.zMin || 0,
                    zMax: props.zMax || 1000,
                },
            },
            series: [
                {
                    name: "",
                    data: props.dataSource,
                    showInLegend: props?.showInLegend,
                    type: props.type || 'pie'
                },
            ],
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500,
                    },
                    chartOptions: {
                        plotOptions: {
                        pie: {
                          size: props.responsiveSize || '80%',  
                        }
                    },
                    legend: {
                        width: props?.responsiveLegendWidth,
                        itemWidth: props?.responsiveItemWidth
                    },
                    }
                },
                
                
                ]
            },
            exporting: {
                enabled: false,
                buttons: {
                  contextButton: {
                    menuItems: [
                      'viewFullscreen',
                      'printChart',
                      //'downloadPNG',
                      'downloadJPEG',
                      'downloadPDF',
                      //'downloadSVG',
                      'downloadCSV',
                      'downloadXLS',
                    ]
                  }
                }
              }
        };

        const chartRef = useRef(null);

        useEffect(() => {
            if (chartRef.current) {
                Highcharts.chart(chartRef.current, chartOptions);
            }
        }, [props.dataSource, chartOptions]);

        return <div ref={chartRef} className={props.className}></div>;
    } catch (error) {
        throw new Error(`PieChart component failed :: ${JSON.stringify(error)}`);
    }
};


