import React, { useState, useEffect } from "react";
import FollowerCardInline from "../follower-card-inline";
import { Methods } from "../../../../core/utility";
import FollowerCard from "../follower-card";
import IconProfileER from "../../../../components/icons/icon-profile-er";
import { Skeleton } from "@mui/material";

type Props = {
  influencerData: any;
  className?: string;
  loading?:boolean;
};
const methods = new Methods();

const FollowerSecInline = ({ influencerData, className, loading }: Props) => {
  const [avg_reach, setAvgReach] = useState<number>(0);
  const [est_generated_reach, setEstGeneratedReach] = useState<number>(0);

  useEffect(() => {
    if (influencerData?.detail?.[0]) {
      if (influencerData?.detail?.[0]?.average_reach > 0) {
        setAvgReach(influencerData?.detail?.[0]?.average_reach);
      } else {
        setAvgReach(influencerData?.detail?.[0]?.generated_reach || 0);
      }
      if (influencerData?.detail?.[0]?.est_follower_reach > 0) {
        setEstGeneratedReach(influencerData?.detail?.[0]?.est_follower_reach);
      } else {
        setEstGeneratedReach(
          influencerData?.detail?.[0]?.est_generated_reach || 0
        );
      }
    }
  }, [influencerData]);
  return (
    <>
      <div className={`d-none d-md-flex follower-sec-inline mb-3 ${className}`}>
        {(loading || avg_reach > 0) && (
          <FollowerCardInline
            loading={loading}
            title="Est. Reach"
            tooltip={true}
            tooltipText="Estimated Reach = (Followers × Reach Rate) + (Engagements × Viral Factor)"
            value=""
            value1={loading ? <Skeleton /> : avg_reach && methods.convertNumber(avg_reach)}
            multipleValue
          />
        )}

        {(loading || influencerData?.detail?.[0]?.avg_plays > 0) && (
          <FollowerCardInline
            title="Avg. Views"
            value={
              loading ? <Skeleton /> : influencerData?.detail?.[0]?.avg_plays &&
              methods.convertNumber(influencerData?.detail?.[0]?.avg_plays)
            }
          />
        )}

        {(loading || (influencerData?.detail?.[0]?.avg_comments !== undefined &&
          influencerData.detail[0].avg_comments !== 0) ||
          (influencerData?.detail?.[0]?.public_avg_comments !== undefined &&
            influencerData.detail[0].public_avg_comments !== 0) && (
              <FollowerCardInline
                title="Avg. Comments"
                value={
                  loading ? <Skeleton /> : influencerData?.detail?.[0]?.avg_comments !== undefined &&
                  influencerData.detail[0].avg_comments !== 0
                    ? methods.convertNumber(
                        Math.round(influencerData.detail[0].avg_comments)
                      )
                    : influencerData?.detail?.[0]?.public_avg_comments !==
                        undefined &&
                      influencerData.detail[0].public_avg_comments !== 0 &&
                      methods.convertNumber(
                        Math.round(influencerData.detail[0].public_avg_comments)
                      )
                }
              />
            ))}
        {(loading || influencerData?.profile_detail?.[0]?.media_count) && (
          <FollowerCardInline
            title="Total Posts"
            value={
              loading ? <Skeleton /> : influencerData?.profile_detail?.[0]?.media_count &&
              methods.convertNumber(
                Math.round(influencerData?.profile_detail?.[0]?.media_count)
              )
            }
          />
        )}
      </div>
      <div className={`d-md-none follower-sec-inline mb-3 ${className}`}>
        {(loading || influencerData?.profile_detail?.[0]?.media_count != undefined) && (
          <FollowerCardInline
            title="Total Posts"
            value={
              loading ?  <Skeleton /> : influencerData?.profile_detail?.[0]?.media_count &&
              methods.convertNumber(
                Math.round(influencerData?.profile_detail?.[0]?.media_count)
              )
            }
          />
        )}
        {(loading || influencerData?.profile_detail?.[0]?.er !== 0) && (
          <FollowerCardInline
            title="Engagement Rate"
            value={
              loading ? <Skeleton /> : influencerData?.profile_detail?.[0]?.er &&
              influencerData?.profile_detail?.[0]?.er + "%"
            }
          />
        )}
        {(loading || influencerData?.detail?.[0]?.avg_plays > 0) && (
          <FollowerCardInline
            title="Avg. Views"
            value={
              loading ? <Skeleton /> : influencerData?.detail?.[0]?.avg_plays &&
              methods.convertNumber(influencerData?.detail?.[0]?.avg_plays)
            }
          />
        )}
        {loading || (influencerData?.detail?.[0]?.avg_likes !== 0 &&
          influencerData?.detail?.[0]?.avg_likes !== undefined) && (
            <FollowerCardInline
              title="Avg. Likes"
              value={
                <>
                  {influencerData?.detail?.[0]?.avg_likes !== 0 &&
                  influencerData?.detail?.[0]?.avg_likes !== undefined
                    ? methods.formatInfluencerProfileNumber(
                        Math.round(influencerData.detail[0].avg_likes)
                      )
                    : influencerData?.detail?.[0]?.public_avg_likes !== 0 &&
                      influencerData?.detail?.[0]?.public_avg_likes !==
                        undefined &&
                      methods.formatInfluencerProfileNumber(
                        Math.round(influencerData.detail[0].public_avg_likes)
                      )}
                </>
              }
            />
          )}

        {(loading || (influencerData?.detail?.[0]?.avg_comments !== undefined &&
          influencerData.detail[0].avg_comments !== 0) ||
          (influencerData?.detail?.[0]?.public_avg_comments !== undefined &&
            influencerData.detail[0].public_avg_comments !== 0)) && (
              <FollowerCardInline
                title="Avg. Comments"
                value={
                  influencerData?.detail?.[0]?.avg_comments !== undefined &&
                  influencerData.detail[0].avg_comments !== 0
                    ? methods.convertNumber(
                        Math.round(influencerData.detail[0].avg_comments)
                      )
                    : influencerData?.detail?.[0]?.public_avg_comments !==
                        undefined &&
                      influencerData.detail[0].public_avg_comments !== 0 &&
                      methods.convertNumber(
                        Math.round(influencerData.detail[0].public_avg_comments)
                      )
                }
              />
            )}
        {(loading || influencerData?.detail?.[0]?.post_frequency > 0) && (
          <FollowerCardInline
            title="Post Frequency"
            value={
              loading ? <Skeleton /> : influencerData?.detail?.[0]?.post_frequency &&
              `${methods.convertNumber(
                influencerData.detail[0].post_frequency
              )}/week`
            }
          />
        )}
        {(loading || influencerData?.detail?.[0]?.total_impressions > 0) && (
          <FollowerCardInline
            tooltip
            tooltipText="Total number of times the IG User's IG Media have been viewed. Includes ad activity generated through the API, Facebook ads interfaces, and the Promote feature. Does not include profile views."
            title="Impressions"
            value={loading ? <Skeleton /> : methods.convertNumber(
              influencerData?.detail?.[0]?.total_impressions
            )}
          />
        )}
      </div>
    </>
  );
};

export default FollowerSecInline;
