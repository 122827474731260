import React, { useEffect, useState } from 'react';
import { DeliverableItemKeys } from './enums';
import { TableCellData } from './table_cell';
import MaterialSymbol from '../../../../../../../components/icons/material-symbol';
import { connect } from 'react-redux';
import dayjs from 'dayjs';

type StatusType = 'Pending' | 'Negotiating' | 'Accepted' | 'Rejected' | 'Shortlisted';

type CurrentSteps = 1 | 2 | 3 | 4;


interface FormDataType {
  negotiation_id: number;
  proposal_id: number;
  campaign_id: number;
  influencer_id: number;
  sm_id: number;
  deliverable_item: string;
  s_0_quantity: number;
  s_0_price: number;
  s_0_status: StatusType;
  s_0_date: string;
  s_1_quantity: number;
  s_1_price: number;
  s_1_status: StatusType;
  s_1_date: string;
  s_2_quantity: number;
  s_2_price: number;
  s_2_status: StatusType;
  s_2_date: string;
  s_3_quantity: number;
  s_3_price: number;
  s_3_status: StatusType;
  s_3_date: string;
  current_step: CurrentSteps;
  status: StatusType;
  proposal_datetime: string;
  created_at: string;
  updated_at: string;
}
interface Props {
  proposalDetail?: any[];
  enumdeliverableItems?: Record<string, string>;
  costing_model?: boolean;
  campaign_type?: boolean;
  status?: string;
  data?: any;
  profileData?: any;
  deliverableHeader?: any;
  tableData?: any;
  campaignPlatform?: any;
  UserReducer: any;
  selectedCampaign: any;
  throughWhatsapp?:any;
  

}

const CustomProposalTable: React.FC<Props> = (props: Props) => {
  const getInfluencerTypeId = () => {
    if(props?.throughWhatsapp == "false" && (props.UserReducer.account_type == "Brand" || props.UserReducer.account_type == "Agency")){
      if(props?.profileData){
        setInfluencerTypeId (props?.profileData?.infTypeId)
      }
    }
    else{
      const filteredInfluencers = props?.UserReducer?.influencer_ids?.filter((ele: any) => {
        return ele?.platform_type === props?.selectedCampaign?.campaign_platform;
      });

      // Then, map over the filtered array to set the influencer_type_id
      filteredInfluencers?.forEach((item: any) => {
        setInfluencerTypeId(item?.influencer_type_id);
      });

    }
  }

  
  
  const [tableCells, setTableCell] = useState<any[]>([]);
  const [influencerTypeId,setInfluencerTypeId] =useState()
  const [enumdeliverableItems] = useState<Record<DeliverableItemKeys, string>>({
    ig_reel: 'Reel',
    ig_story: 'Story',
    ig_carousel_image: 'Carousel Image Post',
    yt_shorts: 'Short',
    available_for_exclusivity: 'Exclusivity',
    ig_static: 'Static',
    digital_rights: 'Digital Rights',
    yt_dedicated_video: 'Dedicated Video',
    yt_integrated_video: 'Integrated Video',
    ["Package Cost 1"]:'Package Cost'
  });

  const [campaignType] = useState(props.selectedCampaign?.campaign_type || "Paid");
  const [costingModel] = useState(props.selectedCampaign?.campaign_cost_model || "Individual");
  useEffect(() => {
    if (props.data.length > 0) {
      setTableCell(renderTableCells({ data: props.data, step: props?.data[0]?.current_step || 1 ,campaignType:campaignType}));
    }
  }, [props.data])

  useEffect(() => {
    getInfluencerTypeId()
  }, [props.data])



  const calculateTotalCost = (step: number) => {
    let total = 0;
    for (let i = 0; i < props.data.length; i++) {
      if (props.data[i][`s_${step}_status`] !== "Rejected") {
        total += props.data[i][`s_${step}_price`];
      }
    }
    return total;
  };

  const renderTableCells = (props: { data: FormDataType[], step: number,campaignType:string }) => {
    let cells = [];
    for (let i = 1; i <= props.step; i++) {
      cells.push(
        <div key={`step-${i}`} className={`table-proposal-body-rest-col-container ${(costingModel?.includes("Package")) ? "extra-space" : ""}`}>
          {props?.data?.filter((ele:any) => ele.deliverable_item !="Package Cost 1").map((data: any, index: number) => {
            return (
              <>
                <TableCellData key={`${index}-${i}`} dataSet={data} step={i} userType="brand"  campaignType= {campaignType} costingModel ={costingModel}/>
              </>
            );
          })}
        </div>
      );
    }
    return cells;
  };
  return (
    <div className='proposal-negotiation-sec flex-grow-1'>
      <div className="proposal-negotiation-detail main-head">
        <div className="proposal-negotiation-table">
          <div className="table-proposal">

            <div className="table-proposal-header-row">
              <span className='table-proposal-header-first-col'>Deliverables</span>
              <div className='table-proposal-header-rest-col-container'>
                {props?.data?.filter((ele:any) => ele.deliverable_item !="Package Cost 1").map((item: any, index: number) => (
                  <span className="table-proposal-header-rest-col" style={{ width: props.data.length > 3 ? `calc(100% / ${props.data.length + 1})` : 'auto' }}>
                    {[enumdeliverableItems[item?.deliverable_item as DeliverableItemKeys]]}
                    <br />
                    {(item.deliverable_item === 'digital_rights') &&
                      props.data
                        ?.filter((pr: any) => (pr.deliverable_item === 'digital_rights'))
                        .map((pr: any) => `${pr.s_0_quantity} days`)}
                    {(item.deliverable_item === 'available_for_exclusivity') &&
                      props.data
                        ?.filter((pr: any) => (pr.deliverable_item === 'available_for_exclusivity'))
                        .map((pr: any) => `${pr.s_0_quantity} days`)}
                     {(item.deliverable_item === 'ig_static') &&
                      props.deliverableHeader
                        ?.filter((pr: any) => (pr.influencer_type_id === influencerTypeId))
                        .map((pr: any) => `No of Images: ${pr["ig_static_image"]}`)}

                  </span>
                ))}
              </div>
            </div>

          </div>
        </div>
      </div>
      {tableCells.map((row: any, rowIndex: number) => (
        <div className='proposal-negotiation-detail inner-content'>

          <div className='proposal-negotiation-table'>
            <div className="table-proposal-body">
              <React.Fragment key={rowIndex}>

                <div className={`table-proposal-body-first-col ${(costingModel?.includes("Package")) ? "extra-space" : ""}`}>
                  <span>
                    Quantity
                  </span>
                  {props?.selectedCampaign?.campaign_type === "Paid" && !costingModel?.includes("Package") && <span>Cost in ₹</span>}
                </div>
                {row}
              </React.Fragment>
            </div>
            { props?.selectedCampaign?.campaign_type !== "Barter" &&             
              <div className={`table-proposal-total-cost-row ${(costingModel?.includes("Package")) ? "extra-space" : ""}`}>
              <strong className='d-flex align-items-center fs-14'>
                Total Cost:
                <MaterialSymbol className='fs-16'
                  iconName='currency_rupee' fontweight='200' />
                {props?.data?.length > 0 && calculateTotalCost(rowIndex)}
              </strong>
            </div>
            }

          </div>
        </div>
      ))
      }

      {props?.selectedCampaign?.campaign_type == "Paid" && 
        <React.Fragment>
          {(() => {
            const { status, current_step, proposal_negotiation_updated_date } = props?.data[0] || {};
            const {account_type}= props.throughWhatsapp === "true" ? "Influencer" : props.UserReducer;
            if (status === "Accepted") {
              if (account_type === "Brand" || account_type === "Agency") {
                if (current_step === 1 || current_step === 3) {
                  return <p className='proposal-status-msg mt-auto mb-4'>You have accepted the proposal on {dayjs(proposal_negotiation_updated_date).format("DD MMM, YYYY")}</p>;
                } else if (current_step === 2 || current_step === 4) {
                  return <p className='proposal-status-msg mt-auto mb-4'>Influencer has accepted the proposal on {dayjs(proposal_negotiation_updated_date).format("DD MMM, YYYY")}</p>;
                }
              } else if (account_type === "Influencer" || account_type === "Talent Partner") {
                if (current_step === 1 || current_step === 3) {
                  return <p className='proposal-status-msg mt-auto mb-4'>Brand has accepted the proposal on {dayjs(proposal_negotiation_updated_date).format("DD MMM, YYYY")}</p>;
                } else if (current_step === 2 || current_step === 4) {
                  return <p className='proposal-status-msg mt-auto mb-4'>You have accepted the proposal on {dayjs(proposal_negotiation_updated_date).format("DD MMM, YYYY")}</p>;
                }
              }
            } else if (status === "Rejected") {
              if (account_type === "Brand" || account_type === "Agency") {
                if (current_step === 1 || current_step === 3) {
                  return <p className='proposal-status-msg-rejected mt-auto mb-4'>You have rejected the proposal on {dayjs(proposal_negotiation_updated_date).format("DD MMM, YYYY")}</p>;
                } else if (current_step === 2 || current_step === 4) {
                  return <p className='proposal-status-msg-rejected mt-auto mb-4'>Influencer has rejected the proposal on {dayjs(proposal_negotiation_updated_date).format("DD MMM, YYYY")}</p>;
                }
              } else if (account_type === "Influencer" || account_type === "Talent Partner") {
                if (current_step === 1 || current_step === 3) {
                  return <p className='proposal-status-msg mt-auto mb-4'>Brand has rejected the proposal on {dayjs(proposal_negotiation_updated_date).format("DD MMM, YYYY")}</p>;
                } else if (current_step === 2 || current_step === 4) {
                  return <p className='proposal-status-msg mt-auto mb-4'>You have rejected the proposal on {dayjs(proposal_negotiation_updated_date).format("DD MMM, YYYY")}</p>;
                }
              }
            }
          })()}
        </React.Fragment>
       } 
    </div>
  );
};


const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
    UserID: state.UserID.userID,
    selectedCampaign: state.InfSelectCamp.campaign,
  };
};

const connectToRedux = connect(mapStateToProps)(CustomProposalTable);

export { connectToRedux as CustomProposalTable };

