import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from "chart.js";
import { connect } from "react-redux";
import { Loading } from "../../../redux/action";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);


interface Props {
  UserReducer: any;
  loading: (data: any) => void;
  engagement?:any;
}

const BrandErVerticalChart: React.FC<Props> = (props: Props) => {
    const chartConfig = {
      labels: ["Nano", "Micro", "Micro Plus","Macro","Macro Plus", "Mega","Mega Plus"],
      datasets: [
        {
          label: "Engagement Rate",
          data: [
            props.engagement["Nano"] ?? 0,
            props.engagement["Micro"] ?? 0,
            props.engagement["Micro Plus"] ?? 0,
            props.engagement["Macro"] ?? 0,
            props.engagement["Macro Plus"] ?? 0,
            props.engagement["Mega"] ?? 0,
            props.engagement["Mega Plus"] ?? 0,
          ],
          backgroundColor: ["#FFA5CB", "#FFD232", "#63ABFD", "#1CF7C2","#F45DCE","#6CDBF3","#SDFGHG"], // Colors for 'All', 'Paid', and 'Organic'
          borderColor: ["#FFA5CB", "#FFD232", "#63ABFD", "#1CF7C2", "#F45DCE", "#6CDBF3","#SDFGHG"],
          borderWidth: 2,
          borderRadius: 5,
          barThickness: 17, // Adjusted thickness of the bars
        },
      ],
    };

  const chartOptions = {
    responsive: true,
    height: 250,
    indexAxis: "x" as const, // Set explicitly to 'x' for vertical bar chart (default orientation)
    plugins: {
      legend: {
        display: false, // Hide legend
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 100, // Assuming engagement rates are percentages
      },
      x: {
        grid: {
          display: false, // Optional: Hides gridlines on the x-axis
        },
      },
    },
    layout: {
      padding: {
        top:25
        // bottom: 5, // Adds padding at the bottom of the chart
      },
    },
  };

  return (
    <div style={{ minHeight:'200px',height: "100%",width:'255px' }}>
      <Bar data={chartConfig} options={chartOptions} />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(mapStateToProps, mapDispatchToProps)(BrandErVerticalChart);

export { connectToRedux as  BrandErVerticalChart };


