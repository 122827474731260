import React, { useEffect, useState } from 'react'
import FollowerCard from '../follower-card'
import { Methods } from '../../../../core/utility';
import IconProfileER from '../../../../components/icons/icon-profile-er';
import { Skeleton } from '@mui/material';


type Props = {
  influencerData:any;
  loading?:boolean;
}
const methods = new Methods();
const FollowerSection = ({ influencerData, loading }: Props) => {
  const formattedFollowers = methods.formatInfluencerProfileNumber(influencerData?.profile_detail?.[0]?.followers);
  const [avg_reach, setAvgReach] = useState<number>(0);
  const [est_generated_reach, setEstGeneratedReach] = useState<number>(0);

  useEffect(() => {
    if (influencerData?.detail?.[0]) {
      if (influencerData?.detail?.[0]?.average_reach > 0){
        setAvgReach(influencerData?.detail?.[0]?.average_reach);
      } else {
        setAvgReach(influencerData?.detail?.[0]?.generated_reach || 0);
      }
      if (influencerData?.detail?.[0]?.est_follower_reach > 0){
        setEstGeneratedReach(influencerData?.detail?.[0]?.est_follower_reach);
      } else {
        setEstGeneratedReach(influencerData?.detail?.[0]?.est_generated_reach || 0);
      }
    }
  }, [influencerData]);
  return (
    <>
    <div className="follower-sec d-none d-md-flex">
      {formattedFollowers &&
        <FollowerCard loading={loading} title="Followers" iconName="person" value={formattedFollowers} />
      }
     
      {(influencerData?.profile_detail?.[0]?.er !== 0) &&
         <FollowerCard className='d-none d-md-flex' mobileTitle mobileTitleText="ER" loading={loading} title="Engagement Rate" iconName='' iconImage={IconProfileER} value={influencerData?.profile_detail?.[0]?.er && influencerData?.profile_detail?.[0]?.er + '%'} />
      }
       {(influencerData?.detail?.[0]?.average_profile_views > 0) &&
          <FollowerCard iconName='' loading={loading} className="d-md-none" title="Avg. Profile Views" value={`${methods.convertNumber(influencerData?.detail?.[0]?.average_profile_views)}/day`} />
        }
      {((influencerData?.detail?.[0]?.avg_likes !== 0 && influencerData?.detail?.[0]?.avg_likes !== undefined)) &&
       
        <FollowerCard className='d-none d-md-flex' title="Avg. Likes" iconName="favorite" value={<>{
          (influencerData?.detail?.[0]?.avg_likes !== 0 && influencerData?.detail?.[0]?.avg_likes !== undefined)
              ? methods.formatInfluencerProfileNumber(Math.round(influencerData.detail[0].avg_likes))
              : (influencerData?.detail?.[0]?.public_avg_likes !== 0 && influencerData?.detail?.[0]?.public_avg_likes !== undefined && methods.formatInfluencerProfileNumber(Math.round(influencerData.detail[0].public_avg_likes)))
        }</>} />
      }
    </div>
    <div className="follower-sec d-md-none">
      {(loading || formattedFollowers) &&
        <FollowerCard title="Followers" iconName="person" value={loading ? <Skeleton width={100} /> : formattedFollowers} />
      }

      {(loading || avg_reach !== undefined) &&
          <FollowerCard loading={loading} iconName='person' className='' title="Est. Reach" tooltip={true} tooltipText='Estimated Reach = (Followers × Reach Rate) + (Engagements × Viral Factor)' value={loading ? <Skeleton width={100} /> : avg_reach && methods.convertNumber(avg_reach)} />
        }
       {(loading || influencerData?.detail?.[0]?.average_profile_views !== undefined) &&
          <FollowerCard loading={loading} iconName='' title="Avg. Profile Views" value={loading ? <Skeleton width={100} /> : influencerData?.detail?.[0]?.average_profile_views && `${methods.convertNumber(influencerData?.detail?.[0]?.average_profile_views)}/day`} />
        }
    </div>
    </>
  )
}

export default FollowerSection