import React from "react";
import "./brand-data.scss";
import { Loading } from "../../../redux/action";
import { connect } from "react-redux";
import { Methods } from "../../../core/utility/methods";
import MaterialSymbol from "../../../components/icons/material-symbol";

interface Props{
  UserReducer: any;
  loading: (data: any) => void;
  profileEr:any;
  postFrequency:any;
  paidFrequency:any;
  organicFrequency:any;
  paidOrganicEr:any;
  budget:any;
}
const methods = new Methods();


const MetricsCard: React.FC<Props> = (props: Props) => {
  return (
    <div className="metrics-container">
      <p className="metrics-note">
        <MaterialSymbol iconName="info" fontSize="19px" color="#56B4EC"  fontweight="200" className="me-1" />
        The budgets, amount spent, EMV and ROI mentioned here are an approximation based on our algorithm from more than 7 data points.
      </p>
      <div className="metrics-grid">
        <div className="metrics-item double">
          <h3>Budget</h3>
          <div className="valuesContainer">
            <div className="d-flex flex-column">
            <p className="value">INR {methods.formatNumberINR(props.budget[0].amount/3)} </p>
            <p className="subtext">Monthly</p>
            </div>
          <div className="d-flex flex-column">
          <p className="value">INR {methods.formatNumberINR(props.budget[0].amount*4)} </p>
          <p className="subtext">Annually</p>
          </div>
         
          </div>
          <div className="d-flex align-items-center">
          <img src="/likeIcon.png" alt="likeIcon" />
          <p className="compareInfo">Better than 80% of other Brands in your category</p>
          </div>
          
          {/* <p className="comparison">Better than 80% of other Brands in your category</p> */}
        </div>
        <div className="metrics-item">
          <h3>Post Frequency</h3>
          <p className="value">{props?.postFrequency[0].post_frequency.toFixed(2)}/week</p>
          <p className="subtext"></p>
          {/* <p className="comparison">Better than 80% of other Brands in your category</p> */}
          <div className="d-flex align-items-center mt-4">
          <img src="/likeIcon.png" alt="likeIcon" />
          <p className="compareInfo" style={{maxWidth:'134px'}}>Better than 80% of other Brands in your category</p>
          </div>
        </div>
        <div className="metrics-item">
          <h3>Engagement Rate</h3>
          <p className="value">{props?.profileEr[0].engagement_rate.toFixed(2)}%</p>
          {/* <p className="comparison">Better than 70% of other Brands in your category</p> */}
          <div className="d-flex align-items-center mt-4">
          <img src="/likeIcon.png" alt="likeIcon" />
          <p className="compareInfo" style={{maxWidth:'134px'}}>Better than 80% of other Brands in your category</p>
          </div>
        </div>
        <div className="metrics-item double">
          <h3>Paid Content</h3>
          {/* <p className="value">{props?.paidFrequency[0].paid_content_frequency}/week <span>Frequency</span></p> */}
          {/* <p className="value">18% <span>Engagement Rate</span></p> */}
          {/* <p className="comparison">Similar to 50% of other Brands in your category</p> */}
          <div className="valuesContainer">
            <div className="d-flex flex-column">
            <p className="value">{props?.paidFrequency[0]?.paid_content_frequency?.toFixed(2)}/week </p>
            <p className="subtext">Frequency</p>
            </div>
          <div className="d-flex flex-column">
          {props.paidOrganicEr.length > 0 && <p className="value">{props.paidOrganicEr.find((item: any) => item.post_type == "paid")?.average_er ?? 0}</p>}
          <p className="subtext">Engagement Rate</p>
          </div>
         
          </div>
          <div className="d-flex align-items-center">
          <img src="/likeIcon.png" alt="likeIcon" />
          <p className="compareInfo" style={{maxWidth:'184px'}}>Better than 80% of other Brands in your category</p>
          </div>
        </div>
        <div className="metrics-item double">
          <h3>Organic Content</h3>
          {/* <p className="value">{props?.organicFrequency[0]?.organic_content_frequency}/week <span>Frequency</span></p> */}
          {/* <p className="value">6% <span>Engagement Rate</span></p> */}
          {/* <p className="comparison comparison-negative">Lesser than 60% of other Brands in your category</p> */}
          <div className="valuesContainer">
            <div className="d-flex flex-column">
            <p className="value">{props?.organicFrequency[0]?.organic_content_frequency.toFixed(2)}/week</p>
            <p className="subtext">Frequency</p>
            </div>
          <div className="d-flex flex-column">
          {props.paidOrganicEr.length > 0 && <p className="value">{props.paidOrganicEr.find((item: any) => item.post_type == "organic")?.average_er ?? 0}</p>}
          <p className="subtext">Engagement Rate</p>
          </div>
         
          </div>
          <div className="d-flex align-items-center">
          <img src="/likeIcon.png" alt="likeIcon" />
          <p className="compareInfo" style={{maxWidth:'134px'}}>Better than 80% of other Brands in your category</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
    return {
      UserReducer: state.UserReducer.UserData,
    };
};
  
const mapDispatchToProps = (dispatch: any) => {
    return {
      loading: (data: any) => dispatch(Loading(data)),
    };
};
  
const connectToRedux = connect(mapStateToProps, mapDispatchToProps)(MetricsCard);
  
export { connectToRedux as  MetricsCard };