import React from 'react'
import MaterialSymbol from '../../../components/icons/material-symbol'
import { IconButton, Skeleton, Tooltip } from '@mui/material'
import IconProfileER from '../../../components/icons/icon-profile-er'

type Props = {
    title: string,
    value: any,
    iconName: string,
    mobileTitle?: boolean,
    mobileTitleText?: string,
    loading?:boolean,
    className?:string,
    tooltip?:boolean,
    tooltipText?:string,
  iconImage?: React.ComponentType<any>,
}

const FollowerCard = ({ tooltip, tooltipText,className, title, value, iconName, mobileTitle, mobileTitleText, loading, iconImage }: Props) => {
  return (
    <div className={`card-follower ${className ? className : ""} `}>
        <div className='card-follower__left'>
        <span className='card-follower__title'>{mobileTitle ? mobileTitleText : title}</span>
            {loading ? <Skeleton /> : <span className='card-follower__value'>{value}</span>}
        </div>
        {tooltip && 
          <Tooltip title={tooltipText} arrow>
              <IconButton className='p-0'>
                  <MaterialSymbol iconName='info' fontweight='200' />
              </IconButton>
          </Tooltip>
          }
        {iconName &&
          <MaterialSymbol iconName={iconName} fontweight='200' className='card-follower__icon' />
        }
        {
          iconImage &&
          <IconProfileER className='card-follower__icon' />
          
        }
    </div>
  )
}

export default FollowerCard