import React from 'react'
import { Loading } from "../../../redux/action";
import { connect } from "react-redux";
import "./brand-data.scss";
import { DefaultAccountLogo, DefaultUserProfile } from "../../../config/config";
import { Link } from 'react-router-dom';
import MaterialSymbol from '../../../components/icons/material-symbol';

interface Props {
  UserReducer: any;
  loading: (data: any) => void;
  topInfluencer: any;
  topCompetitors: any;
}

const TopInfluencer: React.FC<Props> = (props: Props) => {
  return (

    <div className="brand-insight-influencer-competitor">

      <div className="brand-insight-influencer-competitor-row">
        <strong>
          Top Influencers
          <Link to="">
            <MaterialSymbol iconName="chevron_right" fontweight="200" />
          </Link>
        </strong>
        <ul>
          {props.topInfluencer.slice(0, 6).map((influencer: any) => (
            <li>
              <img
                src={influencer.gcp_profile_pic_url.length > 0 ? influencer.gcp_profile_pic_url : DefaultUserProfile.influencer_unknown_default}
                alt={influencer.username}
                className="avatar"
                width={40}
                height={40}
                style={{ borderRadius: '50%', fontSize: '5px' }}
              />
            </li>
          ))}
        </ul>
      </div>
      <div className="brand-insight-influencer-competitor-row">
        <strong>
          Top Competitors
          <Link to="">
            <MaterialSymbol iconName="chevron_right" fontweight="200" />
          </Link>
        </strong>
        <ul>
          {props.topCompetitors.map((competitor: any) => (
            <li>
              <img
                key={competitor.id}
                src={competitor.gcp_profile_image_url}
                alt={competitor.insta_name}
                className="avatar"
                width={40}
                height={40}
                style={{ borderRadius: '50%' }}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(mapStateToProps, mapDispatchToProps)(TopInfluencer);

export { connectToRedux as TopInfluencer };

