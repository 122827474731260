import {
  Button,
  TextField,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Theme,
  useTheme,
  Grid,
  FormHelperText,
  Skeleton
} from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import React, { useEffect, useState } from 'react';
import './personal_info.scss';
// import { useSelector } from 'react-redux';
// import { PublicService } from '../../../../core/services';
import { FormValidation } from '../../../../core/utility';
import { Methods } from '../../../../core/utility';
import {
  PublicService,
  LocalService,
  UserService,
} from '../../../../core/services';
import { Loading } from '../../../../redux/action';
import { connect } from 'react-redux';
import { UserLogin } from '../../../../redux/action';
import dayjs from 'dayjs';
import FileUploadPopup from '../../../../components/popup/file-upload-popup';
import { DefaultUserProfile } from '../../../../config/config';
import { MenuPropsAutoComplete, MenuPropsAutoCompleteNoCheckBox, MenuPropsSelect } from '../../../../core/utility/style';
import * as gtUtil from "../../../../core/utility";
// Create an instance of LocalService class
const localService: LocalService = new LocalService();
// Create an instance of UserService class
const userService: UserService = new UserService();
// Create an instance of PublicService class
// const publicService: PublicService = new PublicService();

// To use predefined methods, to calculate age and get formatted date
const platformMethod = new Methods();

interface Props {
  loading: (data: any) => any;
  userData: any;
  updateAccountImage: (data: any) => void;
}

const InfluencerPersonalInfo: React.FC<Props> = (props: Props) => {
  const theme = useTheme();
  // const dispatch = useDispatch();
  const [disableField, setDisableField] = useState(true);
  const [buttonName, setButtonName] = useState('Edit');
  // Create new instance from FormValidation class
  const formValidation = new FormValidation();
  // Set influencer_id from props
  // Set form fields
  const [formData, setFormData] = useState({
    user_id: props.userData.user_id,
    influencer_id: props.userData.influencer_id,
    account_id: props.userData.account_id,
    account_type: props.userData.account_type,
    name: '',
    dob: '',
    gender: '',
    photo: '',
    primary_phone: null,
    primary_email: '',
    category_ids: [],
    location_id: 0,
    age: '',
  });

  // Set form error fields
  const [formError, setFormErrors] = useState<any>({
    name: { status: true, message: '' },
    category_ids: { status: true, message: '' },
    location_id: { status: true, message: '' }
  });

  // To store all locations from master locations
  const [masterLocations, setMasterLocations] = useState([]);
  // To set selected location
  const [selectedLocation, setSelectedLocation] = React.useState<string>('');
  // const [masterLocations, setMasterLocations] = useState<{
  //     location_id: number;
  //     city: string;
  //     state: string;
  //     country: string;
  //     priority: number;
  // }[]>([]);
  // To store all categories from master_categories
  const [masterCategories, setMasterCategories] = useState<string[]>([]);
  //For camera icon state 
  const [cameraIcon, setCameraIcon] = useState(false);
  //For Popup open state while upload image
  const [showUploadPopup, setShowUploadPopup] = useState<any>(false);
  //path for image upload on AWS
  const path = `accounts/${props.userData.account_id}/users`

  const [loading, setLoading] = useState(false)

  const publicService = new PublicService();
  useEffect(() => {
    props.loading(true);
    setLoading(true)
    userService.getInfluencerDetails(`${props.userData.influencer_id}`)
      .then((res) => {
        // Set category ids in the form of array.
        if (res?.data.rows[0]?.category_ids !== '') {
          res.data.rows[0].category_ids = res.data.rows[0]?.category_ids?.split(',')
        } else {
          res.data.rows[0].category_ids = []
        }
        // Set Account Form data
        setFormData(res.data.rows[0]);

        // set age as per existing dob if exist
        let changedAge = platformMethod.calculateAge(res.data.rows[0]?.dob);
        if (changedAge !== undefined && changedAge !== null) {
          setFormData({
            ...formData,
            ...res.data.rows[0],
            age: (changedAge.toString())
          });
        }
        // To set existing location
        setSelectedLocation(`${res?.data.rows[0]?.city}, ${res?.data.rows[0]?.state}, ${res?.data.rows[0]?.country}`);
        // Remove loader
        props.loading(false);
        setLoading(false)
      })

    //get Geo (Location)
    async function getLocations() {
      try {
        // props.loading(true);
        const res = await publicService.getLocation();
        if (res.data.status) {
          setMasterLocations(res?.data?.rows);
        } else {
          setMasterLocations([]);
        }
      } catch (error) {
        setMasterLocations([]);
        console.log(`get Geo (Location) error :: ${JSON.stringify(error)}`);
      } finally {
        //remove loader
        // props.loading(false);
      }
    }
    getLocations();

    PublicService.getGenre().then((res) => {
      setMasterCategories(res?.data?.rows);
      //remove loader
      props.loading(false);
    });
  }, []);

  // Calculate the minimum date (100 years ago)
  const minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - 100);

  // Calculate the maximum date (4 years ago)
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - 4);

  //Element Heights
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  // Extra component
  function getStyles(name: string, personName: string[], theme: Theme) {
    try {
      return {
        fontWeight:
          personName.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
      };
    } catch (error) { }
  }

  // Submit form logic
  const handlePersonalInfoFormData = (e: any) => {
    try {
      e.preventDefault();
      // Validate form
      // validating name
      const validateName = formValidation.GetNameValidate(
        formData.name
      );
      // validating existence of genre
      const validateGenre = formValidation.GenreControlIsValid(
        formData.category_ids
      );
      // validating existence of location_id
      const validateLocationId = formValidation.LocationControlIsValid(
        formData.location_id
      );
      setFormErrors({
        name: { ...validateName },
        category_ids: { ...validateGenre },
        location_id: { ...validateLocationId },
      });
      if (buttonName === 'Edit') {
        setButtonName('Update');
        setDisableField(false);
        setCameraIcon(true)
      }
      else if (validateName.status && validateGenre.status && validateLocationId.status) {
        try {
          props.loading(true);
          const promise = new Promise(async (resolve, reject) => {
            userService.updateInfluencerDetails(formData).then((res: any) => {
              if (res.status === gtUtil.Constants.SUCCESS) {
                props.updateAccountImage({ ...props.userData, ...formData });
                localService.toastify(res.info, 'success', 1000);
                setButtonName('Edit');
                setDisableField(true);
                setCameraIcon(false);
              } else {
                localService.toastify(res.info, 'error', 1000);
              }

              resolve(res?.data?.rows);
              // Remove loader
              props.loading(false);
            });
          });

          return promise;
        } catch (error) {
          throw new Error(`Handle Personal Info Save Error :: ${error}`);
        } finally {
          //remove loader
          props.loading(false);
        }
      }
    } catch (error) {
      throw new Error(
        `Influencer Account Update function error :: ${JSON.stringify(error)}`
      );
    } finally {
      //remove loader
      props.loading(false);
    }
  }

  // To handle dob
  const handleDob = (event: any) => {
    let birthDate = event.$d;
    let formatted_date = platformMethod.getDateMySQL(birthDate);
    setFormData({
      ...formData,
      dob: formatted_date
    });
    let changedAge = platformMethod.calculateAge(birthDate);
    let ageElement = document.getElementById('age');
    if (changedAge != undefined && changedAge != null && ageElement) {
      setFormData({
        ...formData,
        age: (changedAge.toString())
      });
    }
  };

  // To handle genre
  const handleGenre = (event: any) => {
    const {
      target: { value },
    } = event;
    if (formData?.category_ids?.length != 3) {
      setFormData({
        ...formData,
        category_ids: value
      });
      // setGenre(value);
    } else {
      if (value.length === 2) {
        setFormData({
          ...formData,
          category_ids: value
        });
      } else {
        setFormData({
          ...formData,
          category_ids: []
        });
      }
    }
  };

  // To handle location, to set selected location_id in formData
  const handleLocation = (event: any) => {
    const {
      target: { value },
    } = event;
    let loc = event.target.innerText;
    // setSelectedLocation(`${(loc == null || loc==undefined) ? [] : loc}`)
    if (loc != null && loc != undefined) {
      loc = loc.trim();
      setSelectedLocation(loc)
      const getLocation_id: any = masterLocations.find(
        (res: any) => `${res.city}, ${res.state}, ${res.country}` === loc
      );
      // form.location_id = getLocation_id.location_id;
      setFormData({
        ...formData,
        location_id: getLocation_id.location_id
      });
    } else {
      setSelectedLocation('')
      setFormData({
        ...formData,
        location_id: 0
      });
    }
  };

  const handleError = (event: any) => {
    // Validate form
    // validating name
    const validateName = formValidation.GetNameValidate(
      formData.name
    );
    // validating existence of genre
    const validateGenre = formValidation.GenreControlIsValid(
      formData.category_ids
    );
    // validating existence of location_id
    const validateLocationId = formValidation.LocationControlIsValid(
      formData.location_id
    );
    setFormErrors({
      name: { ...validateName },
      category_ids: { ...validateGenre },
      location_id: { ...validateLocationId },
    });
  }

  //Upload Photo 
  const handlePhoto = (photo_url: any) => {
    // set uploaded photo in formData
    setFormData({
      ...formData,
      photo: photo_url,
    })
  }

  return (
    <>
      <form onSubmit={handlePersonalInfoFormData} className='mb-4'>
        <div className='p-0  _influencer_setting_personal_info_main'>
          <div className='list-section-content rounded'>
            <strong className=''>Personal Information</strong>
            <div className='col-auto text-end'>
              <Button
                size='small'
                type='submit'
                variant='contained'
                className='btn btn-primary btn-mui btn-lg'
              >
                {buttonName}
              </Button>
            </div>
          </div>

          <div>
            <div className='row mb-3'>
              <div className='col-auto _influencer_setting_img'>
                {loading ? <Skeleton variant="circular" height={100} width={100} /> :
                  <> {cameraIcon ? <img
                    onClick={() => { setShowUploadPopup(!showUploadPopup) }}
                    src={formData.photo === ''
                      ? DefaultUserProfile.influencer_unknown_default
                      : formData.photo}
                    alt="User"
                    style={{ position: "relative", cursor: 'pointer' }}
                  /> : <img
                    src={formData.photo === ''
                      ? DefaultUserProfile.influencer_unknown_default
                      : formData.photo}
                    alt="User"
                    style={{ position: "relative" }
                    } />
                  }
                  </>
                }

                {cameraIcon && <AddAPhotoIcon className='add-photo-icon'
                  onClick={() => { setShowUploadPopup(!showUploadPopup) }} />}

                <div className='col-lg-3 '>
                  {showUploadPopup && (
                    <FileUploadPopup openModal={showUploadPopup} onClose={(e) => setShowUploadPopup(false)} onSend={(e) => handlePhoto(e.url)}
                      path={path}
                      acceptedFileTypes=".jpg, .jpeg, .png" />
                  )}
                </div>
              </div>
              <div className='col'>
                {loading ? <Skeleton height={40} /> :
                  <TextField
                    fullWidth
                    label='Name'
                    name='name'
                    variant='standard'
                    value={formData?.name}
                    disabled={disableField}
                    error={!formError?.name?.status}
                    helperText={
                      formError?.name?.message
                    }
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        name: e.target.value,
                      })
                    }
                    onBlur={handleError}
                  />
                }
              </div>
            </div>
          </div>
          <Grid container rowSpacing={2} spacing={2}>
            <Grid item xs={12} md={6}>
              {loading ? <Skeleton height={40} /> :
                <TextField
                  className='influencer_personal_info'
                  size='small'
                  fullWidth
                  name='primary_email'
                  label='Email'
                  variant='outlined'
                  defaultValue={formData?.primary_email}
                  disabled={true}
                  key={formData?.primary_email}
                />
              }
            </Grid>
            <Grid item xs={12} md={6}>
              {loading ? <Skeleton height={40} /> :
                <FormControl
                  className='w-100 input-style'
                  size='small'
                >
                  <InputLabel id='demo-simple-select-label'>
                    Gender
                  </InputLabel>

                  <Select
                    className='influencer_personal_info'
                    labelId='demo-simple-select-label'
                    id='influencer-gender'
                    value={formData?.gender}
                    name='gender'
                    displayEmpty
                    disabled={disableField}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        gender: e.target.value,
                      })
                    }
                    input={<OutlinedInput label='Gender' />}
                    MenuProps={MenuPropsSelect}
                  >
                    <MenuItem value={'Male'}>Male</MenuItem>
                    <MenuItem value={'Female'}>Female</MenuItem>
                    <MenuItem value={'Other'}>Other</MenuItem>
                  </Select>
                </FormControl>
              }
            </Grid>
            <Grid item xs={12} md={6}>
              {loading ? <Skeleton height={40} /> :
                <TextField
                  className='influencer_personal_info'
                  size='small'
                  fullWidth
                  name='primary_phone'
                  type='number'
                  label='Mobile'
                  variant='outlined'
                  defaultValue={formData?.primary_phone}
                  disabled={true}
                  key={formData?.primary_phone}
                />
              }
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid className='d-flex pt-0'>
                {loading ? <Skeleton height={40} /> :
                  <><LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                      {formData?.dob && (
                        <DatePicker
                          className='input-style date_picker'
                          label='Date of Birth'
                          defaultValue={dayjs(formData?.dob)}
                          disabled={disableField}
                          format='DD/MM/YYYY'
                          onChange={handleDob}
                          minDate={dayjs(minDate)}
                          maxDate={dayjs(maxDate)}
                        />
                      )}
                    </DemoContainer>
                  </LocalizationProvider>

                    {formData?.age && (
                      <TextField
                        size='small'
                        className='ms-2 influencer_personal_info input-style'
                        type='age'
                        id='age'
                        label='Age'
                        variant='outlined'
                        disabled={true}
                        style={{ width: '60px' }}
                        value={formData?.age}
                      />
                    )}</>
                }
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              {loading ? <Skeleton height={40} /> :
                <FormControl
                  className='w-100 input-style'
                  size='small'
                  disabled={disableField}
                >
                  <InputLabel id='demo-multiple-name-label'>
                    Genre (Max. 3)
                  </InputLabel>
                  <Select
                    disabled={disableField}
                    className='_genre_option influencer_personal_info'
                    labelId='demo-multiple-name-label'
                    id='Genre_id'
                    multiple
                    value={formData?.category_ids}
                    onChange={handleGenre}
                    onBlur={handleError}
                    input={<OutlinedInput label='Genre (Max. 3)' />}
                    MenuProps={MenuPropsSelect}
                    name='genre'
                    error={!formError?.category_ids?.status}
                  >
                    {masterCategories ? (
                      masterCategories?.map((res: any) => (
                        <MenuItem
                          key={res.category_id}
                          value={String(res.category_id)}
                          style={getStyles(
                            res.category,
                            formData?.category_ids,
                            theme
                          )}
                        >
                          {res.category}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem
                        key={1}
                        value='1'
                        style={getStyles(
                          'No Data',
                          formData?.category_ids,
                          theme
                        )}
                      >
                        {'No Data'}
                      </MenuItem>
                    )}
                  </Select>
                  {!formError?.category_ids?.status && (
                    <FormHelperText style={{ color: 'red' }}>
                      {formError?.category_ids?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              }
            </Grid>
            <Grid item xs={12} md={6}>
              {loading ? <Skeleton height={40} /> :
                <Autocomplete
                  className='influencer_personal_info'
                  size='small'
                  disablePortal
                  disabled={disableField}
                  id='combo-box-demo'
                  options={
                    masterLocations
                      ? masterLocations?.map(
                        (res: {
                          id: number;
                          city: string;
                          state: string;
                          country: string;
                        }) => {
                          return `${res.city}, ${res.state}, ${res.country}`;
                        }
                      )
                      : ['No Data']
                  }
                  value={selectedLocation} // Preselect the existing location
                  // onChange={(event, newValue) => {
                  //     setSelectedLocation(`${(newValue == null || newValue==undefined) ? [] : newValue}`)
                  // }}
                  onChange={handleLocation}
                  ListboxProps={MenuPropsAutoCompleteNoCheckBox.PaperProps}
                  onBlur={handleError}
                  renderInput={(params) => {
                    return (
                      <>
                        <TextField
                          name='location'
                          {...params}
                          label='Location'
                          error={!formError?.location_id?.status}
                          helperText={
                            formError?.location_id?.message
                          }
                        />
                      </>
                    );
                  }}
                />
              }
            </Grid>
          </Grid>
        </div>
      </form>

    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.UserReducer.UserData
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
    updateAccountImage: (data: any) => dispatch(UserLogin(data))
  };
};

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(InfluencerPersonalInfo);
export { connectToRedux as InfluencerPersonalInfo };
