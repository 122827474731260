import React, { useEffect, useState, useCallback, Fragment } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  LocalService,
  SubscriptionService,
} from '../../../../core/services';
// For subscription payment
import useRazorpay from 'react-razorpay';
import { SubscriptionDetail } from '../../../../redux/action';
import { makeRazorpayPayment } from "../../../../core/services/PaymentService";
// To render common pricing plan compoent
import PricingPlan from './pricing-plan';
// To render basic plan data
import { SubscriptionBasicPlanData } from './subscription-plan-data';
import * as gtUtil from '../../../../core/utility'

// const localService: LocalService = new LocalService();
const subscriptionService: SubscriptionService = new SubscriptionService();

interface Props {
  UserReducer: any;
  SubscriptionDetail: (data: any) => void;
}

const UserSubscription: React.FC<Props> = (props: Props) => {
  const [razorpay] = useRazorpay();
  const [availablePlan, setAvailablePlan] = useState<any>(null);
  const [activePlan, setActivePlan] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [planType, setPlanType] = useState('');
  const navigate = useNavigate();


  // To fetch the basic plan details
  const basicPlan: any = ['Influencer', 'Talent Partner'].includes(props?.UserReducer?.account_type) ? SubscriptionBasicPlanData.influencer : SubscriptionBasicPlanData.brand

  const handlePlanType = (tab: string) => {
    setPlanType(tab);
  }

  const getSubscriptionDetail = useCallback(() => {
    //get Subscription Plan Detail
    const { user_id, account_id, account_type, influencer_id } = props.UserReducer;
    async function getSubs() {
      try {
        const response = await subscriptionService.getPlanDetails({
          user_id: user_id,
          account_id: account_id,
          user_type: account_type,
          influencer_id: influencer_id,
        });
        if (response.status === gtUtil.Constants.SUCCESS) {
          //return Only Active Plan Info
          setAvailablePlan(response.data.rows);
          setPlanType(response.data.rows[0].plan_type);
        } else {
          // localService.toastify(response.data.status.message, 'info', 1000);
          setAvailablePlan(null);
        }

        //update subscription Details
        const getPlanDetails: any =
          await subscriptionService.getSubscription_Remaining_Days({
            account_id: props.UserReducer.account_id,
            user_id: props.UserReducer.user_id,
            influencer_id: props.UserReducer.influencer_id
          });

        if (getPlanDetails.status === gtUtil.Constants.SUCCESS) {
          props.SubscriptionDetail(getPlanDetails.data.rows[0]);
        }
      } catch (error) {
        throw new Error(
          `getSubsctiption Detail in Subscription Influencer :: ${JSON.stringify(
            error
          )}`
        );
      }
    }

    async function getActivePlan() {
      try {
        const response: any =
          await subscriptionService.getSubscription_Remaining_Days({
            user_id: user_id,
            account_id: account_id,
            influencer_id: influencer_id
          });
        if (response.status === gtUtil.Constants.SUCCESS) {
          setActivePlan(response.data.rows[0]);
        } else {
          setActivePlan(null);
        }
      } catch (error) {
        console.log(
          `getActivePlan error in influencer Section :: ${JSON.stringify(
            error
          )}`
        );
      }
    }
    getActivePlan();
    getSubs();
  }, [props.UserReducer]);

  useEffect(() => {
    getSubscriptionDetail();
  }, [getSubscriptionDetail]);

  // For subscription payment
  const makePayment = async (data: any) => {
    // Your makePayment function logic here
    setLoading(true);
    try {
      const paymentResponse = await makeRazorpayPayment(data, props, razorpay);
      if (paymentResponse?.status) {
        // Payment successful, perform necessary actions
        if (['Brand', 'Agency'].includes(props.UserReducer?.account_type)) {
          navigate(`/brand/orders/confirmed?payment_id=${paymentResponse?.paymentId}`);
        } else {
          navigate(`/influencer/orders/confirmed?payment_id=${paymentResponse?.paymentId}`);
        }
      } else {
        // Payment failed, handle it
        console.log("Payment failed");
        // localService.toastify(paymentResponse?.message, "error", 1000);
      }
    } catch (error) {
      console.error(`Make Payment Error :: ${JSON.stringify(error)}`);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Fragment>
      <div className='list-section-content rounded'>
        <div className='row align-items-center'>
          <strong className='col'>Subscription</strong>
          {props.UserReducer.agency_account_id && props.UserReducer.brand_id && (
            <span>On Behalf of Brand: <strong>{props.UserReducer.brand_name}</strong></span>
          )}
          {props.UserReducer.account_type === 'Talent Partner' && props.UserReducer.influencer_type === 'Talent Partner' && (
            <span>On Behalf of Influencer: <strong>{props.UserReducer.name}</strong></span>
          )}
        </div>
      </div>
      <main className='full-width pb-3 page-pricing'>
        <div className='pricing-tab p-0'>
          {
            planType && planType === 'Half-Yearly' && 
            <span>
              📢 Important Update: Our <strong>half-yearly</strong> subscription plan will be discontinued from <strong>1st November, 2024</strong>. Grab it before it's gone!
            </span>
          }
          <span className={`plan-switch-btns mt-4 ${props.UserReducer.account_type === 'Influencer' ? "d-none": ""}`}>
            <div className='sidebar-nav mb-0'>
              {availablePlan && availablePlan.map((plan: any) =>
                <span className={` sidebar-nav-link sml ${planType === plan.plan_type ? 'active' : ''}`} onClick={() => handlePlanType(plan.plan_type)}>{plan.plan_type}</span>
              )}
            </div>
          </span>
          <div className='tab-content' id='pricingTabContent'>
            <div className='pricing-sec-infl'>
              <div className='pricing-sec-right'>
                <div className='pricing-plan-sec'>
                  {
                    (basicPlan && props.UserReducer.account_type !=="Influencer") &&
                    (
                      <PricingPlan
                        key={basicPlan.id}
                        subscription_plan={basicPlan}
                        account_type={props.UserReducer.account_type}
                      />
                    )
                  }
                  {
                    availablePlan &&
                    (
                      <Fragment>
                        {availablePlan.map((data: any, index: number) => {
                          if (planType === data.plan_type) {
                            return (
                              <PricingPlan
                                key={data.id}
                                subscription_plan={data}
                                makePayment={makePayment} // Pass makePayment function as prop
                                loading={loading} // Pass loading state as prop
                                account_type={props.UserReducer.account_type}
                                activePlan={activePlan}
                              />
                            );
                          }
                          return null;
                        })}
                      </Fragment>
                    )
                  }
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    SubscriptionDetail: (data: any) => dispatch(SubscriptionDetail(data)),
  };
};

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSubscription);

export { connectToRedux as UserSubscription };
