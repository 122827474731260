// import React from 'react';

import { useState } from "react";

const stepper = [
  { id: 1, name: "Proposal", status: "active" },
  { id: 2, name: "First counteroffer", status: "active" },
  { id: 3, name: "Influencer response", status: "active" },
  { id: 4, name: "Final counteroffer", status: "active" },
  { id: 5, name: "Deal closed", status: "active" },
];

type Props = {
  step: any;
  data?: any;
  handleClick?: any;
};

export const ProposalNegotiationStep = ({ step, data }: Props) => {
  const currentStep = step && step[0]?.current_step;
  const isAcceptedOrRejected =
    data && data?.length > 0
      ? data[0]?.status === "Accepted" || data[0]?.status === "Rejected"
      : false;
  if (typeof currentStep === "number") {
    // Loop over the stepper array and log the steps up to the current step
    for (let i = 0; i <= currentStep; i++) {
      // console.log(stepper[i].name);
    }
  } else {
    console.error("Invalid current step value");
  }

  const [clickedSteps, setClickedSteps] = useState<number[]>([]); // Track clicked steps

//   const handleClick = (index: number) => {
//     if (!clickedSteps.includes(index)) {
//       setClickedSteps((prev) => [...prev, index]); // Add step index to clickedSteps
//     }
//   };
  const handleClick = (index: number) => {
    setClickedSteps((prev) =>
      prev.includes(index)
        ? prev.filter((stepIndex) => stepIndex !== index) // Remove if already clicked
        : [...prev, index] // Add if not clicked
    );
  };
  return (
    <div className="stepper-sec">
      <ul>
        {stepper.map((step, index) => {
          let className = "";
          if (index < currentStep) {
            className = "done";
          } else if (index === currentStep) {
            className = "stepper-active";
            // Add inactiveclass if conditions are met
            if (currentStep < 4 && isAcceptedOrRejected) {
              className += " inactiveclass";
            }
          } else {
            className = "inactive";
          }

          // Add the 'clickactive' class if the step has been clicked
          if (clickedSteps.includes(index)) {
            className += " expanded-view";
          }
          if (!clickedSteps.includes(index)) {
            className += " collapsed-view";
          }

          // Add custom class to the last step if conditions are met
          if (
            index === stepper.length - 1 &&
            currentStep < 4 &&
            isAcceptedOrRejected
          ) {
            className += " deal-closed"; // Append custom class
          }

          return (
            <li
              className={className}
              key={step.id}
              onClick={
                index === currentStep ? () => handleClick(index) : undefined
              } // Attach the click event handler
              style={{ cursor: index === currentStep ? "pointer" : "default" }}
            >
              <span className="">{step.name}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
