import { Grid, Skeleton, Tab } from "@mui/material";
import React from "react";
import { Methods } from "../../../../core/utility";
import { PieChart } from "../../../../components/charts";
import { Boy, Girl } from "@mui/icons-material";
import { LineChartDropDown } from "../../../../components/charts/line-chart";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CityList from "../city-list";
import { DonutChart } from "../../../../components/charts/donut-chart";

type Props = {
  influencerData: any;
  genderSplitPieChartData: any;
  columnChartData: any;
  genderArrayLabel: any;
  loading: boolean;
  handleChange: (event: React.SyntheticEvent, newValue: string) => void;
  value: string;
};

const methods = new Methods();

const Audience = ({
  influencerData,
  genderSplitPieChartData,
  columnChartData,
  genderArrayLabel,
  handleChange,
  value,
  loading,
}: Props) => {
  const totalMaleCount = influencerData?.detail?.[0]?.total_male_count ?? 0;
  const totalFemaleCount = influencerData?.detail?.[0]?.total_female_count ?? 0;
  const totalAdultCount =
    influencerData?.detail?.[0]?.total_adult_percentage ?? 0;

  const totalPopulation = totalMaleCount + totalFemaleCount;
  return (
    <>
      {(loading ||
        genderSplitPieChartData[0]?.y > 0 ||
        genderSplitPieChartData[1]?.y > 0) && (
        <strong className="influencer-profile__common__title audience">
          Audience
        </strong>
      )}
      {(loading ||
        genderSplitPieChartData[0]?.y > 0 ||
        genderSplitPieChartData[1]?.y > 0) && (
        <section className="influencer-profile__common w-bdr">
          <Grid container>
            <>
              {(loading ||
                genderSplitPieChartData[0]?.y > 0 ||
                genderSplitPieChartData[1]?.y > 0) && (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    className="influencer-profile__common__inner"
                  >
                    <div className="influencer-profile__common__box">
                      <div className="inner-heading">Gender</div>
                      <div
                        id="paichart"
                        data-highcharts-chart={1}
                        style={{ overflow: "hidden" }}
                        className="mx-auto sec-highchart"
                      >
                        {loading ? (
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{
                              height: "240px",
                              width: "240px",
                              margin: "0 auto",
                            }}
                          >
                            <Skeleton
                              variant="circular"
                              animation="wave"
                              width={240}
                              height={240}
                            />
                          </div>
                        ) : (
                          <PieChart
                            dataSource={genderSplitPieChartData}
                            showInLegend={false}
                            property="percentage"
                            size="80%"
                            title=""
                            height={240}
                            width={240}
                          />
                        )}
                      </div>

                      <div className="gender-value-sec gender">
                        <div className="gender-value female">
                          {loading ? (
                            <Skeleton width={100} />
                          ) : totalPopulation === 0 ? (
                            "NA"
                          ) : (
                            `${Math.round(
                              (totalFemaleCount / totalPopulation) * 100
                            )}% Female`
                          )}
                        </div>
                        <div className="gender-value male">
                          {loading ? (
                            <Skeleton width={100} />
                          ) : totalPopulation === 0 ? (
                            "NA"
                          ) : (
                            `${Math.round(
                              (totalMaleCount / totalPopulation) * 100
                            )}% Male`
                          )}
                        </div>
                        <div className="gender-value none">
                          {loading ? (
                            <Skeleton width={100} />
                          ) : (
                            `${totalAdultCount}% Adults`
                          )}
                        </div>
                      </div>
                    </div>
                  </Grid>
                  {(loading || influencerData?.age_gender?.length > 0) && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      className="influencer-profile__common__inner age-range"
                    >
                      <div className="influencer-profile__common__box">
                        <div className="inner-heading">Age range</div>
                        <div>
                          {loading ? (
                            <div
                              className="d-flex justify-content-center align-items-center"
                              style={{
                                height: "240px",
                                width: "240px",
                                margin: "0 auto",
                              }}
                            >
                              <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={440}
                                height={240}
                              />
                            </div>
                          ) : (
                            <LineChartDropDown
                              dataSource={
                                loading ? (
                                  <Skeleton width={300} height={300} />
                                ) : (
                                  columnChartData
                                )
                              }
                              categories={genderArrayLabel}
                              exporting={false}
                              legend={false}
                              chartType="column"
                              height="240px"
                            />
                          )}
                        </div>
                        <div className="gender-value-sec">
                          <div className="gender-value female"> Female</div>
                          <div className="gender-value male">Male</div>
                        </div>
                      </div>
                    </Grid>
                  )}
                  {(loading ||
                    influencerData?.city_indian?.length > 0 ||
                    influencerData?.city_international?.length > 0) && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      className="influencer-profile__common__inner extra__spacing"
                    >
                      <div className="influencer-profile__common__box ind-row-tab">
                        <div className="cities-tab-sec">
                          <div className="d-flex pb-2 pb-md-4">
                            <div className="me-auto inner-heading city-section me-auto">
                              Cities
                            </div>
                            <TabContext value={value}>
                              <TabList
                                className="common-tab mb-0"
                                onChange={handleChange}
                              >
                                <Tab
                                  className="common-tab-menu city_tab_1"
                                  label="India"
                                  value="1"
                                />
                                <Tab
                                  className="common-tab-menu city_tab_2"
                                  label="ROW"
                                  value="2"
                                />
                              </TabList>
                            </TabContext>
                          </div>

                          <TabContext value={value}>
                            <div className="cities-tab-content-sec">
                              <TabPanel value="1" sx={{ padding: 0 }}>
                                <CityList
                                  data={influencerData?.city_indian}
                                  loading={loading}
                                />
                              </TabPanel>
                              <TabPanel value="2" sx={{ padding: 0 }}>
                                <div className="cities-list">
                                  <CityList
                                    data={influencerData?.city_international}
                                    loading={loading}
                                  />
                                </div>
                              </TabPanel>
                            </div>
                          </TabContext>
                        </div>
                      </div>
                    </Grid>
                  )}
                  {(loading || influencerData?.city_country?.length > 0) && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      className="influencer-profile__common__inner extra__spacing"
                    >
                      <div className="influencer-profile__common__box">
                        <div
                          className="inner-heading"
                          style={{ minHeight: "67px" }}
                        >
                          Countries
                        </div>

                        <div className="main-content">
                          <div id="" className="examples">
                            <CityList
                              data={influencerData?.city_country}
                              loading={loading}
                            />
                          </div>
                        </div>
                      </div>
                    </Grid>
                  )}
                </>
              )}
            </>
          </Grid>
        </section>
      )}
    </>
  );
};

export default Audience;
