import React from "react";
import { useState, useEffect } from "react";
import { Loading } from "../../../redux/action";
import { connect } from "react-redux";
import { ChatService } from "../../../core/services";
import { InfluencerList } from "../../../components/common-inbox-chat/influencer-list";
import { ChatBox } from "../../../components/common-inbox-chat/chat-box";
import * as Util from "../../../core/utility";
import { useLocation, useNavigate } from "react-router-dom";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import { useMediaQuery } from "@mui/material";
import {
  SubscriptionService,
} from '../../../core/services';
import  UpgradeInfluencerPopUp from '../../../components/popup/upgrade-influencer-popup'
import * as gtUtil from "../../../core/utility";

interface Props {
  loading: (data: any) => any;
  userData: any;
}

const chatService: ChatService = new ChatService();
const methods: Util.Methods = new Util.Methods();
const subscriptionService: SubscriptionService = new SubscriptionService();

const InfluencerDirectMsg: React.FC<Props> = (props: Props) => {
  const [influencerList, setInfluencerList] = useState([]);
  const [selectedInfluencer, setSelectedInfluencer] = useState<any>(null);
  const [activeInfluencerChat, setActiveInfluencerChats] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = location.state || {};
  const [directUnreadMsgData, setDirectUnreadMsgData] = useState<any>([]);
  const [campaignUnreadMsgData, setCampaignUnreadMsgData] = useState<any>([]);
  const isMobile = useMediaQuery("(max-width: 700px)");
  const [isBasicUser, setBasicUser] = useState(false);
  const [openUpgradeInfluencerInboxModal, setopenUpgradeInfluencerInboxModal] = useState(false);
  const [chatLoading, setChatLoading] = useState(false);
  const [chatSendLoading, setChatSendLoading] = useState(false);

  useEffect(() => {
    getUnreadMessage();
  }, []);

  useEffect(() => {
    if (selectedInfluencer) {
      getActiveChat();
    }
  }, [selectedInfluencer]);

  useEffect(() => {
    getDirectInfluencerList();
  }, []);

  useEffect(() => {
    if (props.userData) {
      getLeftDays();
    }
  }, [props.userData]);
  
  async function getLeftDays() {
    const { account_id, user_id, influencer_id } = props.userData;
    try {
      props.loading(true);
      const response: any =
        await subscriptionService.getSubscription_Remaining_Days({
          account_id: account_id,
          user_id: user_id,
          influencer_id: influencer_id
        });
      if (response.status === gtUtil.Constants.SUCCESS) {
        setBasicUser(false);
      } else {
        setBasicUser(true);
        setopenUpgradeInfluencerInboxModal(!openUpgradeInfluencerInboxModal);
        // navigate("/influencer/dashboard")
      }
    } catch (error) {
      console.log('getLeftDays Error :: ' + JSON.stringify(error));
    }finally{
      props.loading(false);
    }
  }

  const clickOnInfluencer = (influencer: any) => {
    setSelectedInfluencer(influencer);
  };

  const getDirectInfluencerList = () => {
    chatService
      .getDirectInfluencerList({
        influencer_id: props.userData?.influencer_id ?? 0,
        influencer_type: "influencer",
      })
      .then((res) => {
        if (res.data.status === 1) {
          setInfluencerList(res.data.rows);
        } else {
          setInfluencerList([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching influencer list:", error);
        setInfluencerList([]);
      });
  };

  const getActiveChat = () => {
    setChatLoading(true);
    chatService
      .getDirectInfluencerConversion({
        proposal_id: 0,
        influencer_id: props.userData.influencer_id,
        receiver_influencer_id: selectedInfluencer.influencer_id,
      })
      .then((res) => {
        if (res.data.status) {
          // setActiveInfluencerChats(methods.alignChat(res.data.rows))
          setActiveInfluencerChats(methods.alignChat(res.data.rows));
          getUnreadMessage();
          setChatLoading(false);
        }
      })
      .catch((err) =>
        console.log(`active influencer chat error in useEffect :: ${err}`)
      ).finally(()=>{setChatLoading(false)})
  };

  function handleSubmitChat(event: any) {
    try {
      event.preventDefault();
      let target: any = event.target;
      let values = Object.fromEntries(new FormData(target)) as {
        message: string;
      };
      if (values.message.replace(/\s+/g, "").length > 0) {
        setChatSendLoading(true);
        chatService
          .insertProposalMessage({
            proposal_id: 0,
            comments: values.message,
            from: props.userData.brand_name,
            user_id: props.userData?.user_id ?? 0,
            account_id: props.userData?.account_id ?? 0,
            influencer_id: props.userData?.influencer_id ?? 0,
            sm_id: selectedInfluencer.sm_id ?? 0,
            // receiver_influencer_id: influencer?.influencer_id,
            receiver_influencer_id: selectedInfluencer.influencer_id,
            platform_type: selectedInfluencer.platform_type ?? 'None'
            // receiver_account_id: activeInfluencer?.account_id
          })
          .then((res) => {
            getActiveChat();
            event.target?.reset();
            setChatSendLoading(false);
          })
          .catch((error) => console.log(error))
          .finally(() => {setChatSendLoading(false);});
      }
    } catch (err) {
      throw new Error(`handleSubmitChat`);
    }
  }

  function getUnreadMessage() {
    chatService
      .getUnReadMessageChat({ influencer_id: props.userData.influencer_id })
      .then((res) => {
        if (res.status === 1) {
          setDirectUnreadMsgData(res.data.direct_unread);
          setCampaignUnreadMsgData(res.data.campaign_unread);
        } else {
          setDirectUnreadMsgData([]);
          setCampaignUnreadMsgData([]);
        }
      })
      .catch((error: any) =>
        console.log(`message count fetch proposal :: ${error}`)
      );
  }

  const onClickChatBackBtn = () => {
    setSelectedInfluencer(null);
  };

  const handleCloseInfluencerInboxModal = () =>{
    setopenUpgradeInfluencerInboxModal(!openUpgradeInfluencerInboxModal);
    navigate("/influencer/dashboard")
  }

  return (
    <section className="main-inbox-container">
      <h3 className="inbox-heading">Inbox</h3>
      <div className="btn-class-chat">
        <span
          className="btn"
          onClick={() => navigate("/influencer/campaign-msg")}
        >
          {" "}
          Campaign Messages
          <span className={`${campaignUnreadMsgData.length > 0 ? "unread-msg" : "" }`}>{campaignUnreadMsgData.length > 0 ? campaignUnreadMsgData[0].total_unread_messages : ''}</span>
        </span>
        <span
          className="btn active-chat-btn"
          onClick={() => navigate("/influencer/direct-msg")}
        >
          {" "}
          Direct Messages
          <span className={`${directUnreadMsgData.length > 0 ? "unread-msg" : "" }`}>{directUnreadMsgData.length > 0 ? directUnreadMsgData[0].total_unread_messages : ''}</span>
        </span>
      </div>
      <div className="main-chat-inbox-container">
        {isMobile ? (
          <div className="mobile-view">
            {!selectedInfluencer && (
              <InfluencerList
                data={influencerList}
                selected_influencer={selectedInfluencer}
                state={user}
                clickOnInfluencer={clickOnInfluencer}
                direct_tab={true}
                influencer_type={"influencer"}
                unreadMsg={directUnreadMsgData}
              />
            )}
            {selectedInfluencer && (
              <div className="chat-container">
                <ChatBox
                  campaign={null}
                  campaignDetail={null}
                  chats={activeInfluencerChat}
                  influencer={selectedInfluencer}
                  userData={props.userData}
                  onSubmit={handleSubmitChat}
                  direct_tab={true}
                  influencer_type={"influencer"}
                  isMobile={isMobile}
                  onClickChatBackBtn={onClickChatBackBtn}
                  chatLoading={chatLoading}
                  chatSendLoading={chatSendLoading}
                />
              </div>
            )}
          </div>
        ) : (
          <>
            <div className="list-container">
              <InfluencerList
                data={influencerList}
                selected_influencer={selectedInfluencer}
                state={user}
                clickOnInfluencer={clickOnInfluencer}
                direct_tab={true}
                influencer_type={"influencer"}
                unreadMsg={directUnreadMsgData}
              />
            </div>
            {selectedInfluencer && (
              <div className="chat-container">
                <ChatBox
                  campaign={null}
                  campaignDetail={null}
                  chats={activeInfluencerChat}
                  // onClick={() => handleBackButton()}
                  influencer={selectedInfluencer}
                  userData={props.userData}
                  onSubmit={handleSubmitChat}
                  direct_tab={true}
                  influencer_type={"influencer"}
                  chatLoading={chatLoading}
                  chatSendLoading={chatSendLoading}
                />
              </div>
            )}
            {!selectedInfluencer && (
              <div className="chat-container">
                <div className="inbox">
                  <div className="in-inbox">
                    <p className="icon">
                      <QuestionAnswerOutlinedIcon />
                    </p>
                    <p className="p-1">{`${
                      influencerList.length > 0
                        ? "Nothing Selected"
                        : "No messages yet"
                    }`}</p>
                    <p className="p-2">{`${
                      influencerList.length > 0
                        ? "Please select a conversation from the list to view its details."
                        : ""
                    }`}</p>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {openUpgradeInfluencerInboxModal && (
        <>
        <UpgradeInfluencerPopUp
          openUpgradeInfluencerModal={openUpgradeInfluencerInboxModal}
          onClose={() => handleCloseInfluencerInboxModal()}
          popupMinWidth='630px'
        >
        </UpgradeInfluencerPopUp>
        </>
      )}
    </section>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.UserReducer.UserData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(InfluencerDirectMsg);
export { connectToRedux as InfluencerDirectMsg };
