import React, { useState } from "react";
import { Button, Divider, Grid, TextField } from "@mui/material";
import { connect } from "react-redux";
import { FormValidation } from "../../../core/utility";
import { LocalService } from "../../../core/services";
import { PinSecurity } from "./pin";
import { UserService } from "../../../core/services";
import CommonPopUp from "../../../../src/components/popup/common-popup";
import * as gtUtil from "../../../core/utility";
import { AdminDashboardService } from "../../../core/services/admin-dashboard.service";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { FormLabel } from 'react-bootstrap';

const localService = new LocalService();
const adminDashboardService = new AdminDashboardService();
const userService = new UserService();
interface Props {
  UserData: any;
}
interface ErrorTypes {
  status: boolean;
  message: string;
}

const Security: React.FC<Props> = (props: Props) => {
  const formValidation = new FormValidation();
  const [currentPassord, setCurrentPassord] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [popup, set_popup] = useState({
    pop_deactivate : false,
    pop_delete : false
  })
  const [errorPassword, setErrorPassword] = useState<ErrorTypes>();
  const [confirmPasswordError, setConfirmPasswordError] = useState<ErrorTypes>({
    status: false,
    message: "",
  });
  const [formError, setFormError] = useState({
    remark: { status: true, message: "" },
    remarkDescription: { status: true, message: "" },
  });
  const [remark, setRemark] = useState("");
  const [otherRemark, setOtherRemark] = useState(false);
  const [remarkDescription, setRemarkDescription] = useState("");

  const handleRemarkDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = (event.target as HTMLInputElement).value;
    setRemarkDescription(selectedValue);
    const isValidRemarkDescription = formValidation.GetNameValidate(selectedValue).status;
    
    if (remark === "Other" && !isValidRemarkDescription) {
        setFormError((prev) => ({
            ...prev,
            remarkDescription: { status: false, message: "This Field is required" },
        }));
        // return;
    } else {
        setFormError((prev) => ({
            ...prev,
            remarkDescription: { status: true, message: "" },
        }));
    }
    // setFormData({ ...formData, reason: (event.target as HTMLInputElement).value });
    // validateForm();
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    // Check for empty fields
    if (!currentPassord || !newPassword || !confirmPassword) {
      localService.toastify("All fields are required", "error", 1000);
      return;
    }
    setErrorPassword(formValidation.GetPasswordValidate(newPassword));
    const isValidPassword = formValidation.GetPasswordValidate(
      `${newPassword}`
    ).status;
    if (isValidPassword) {
      if (
        newPassword === currentPassord ||
        confirmPassword === currentPassord
      ) {
        localService.toastify(
          "Current Password should not match with new password",
          "error",
          1000
        );
      } else if (newPassword != confirmPassword) {
        setConfirmPasswordError({
          status: true,
          message: "Passwords do not match",
        });
      } else if (
        newPassword !== currentPassord &&
        newPassword.length > 0 &&
        newPassword == confirmPassword
      ) {
        setConfirmPasswordError({
          status: false,
          message: "",
        });
        let user_id = props.UserData.user_id;
        const response = await userService.updatePassword({
          data: {
            old_password: currentPassord,
            new_password: newPassword,
            user_id: user_id,
          },
        });
        if (response.data.affectedRows > 0) {
          localService.toastify("Password Updated", "success", 1000);

          setCurrentPassord("");
          setNewPassword("");
          setConfirmPassword("");
        } else {
          localService.toastify(
            "Password update failed due to wrong password",
            "error",
            1000
          );
        }
      } else {
        localService.toastify("Password update failed", "error", 1000);
      }
    }
  };

  const onCloseDeactivate = (value: boolean) => {
    setRemark("");
    setOtherRemark(value);
    setRemarkDescription("");
    set_popup((prevState) => ({
      ...prevState,
      pop_deactivate: value
    }));
    
  };

  const onCloseDelete = (value: boolean) => {
    setRemark("");
    setOtherRemark(value);
    setRemarkDescription("");
    set_popup((prevState) => ({
      ...prevState,
      pop_delete: value
    }));
    
  };

  async function account_status(status: string) {
    try {
      const isValidRemark = remark.length > 0
      const isValidRemarkDescription = formValidation.GetNameValidate(remarkDescription).status;

      if (!isValidRemark) {
          localService.toastify("Please select a valid reason", "error", 1000);
          return;
      }
      
      if (remark === "Other" && !isValidRemarkDescription) {
          setFormError((prev) => ({
              ...prev,
              remarkDescription: { status: false, message: "This Field is required" },
          }));
          return;
      }
      const res = await userService.accountStatus({
        account_id: props.UserData.account_id,
        user_id: props.UserData.user_id,
        status: status,
        remark: remark,
        remark_description: remarkDescription
      });
      if (res.status === gtUtil.Constants.SUCCESS) {
        localService.logout();
      }
    } catch (error) {
      console.error("Error deactivateAccount:", error);
    } finally {
    }
  }

  const deleteUser = async (loginEmailMobile: string, accountType: string) => {
    let response;
    try {
      const isValidRemark = remark.length > 0
      const isValidRemarkDescription = formValidation.GetNameValidate(remarkDescription).status;

      if (!isValidRemark) {
          localService.toastify("Please select a valid reason", "error", 1000);
          return;
      }
      
      if (remark === "Other" && !isValidRemarkDescription) {
          setFormError((prev) => ({
              ...prev,
              remarkDescription: { status: false, message: "This Field is required" },
          }));
          return;
      }
      if (accountType === "Influencer") {
        response = await adminDashboardService.deleteInfluencer({
          login: loginEmailMobile,
          remark: remark,
          remark_description: remarkDescription
        });
        if (response.status === gtUtil.Constants.SUCCESS) {
          localService.logout();
        } 
      }
      if (accountType === "Brand") {
        response = await adminDashboardService.deleteBrand({
          login: loginEmailMobile,
          remark: remark,
          remark_description: remarkDescription
        });
        if (response.status === gtUtil.Constants.SUCCESS) {
          localService.logout();
        }
      }
      if (accountType === "Agency") {
        response = await adminDashboardService.deleteAgency({
          login: loginEmailMobile,
          remark: remark,
          remark_description: remarkDescription
        });
        if (response.status === gtUtil.Constants.SUCCESS) {
          localService.logout();
        }
      }
      if (accountType === "Talent Partner") {
        response = await adminDashboardService.deleteTalentPartner({
          login: loginEmailMobile,
          remark: remark,
          remark_description: remarkDescription
        });
        if (response.status === gtUtil.Constants.SUCCESS) {
          localService.logout();
        }
      }
    } catch (error) {
      console.log(`deleteUser error :: ${JSON.stringify(error)}`);
    }
  };

  const onSelectChange = async (data:any) => {
    try{
        setRemark(data);
        if(data == "Other"){
            setOtherRemark(true);
        }else{
            setOtherRemark(false);
            setRemarkDescription("");
        }
          
    } catch (error) {
          console.log(`onSelectChange error :: ${JSON.stringify(error)}`);
    }
  };

  return (
    <>
      <div className="list-section-content rounded">
        <div className="row align-items-center">
          <strong className="d-block">Security</strong>
        </div>
      </div>
      <Grid
        container
        sx={{
          flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" },
        }}
      >
        <Grid item xs>
          <span className="fs-16 d-block" style={{ marginBottom: "10px" }}>
            Password
          </span>

          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  label="Current Password"
                  className="input-common"
                  value={currentPassord}
                  onChange={(event) => setCurrentPassord(event.target.value)}
                  fullWidth
                  size="small"
                  type="password"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  type="password"
                  label="New Password"
                  className="input-common"
                  value={newPassword}
                  onChange={(event) => setNewPassword(event.target.value)}
                  fullWidth
                  size="small"
                />
                <span className="error">{errorPassword?.message}</span>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  type="password"
                  label="Confirm Password"
                  className="input-common"
                  value={confirmPassword}
                  onChange={(event) => setConfirmPassword(event.target.value)}
                  fullWidth
                  size="small"
                />
                {confirmPasswordError.status && (
                  <span className="error">{confirmPasswordError.message}</span>
                )}
              </Grid>
            </Grid>

            <Button
              type="submit"
              variant="contained"
              className="btn btn-outline-primary upload_button  "
              sx={{ mt: 2 }}
            >
              Update Password
            </Button>
          </form>
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          className="pin-pass-divider mx-2"
        >
          <span></span>
        </Divider>
        <Grid
          item
          xs
          sx={{
            marginTop: { xs: "40px", sm: "0", md: "0", lg: "0" },
          }}
        >
          <PinSecurity />
        </Grid>
      </Grid>
      <div className="list-section-content rounded mt-3">
        <div className="row align-items-center">
          <strong className="d-block">Account</strong>
        </div>
      </div>
      <Grid
        container
        sx={{
          flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" },
        }}
      >
        <Grid item xs>
          {/* <span className="fs-16 d-block" style={{ marginBottom: "10px" }}>
            Password
          </span> */}
          <span className="deactivate-text" style={{ marginRight: "10px" }}>
            Deactivate your account
          </span>
          <Button
            type="button"
            variant="contained"
            className="btn btn-outline-primary upload_button  "
            onClick={() => {
              set_popup((prevState) => ({
                ...prevState,
                pop_deactivate: true
              }));
            }}
          >
            Deactivate
          </Button>
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          className="pin-pass-divider mx-2"
        >
          <span></span>
        </Divider>
        <Grid
          item
          xs
          sx={{
            marginTop: { xs: "10px", sm: "0", md: "0", lg: "0" },
          }}
        >
          <span className="deactivate-text" style={{ marginRight: "10px" }}>
            Delete your account
          </span>
          <Button
            type="button"
            variant="contained"
            className="btn btn-outline-primary upload_button  "
            onClick={() => {
              set_popup((prevState) => ({
                ...prevState,
                pop_delete: true
              }));
            }}
          >
            Delete
          </Button>
        </Grid>
      </Grid>
      {popup.pop_deactivate && (
        <CommonPopUp
          modalTitle="Deactivate"
          modalContent=""
          openModal={popup.pop_deactivate}
          onClose={() => onCloseDeactivate(false)}
          className="modal-medium mx-auto"
        >
          <div className="deactivate-popup">
            <div className="">
              <FormControl fullWidth size="small" className="mt-2">
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  style={{ fontSize: "14px" }}
                >
                  Select Reason*
                </FormLabel>
                {formError.remark.message && (
                  <div style={{ color: "red", marginTop: "8px" }}>
                    {formError.remark.message}
                  </div>
                )}
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  id="gender_id"
                  value={remark}
                  onChange={(e) => {
                    onSelectChange(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="I’m taking a break from influencer marketing"
                    control={<Radio className="py-0" size="small" />}
                    label="I’m taking a break from influencer marketing."
                  />
                  <FormControlLabel
                    value="I’m not receiving enough collaboration opportunities"
                    control={<Radio className="py-0" size="small" />}
                    label="I’m not receiving enough collaboration opportunities."
                  />
                  <FormControlLabel
                    value="The platform is difficult to use"
                    control={<Radio className="py-0" size="small" />}
                    label="The platform is difficult to use."
                  />
                  <FormControlLabel
                    value="My goals have changed"
                    control={<Radio className="py-0" size="small" />}
                    label="My goals have changed."
                  />
                  <FormControlLabel
                    value="Other"
                    control={<Radio className="py-0" size="small" />}
                    label="Other"
                    onClick={() => setOtherRemark(true)}
                  />
                  {otherRemark && (
                    <div>
                      <TextField
                        className="w-100"
                        id="remarkDescription"
                        name="remarkDescription"
                        multiline
                        rows={4}
                        value={remarkDescription}
                        error={!formError.remarkDescription.status}
                        helperText={formError.remarkDescription.message}
                        onChange={handleRemarkDescription}
                        onBlur={() => {
                          setFormError({
                            ...formError,
                            remarkDescription:
                              formValidation.GetNameValidate(remarkDescription),
                          });
                        }}
                      />
                      {/* {formError.remarkDescription.message && <div style={{ color: 'red', marginTop: '8px' }}>{formError.remarkDescription.message}</div>} */}
                    </div>
                  )}
                </RadioGroup>
              </FormControl>
              <div className="popup-footer">
                <div className="d-flex justify-content-end">
                  <Button
                    className="btn btn-outline-primary btn-mui ms-2"
                    onClick={() => onCloseDeactivate(false)}
                    variant="contained"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn btn-primary btn-mui  ms-2"
                    onClick={() => {
                      account_status("Deactivate");
                    }}
                    variant="outlined"
                  >
                    Deactivate
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </CommonPopUp>
      )}

      {popup.pop_delete && (
        <CommonPopUp
          modalTitle="Delete"
          modalContent=""
          openModal={popup.pop_delete}
          onClose={() => onCloseDelete(false)}
          className="modal-medium mx-auto"
        >
           <div className="deactivate-popup">
            <div className="">
              <FormControl fullWidth size="small" className="mt-2">
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  style={{ fontSize: "14px" }}
                >
                  Select Reason*
                </FormLabel>
                {formError.remark.message && (
                  <div style={{ color: "red", marginTop: "8px" }}>
                    {formError.remark.message}
                  </div>
                )}
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  id="gender_id"
                  value={remark}
                  onChange={(e) => {
                    onSelectChange(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="I’m concerned about privacy and data security"
                    control={<Radio className="py-0" size="small" />}
                    label="I’m concerned about privacy and data security."
                  />
                  <FormControlLabel
                    value="I've moved to another platform"
                    control={<Radio className="py-0" size="small" />}
                    label="I've moved to another platform."
                  />
                  <FormControlLabel
                    value="I no longer want to use the platform"
                    control={<Radio className="py-0" size="small" />}
                    label="I no longer want to use the platform."
                  />
                  <FormControlLabel
                    value="The platform doesn't meet my expectations"
                    control={<Radio className="py-0" size="small" />}
                    label="The platform doesn't meet my expectations."
                  />
                  <FormControlLabel
                    value="Other"
                    control={<Radio className="py-0" size="small" />}
                    label="Other"
                    onClick={() => setOtherRemark(true)}
                  />
                  {otherRemark && (
                    <div>
                      <TextField
                        className="w-100"
                        id="remarkDescription"
                        name="remarkDescription"
                        multiline
                        rows={4}
                        value={remarkDescription}
                        error={!formError.remarkDescription.status}
                        helperText={formError.remarkDescription.message}
                        onChange={handleRemarkDescription}
                        onBlur={() => {
                          setFormError({
                            ...formError,
                            remarkDescription:
                              formValidation.GetNameValidate(remarkDescription),
                          });
                        }}
                      />
                      {/* {formError.remarkDescription.message && <div style={{ color: 'red', marginTop: '8px' }}>{formError.remarkDescription.message}</div>} */}
                    </div>
                  )}
                </RadioGroup>
              </FormControl>
              <div className="popup-footer">
                <div className="d-flex justify-content-end">
                  <Button
                    className="btn btn-outline-primary btn-mui ms-2"
                    onClick={() => onCloseDelete(false)}
                    variant="contained"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn btn-primary btn-mui  ms-2"
                    onClick={() => {
                      deleteUser(
                        props.UserData.login_mobile || props.UserData.mobile_whatsapp,
                        props.UserData.account_type
                      );
                    }}
                    variant="outlined"
                  >
                    Delete
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </CommonPopUp>
      )}
    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    UserData: state.UserReducer.UserData,
  };
};
const connectToRedux = connect(mapStateToProps, null)(Security);
export { connectToRedux as Security };
