import { Grid, IconButton, Skeleton, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { DummyCampaignImage } from '../../../config/config';
import IconImpression from '../../../components/icons/icon-impression';
import MaterialSymbol from '../../../components/icons/material-symbol';
import { Methods } from '../../../core/utility';
import Slider from 'react-slick';

type Story = {
  media_id: string;
  gcp_media_url: string;
  gcp_story_url: string;
  impressions: number;
  reach: number;
  username: string;

};

type Props = {
  loading?: boolean;
  influencerData: {
    top_stories: Story[];
  };
};


const methods = new Methods()

const InsightMediaDetailCard = (props: Props) => {
  const influencerData = props.influencerData;
    const settings = {
    dots: false, // Show dots for navigation
    infinite: false,
    speed: 500,
    slidesToShow: 4.25, // Show 4 posts at a time
    slidesToScroll: 1,
    swipeToSlide: true,
    centerMode: false,
    variableWidth: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          // centerPadding: '30px',
          infinite: false,
          initialSlide: 0.75,
          slidesToScroll: 1,
          slidesToShow: 1.25,
    variableWidth: true,
          arrows: true,
        
        }
      }
    ]
  };
  return (
    
    <Slider {...settings} className='custom-slick-slider'>
  {props.loading
    ? // Render Skeletons when loading
      Array.from({ length: 5 }).map((_, index) => (
        <Grid key={index} item className='posts-story-grid'>
          <div className="card-posts card-posts-stories">
            <span className="card-posts-image embed-responsive embed-responsive-1by1">
              <Skeleton width={112} variant="circular" height={112} />
            </span>
          </div>
        </Grid>
      ))
    : // Render actual data when not loading
      influencerData?.top_stories?.length > 0 &&
      influencerData?.top_stories?.map((story: Story) => (
        <Grid key={story?.media_id} item className='posts-story-grid'>
          <div className="card-posts card-posts-stories">
            <span className="card-posts-image embed-responsive embed-responsive-1by1">
              <img
                className="embed-responsive-item"
                src={
                  !story?.gcp_story_url
                    ? DummyCampaignImage.default_campaign_image
                    : encodeURI(story?.gcp_story_url)
                }
                width="100%"
                alt={story.username}
              />
            </span>
            {(story?.impressions > 0 || story?.reach > 0) && (
              <div className="card-posts-info">
                <ul>
                  {story?.impressions > 0 && (
                    <li>
                      <Tooltip title="Impressions" arrow placement='top'>
                        <IconButton className='p-0' size='small'>
                          <MaterialSymbol iconName='mystery' fontweight='200' className='text-white' />
                        </IconButton>
                      </Tooltip>
                      <span>{story?.impressions !== 0 && methods.convertNumber(story?.impressions)}</span>
                    </li>
                  )}
                  {story?.reach > 0 && (
                    <li>
                      <Tooltip title="Reach" arrow placement='top'>
                        <IconButton className='p-0' size='small'>
                          <IconImpression />
                        </IconButton>
                      </Tooltip>
                      <span>{story?.reach !== 0 && methods.convertNumber(story?.reach)}</span>
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
        </Grid>
      ))}
</Slider>


  );
};

export default InsightMediaDetailCard;
