import React from 'react';
import { Loading } from '../../../redux/action';
import { connect } from 'react-redux';
import { Methods } from '../../../core/utility/methods';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Launch from '@mui/icons-material/Launch';
import InstagramIcon1 from '../../../components/icons/instagram-icon1';

const methods = new Methods();

interface Props {
  UserReducer: any;
  loading: (data: any) => void;
  profileData: any;
}
const BrandPulseProfileCard: React.FC<Props> = (props: Props) => {
  
  return (
    <>
      <div className="" style={{ fontFamily: 'Open Sans',display:'flex',flexDirection:'column',width:'100%'}}>
        <div className="profile-header" style={{backgroundColor:'#f5fbff',borderRadius:'10px',boxShadow:'0 4px 6px rgba(0, 0, 0, 0.1)'}}>
          <div className="profile-logo">
            <img
              src={props.profileData[0].gcp_profile_image_url}
              alt={props.profileData[0].insta_name}
            />
          </div>
          <div className="profile-info">
            
              <p
                className="profile-info-insta-name"
              >
                {props.profileData[0].insta_name}
                <a
                  href={`https://www.instagram.com/${props.profileData[0].username}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginLeft: '8px' }}
                >
              <Launch fontSize="small" sx={{ height: 'auto' }} />
              </a>
              </p>

              <div
                className="profile-name"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '2px',
                  marginBottom: '8px',
                }}
              >
                <InstagramIcon1 width={25} />                 <p
                  style={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: '#83A5B0',
                    margin: 0,
                  }}
                >
                  {props.profileData[0].username}
                </p>
              
            
              </div>
                <span className='profile-info-industry-name'>
                  {props.profileData[0].industry_name}
                </span>
            {/* profile stats */}
          <div className="profile-stats">
              <div
                className="stat-item"
                style={{
                 
                }}
              >
                <strong>
                  {methods.formatNumber(props.profileData[0].followers)}
                </strong>
                <p style={{ color: '#3B5763', fontSize: '14px', margin: 0 }}>Followers</p>
              </div>

              <div
                className="stat-item"
                
              >
                <p
                  style={{
                    fontSize: '26.87px',
                    fontWeight: '800',
                    lineHeight: '36.59px',
                    margin: 0,
                     color:'#3B5763'
                  }}
                >
                  {props.profileData[0].media_count}
                </p>
                <p style={{ color: '#3B5763', fontSize: '14px', margin: 0 }}>Posts</p>
              </div>

              <div
                className="stat-item"
                
              >
                <p
                  style={{
                    fontSize: '26.87px',
                    fontWeight: '800',
                    lineHeight: '36.59px',
                    margin: 0,
                     color:'#3B5763'
                  }}
                >
                  {props.profileData[0].image_count}
                </p>
                <p style={{ color: '#3B5763', fontSize: '14px', margin: 0 }}>Images (90 days)</p>
              </div>

              <div
                className="stat-item"
                
              >
                <p
                  style={{
                    fontSize: '26.87px',
                    fontWeight: '800',
                    lineHeight: '36.59px',
                    margin: 0,
                     color:'#3B5763'
                  }}
                >
                  {props.profileData[0].video_count}
                </p>
                <p style={{ color: '#3B5763', fontSize: '14px', margin: 0 }}>Videos (90 days)</p>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(mapStateToProps, mapDispatchToProps)(BrandPulseProfileCard);

export { connectToRedux as BrandPulseProfileCard };
