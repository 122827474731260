import {
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Switch,
    TextField,
    Select,
    MenuItem,
} from '@mui/material';
import {
    useLocation,
    NavLink,
  } from "react-router-dom";
  
import React, { useEffect, useState} from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { FormValidation } from '../../core/utility/formValidation';
// import { WhatsApp } from '@mui/icons-material';
import { LoginService, LocalService, UserService } from '../../core/services';
import { connect } from 'react-redux';
import {
    Loading,
    UserLogin,
    LoginUserId,
    SwitchLayer1,
} from '../../redux/action';
import { Link, useNavigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
// import LockOpenIcon from '@mui/icons-material/LockOpen';
import useBodyClass from '../../hooks/useBodyClass';
import HeadingStyle from '../../components/heading-style/heading-style';
import './login.scss';
import Footer from '../../components/footer/footer';
import CommonPopUp from "../../../src/components/popup/common-popup";
import {MuiOtpInput} from 'mui-one-time-password-input';
import { Button } from "@mui/material";
import * as gtUtil from "../../core/utility";
const formValidation = new FormValidation();
const localService = new LocalService();
const userService = new UserService();

interface Props {
    IsUserLogged: (data: any) => void;
    switchLayer1: (data: any) => void;
    userID: (data: any) => void;
    loading: (data: any) => void;
}

interface ErrorTypes {
  status: boolean;
  message: string;
}

const initializeShowButtonsState = {
    showPinOption: false,
    showPinBox: false,
};
const initializeDisableState = {
    login_id: false,
};

const Login: React.FC<Props> = (props: Props) => {
    const [login, setLogin] = useState({ logIn: '', password: '', pin: '' });
    const [remember, setRemember] = useState(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [disable] = useState(initializeDisableState);
    const [show, setShow] = useState(initializeShowButtonsState);
    const [loading, setLoading] = useState(false);
    // const dispatch = useDispatch<any>();
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [otpValue, setOtpValue] = React.useState<string>('')
    const [userType, setUserType] = useState('');
    const [campaignId,setCampaignId]=useState<string>('');
    const [isFormValid, setIsFormValid] = useState(false)
    const [popup, set_popup] = useState({
        pop_deactivate : false
    })
    const [login_data, set_login_data] = useState<any>({});
    const location = useLocation();

    const navigate = useNavigate();
    // const [checked, setChecked] = useState(initializeChecked);

    //validation
    const [handleSendError, setHandleSendError] = useState({
        message: 'Please enter valid Email/Mobile.',
        status: false,
    });
    
    let inputField = document.getElementById('id_password');
    inputField?.addEventListener('keypress', (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            document.getElementById('id_login_btn')?.click();
        }
    });

    useEffect(() => {
        if (localService.getStorageItem('DeviceId')) {
            setLogin({ ...login, ...JSON.parse(`${localService.getStorageItem('DeviceId')}`) });
            setRemember(true);
        } else if (localService.getStorageItem('userreducer') !== null){
            navigate('/switch/account');
        }
    }, []);

    useEffect(() => {
        if (localService.getStorageItem('DeviceId') && remember) {
            handleLogin(login.logIn, login.password, login.pin);
        }
    }, [remember]);

    useEffect(() => {
        if (localService.getStorageItem('login') && localService.getStorageItem('password')) {
            const _Login = localService.getStorageItem('login') || '';
            const _Pass = localService.getStorageItem('password') || '';
            handleLogin(_Login, _Pass, '');
        }
        let campaign_id = sessionStorage.getItem("campaignId") || ""
        setCampaignId(campaign_id);
    }, [])



    //Login Button Actions
    const handleLogin = (login: string, password: string, pin: string) => {
        try {
            // Clear previous sessions
            sessionStorage.clear();
            localStorage.clear();

            // Validation logic
            if (!login.trim()) {
                localService.toastify('Email or Mobile Number is required', 'error', 1000);
                return;
            }
            
            const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(login); // Basic email validation
            const isMobile = /^\d{10}$/.test(login); // Basic mobile number validation (10 digits)
            
            if (!isEmail && !isMobile) {
                localService.toastify('Invalid Email or Mobile Number format', 'error', 1000);
                return;
            }

            if (!password.trim() && !pin.trim()) {
                localService.toastify('Password or PIN is required', 'error', 1000);
                return;
            }

            if (password && password.length < 8) {
                localService.toastify('Password must be at least 8 characters long', 'error', 1000);
                return;
            }

            if (pin && (pin.length !== 4 || isNaN(Number(pin)))) {
                localService.toastify('PIN must be a 4-digit number', 'error', 1000);
                return;
            }

            // All validations passed
            setLoading(true);

            // Call the login service
            LoginService.login({
                logIn: login.replace(/"/g, ''),
                password: password.replace(/"/g, ''),
                pin: pin.replace(/"/g, ''),
            })
                .then((res: any) => {
                    if (String(res.status) === '1') {
                        if(res.data.rows[0].status === "Deactivate"){
                            set_login_data(res.data.rows[0]);
                            set_popup((prevState) => ({
                                ...prevState,
                                pop_deactivate: true
                            }));
                        }else{
                        props.IsUserLogged(String(res.status) === '1');
                        props.switchLayer1(res?.data?.rows);
                        props.loading(true);
                        sessionStorage.setItem('campaignId', campaignId);
                        navigate('/switch/account');
                        localService.toastify(
                            res.info,
                            'success',
                            1000
                        );

                        // Save Remember Me
                        handleremember();}
                    } else {
                        localService.toastify(
                            res.info,
                            'info',
                            1000
                        );
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    throw new Error('handleLogin :: Failed :: ' + error);
                });
        } catch (error) {
            throw new Error(`handleLogin ::: ${error}`);
        }
    };
    


    const handleremember = () => {
        if (remember) {
            // localService.setStorageItem('remember', 'true');
            localService.setStorageItem('DeviceId', JSON.stringify(login));
        }

    };
    // const handleInput = (e: any) => {
    //     const {
    //         target: { value },
    //     } = e;
    //     setLogin({ ...login, logIn: value });
    //     if (
    //         formValidation.GetEmailControlIsValid(value).status ||
    //         formValidation.GetMobileControlIsValid(value).status
    //     ) {
    //         setHandleSendError({ message: '', status: true });
    //     } else {
    //         setHandleSendError({
    //             message: 'Please enter valid Email/Mobile.',
    //             status: false,
    //         });
    //     }
    // };

// Updated handleInput to validate the login field
const handleInput = (e: any) => {
    try {
        const {
            target: { value },
        } = e;

        if (/^\d/.test(value)) {
            const numericValue = value.replace(/\D/g, '');
            setLogin((prev) => ({ ...prev, logIn: numericValue }));

            if (
                numericValue.length === 10 &&
                formValidation.GetMobileControlIsValid(numericValue).status
            ) {
                setHandleSendError({ message: '', status: true });
            } else {
                setHandleSendError({
                    message: 'Please enter a valid phone number.',
                    status: false,
                });
            }
        } else {
            setLogin((prev) => ({ ...prev, logIn: value }));
            const emailValidation = formValidation.GetEmailControlIsValid(value);
            if (emailValidation.status) {
                setHandleSendError({ message: '', status: true });
            } else {
                setHandleSendError({
                    message: 'Please enter a valid email address.',
                    status: false,
                });
            }
        }

        // Validate the form on each input change
        validateForm();
    } catch (error) {
        throw new Error(`handleInput error :: ${error}`);
    }
};

const validateForm = () => {
    const isEmailOrPhoneValid = handleSendError.status;
    const isPasswordValid = login.password.length >= 8; // Example: Password must be at least 8 characters
    setIsFormValid(isEmailOrPhoneValid && isPasswordValid);
};

  const [errorPassword, setErrorPassword] = useState<ErrorTypes>();
  const [isLoginDisabled, setIsLoginDisabled] = useState<boolean>(true);
const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLogin((prev) => ({ ...prev, password: value }));

    // Password validation
    if (value.length < 8) {
        setErrorPassword({
            status: false,
            message: "Password should be minimum 8 characters."
        });
    } else {
        setErrorPassword({
            status: true,
            message: ""
        });
    }

    // Update button state
    validateLoginForm(login.logIn, value);
};

const validateLoginForm = (emailOrPhone: string, password: string) => {
    const isEmailOrPhoneValid =
        formValidation.GetEmailControlIsValid(emailOrPhone).status ||
        formValidation.GetMobileControlIsValid(emailOrPhone).status;
    const isPasswordValid = password.length >= 6;

    setIsLoginDisabled(!(isEmailOrPhoneValid && isPasswordValid));
};

// Attach this function to the input fields
useEffect(() => {
    validateLoginForm(login.logIn, login.password);
}, [login.logIn, login.password]);


    //Send Otp
    const sendOtpButton = () => {
        if (handleSendError.status) {
            LoginService.userValidationOTP({ logIn: login.logIn }).then(
                (res) => {
                    if (res?.data?.rows?.status) {
                        localService.toastify(
                            res?.data?.rows?.message,
                            'success',
                            1000
                        );
                        setShow({
                            ...show,
                            showPinBox: res?.data?.rows?.status,
                        });
                    } else {
                        localService.toastify(
                            res?.data?.rows?.message,
                            'error',
                            1000
                        );
                    }
                }
            );
        } else {
            localService.toastify(handleSendError.message, 'error', 1000);
        }
    };

    const handleOtpButton = (e: any, c: any) => {
        setShow({ ...show, showPinOption: c });
        if (!c) {
            setLogin({ ...login, pin: '' });
        }
        setShow({ ...show, showPinBox: c, showPinOption: c });
    };

    const sendPinPassword = (login: any) => {
        setIsDisabled(true);

        if (login.length > 0) {
            userService.forgetPasswordPin({ login: login }).then((res) => {
                if (res?.data?.rows?.status) {
                    localService.toastify(
                        res?.data?.rows?.message,
                        'success',
                        1000
                    );

                    setTimeout(() => {
                        setIsDisabled(false);
                    }, 2 * 60 * 1000);
                } else {
                    localService.toastify(
                        res?.data?.rows?.message,
                        'error',
                        1000
                    );
                }
            });
        } else {
            setIsDisabled(false);

            localService.toastify(
                'Valid Email or  Valid Mobile Number is required',
                'error',
                1000
            );
        }
    };

    //Login with Pin
    const handlePinField = (pin: any) => {
        login.pin = pin;
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };
    // For Body Class Name
    useBodyClass('common-signup-page');
    useBodyClass('login-page');
    
    const handleChange = (newValue: string) => {
        const filteredValue = newValue.replace(/\D/g, '');
        setOtpValue(filteredValue)
    }
    // Function to check if a value is numeric
    const matchIsNumeric = (text: any) => {
        return !isNaN(Number(text));
    };

    // Validation function for MuiOtpInput
    const validateChar = (value: string) => {
        // return matchIsNumeric(value);
        return /^\d*$/.test(value);
    };

    async function account_status(status:string) {
        try {
          const res = await userService.accountStatus(
            {
              account_id : login_data.account_id,
              user_id : login_data.user_id,
              status : status
            })
            if(res.status === gtUtil.Constants.SUCCESS){
                handleLogin(login.logIn, login.password, login.pin);   
            }
        } catch (error) {
          console.error('Error deactivateAccount:', error);
        } finally {
        }
      }

      const onClose = (value: boolean) => {
        set_popup((prevState) => ({
            ...prevState,
            pop_deactivate: value
        }));
      }
     
  const [isTouched, setIsTouched] = useState<Boolean>(true)
  
    return (
        <>
        <div className='__login_container common-bg'>
            <div className='row __login_row'>
                <div className='col-12 col-sm-8 col-lg-4 __login_box'>
                    <HeadingStyle title='Login' className=' mb-4' />
                    <div className='position-relative'>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-1 mb-3">
                            <FormControl fullWidth size="small" className="input-style input-common">
                                <InputLabel id="demo-simple-select-label">
                                    I am
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="i_am_id"
                                    value={userType}
                                    label="I am"
                                    name="I am"
                                    onChange={(e) => setUserType(e.target.value as string)}
                                    // MenuProps={MenuPropsSelect}
                                >
                                    <MenuItem value="Influencer">Influencer</MenuItem>
                                    <MenuItem value="Brand">Brand</MenuItem>
                                    <MenuItem value="Talent Partner">Talent Partner</MenuItem>
                                    <MenuItem value="Agency">Agency</MenuItem>
                                </Select>
                            </FormControl>
                    </div>
        
                        <TextField
                            error={isTouched && !handleSendError?.status} // Error state for the field
                            helperText={isTouched && !handleSendError?.status ? handleSendError.message : ''} 
                            className='w-100 mb-3 input-common'
                            onChange={handleInput}
                             onBlur={(e) => {
                                setIsTouched(true); // Mark the field as touched
                                handleInput(e); // Trigger validation on blur
                            }}
                            onFocus={() => setIsTouched(false)} 
                            size='small'
                            id='login_id'
                            disabled={disable.login_id}
                            autoFocus
                            label='Email/Mobile'
                            variant='outlined'
                            value={login.logIn}
                             onKeyDown={(e: any) => formValidation.GetHandleKeyDown(e, (value: string) => setLogin((prev) => ({ ...prev, logIn: value })), login.logIn, 'emailOrNumber')}
                            onPaste={(e:any) => formValidation.GethandlePaste(e, (value: string) => setLogin((prev) => ({ ...prev, logIn: value })),"emailOrNumber", (trimmedData) => handleInput({ target: { value: trimmedData } }))}
                        />
                        <FormControl variant="outlined" className="w-100 input-common" size="small">
                            <InputLabel htmlFor="id_password">Password</InputLabel>
                            <OutlinedInput
                                id="id_password"
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                value={login.password}
                                onChange={handlePasswordChange}
                                error={!!errorPassword?.message}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                            />
                            <span className="error">{errorPassword?.message}</span>
                        </FormControl>
                        <div className="col d-flex justify-content-end pt-1">
                            <label className='small-size text-right text-black-link'>

                                <span
                                    onClick={() => sendPinPassword(login.logIn)}
                                    className={
                                        isDisabled ? 'disabled-link' : 'show-cursor'
                                    }
                                >
                                    Forgot Password?
                                </span>
                            </label>
                        </div>
                    </div>
                    <Divider className="w-100 text-center py-3 fs-14">or PIN</Divider>

                    <span className='__login_auth_span d-none'>
                        {' '}
                        Login via PIN
                        <Switch
                            style={{ color: `white` }}
                            onChange={handleOtpButton}
                            inputProps={{ 'aria-label': 'Switch demo' }}
                        />
                    </span>
                    {/* <OtpField handleOtp={handlePinField} /> */}
                    <MuiOtpInput
                        value={otpValue}
                        onChange={handleChange}
                        onComplete={handlePinField}
                        length={4}
                        
                        TextFieldsProps={{ size: 'small', placeholder: '0', type:"password"  }}
                        validateChar={validateChar} 
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleLogin(login.logIn, login.password, login.pin);
                            }
                        }}
                        />


                    <div className="col d-flex justify-content-end pt-1">
                        <label className='small-size text-right text-black-link'>
                            <span
                                onClick={() => sendPinPassword(login.logIn)}
                                className={isDisabled ? 'disabled-link' : 'show-cursor'
                                }
                            >
                                Forgot PIN?
                            </span>
                        </label>
                    </div>
                    <div className='login_remember'>
                        <label className='user-select-none'>
                            <FormControlLabel
                                className='custom-checkbox m-0'
                                control={<Checkbox
                                    className=' p-0 m-0'
                                    inputProps={{ 'aria-label': 'Checkbox' }}
                                    onChange={(event, value) => setRemember(value)}
                                    name='terms'
                                    size='small'
                                />}
                                label=""
                            />
                            <span className='small-size ps-1'>Remember me</span>
                        </label>
                    </div>

                    <div className='login_buttons pb-2'>
                        <LoadingButton
                            id='id_login_btn'
                            loading={loading}
                            loadingPosition='start'
                            className='btn btn-primary w-100'
                            variant='contained'
                            onClick={() =>
                                handleLogin(
                                    login.logIn,
                                    login.password,
                                    login.pin
                                )
                            }
                        >
                            Login
                        </LoadingButton>
                    </div>
                    <div className='d-flex justify-content-center'>
                        <label className='small-size text-center'>
                            Not registered yet? &nbsp;
                            <Link to="/"
                                className={
                                    isDisabled ? 'disabled-link' : 'show-cursor'
                                }
                            >
                                Signup
                            </Link>
                        </label>
                    </div>

                </div>
            </div>
        </div>
        <Footer />
        {popup.pop_deactivate &&
            <CommonPopUp
            modalTitle="Activate"
            modalContent=""
            openModal={popup.pop_deactivate}
            onClose={() => onClose(false)}
            className="modal-medium mx-auto"
            >
                <div className="deactivate-popup">
                    <span>You have deactivated your account. Would you like to reactivate it?</span>

                    <div className="d-flex justify-content-end">
                    <Button
                        className="btn btn-outline-primary btn-mui ms-2"
                        onClick={() => onClose(false)}
                        variant="contained"
                    >
                        Cancel
                    </Button>
                    <Button
                        className="btn btn-primary btn-mui  ms-2"
                        onClick={() => {
                        account_status("Active");
                        }}
                        variant="outlined"
                    >
                        Active
                    </Button>
                    </div>
                </div>
            </CommonPopUp>
        }
        </>

    );
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        loading: (data: any) => dispatch(Loading(data)),
        switchLayer1: (data: any) => dispatch(SwitchLayer1(data)),
        IsUserLogged: (data: any) => dispatch(UserLogin(data)),
        userID: (data: any) => dispatch(LoginUserId(data)),
    };
};

const reduxConnect = connect(null, mapDispatchToProps)(Login);

export { reduxConnect as Login };
