import React, { useEffect, useState, useRef } from "react";
import generatePDF, { Resolution, Margin } from 'react-to-pdf';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { CurrencyRupee } from "@mui/icons-material";
import html2pdf from 'html2pdf.js';
import * as gtUtil from "../../../core/utility"

import {
  Button,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";
import { Loading } from "../../../redux/action";
import { InvoiceService, LocalService } from "../../../core/services";
import dayjs from "dayjs";
import { FormValidation, Methods, Numbers } from "../../../core/utility";
import "./invoice.scss";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import IconRejected from "../../../components/icons/icon-rejected";
import { LoadingButton } from "@mui/lab";
import { InvoiceTableComponent } from "./invoice-table";
import { DummyCampaignImage } from "../../../config/config";
import ConfirmPopup from "../../../components/confirm-popup/confirm-popup";
import { createPortal } from "react-dom";
import MaterialSymbol from "../../../components/icons/material-symbol";

const invoiceService: InvoiceService = new InvoiceService();
const numberFormat: Numbers = new Numbers();
const methods: Methods = new Methods();
const localService = new LocalService();
const formValidation = new FormValidation();

interface Props {
  UserReducer: any;
  loading: (data: boolean) => void;
}

const PrintInvoice: React.FC<Props> = ({ UserReducer }: Props) => {
  const navigate = useNavigate();
  const [deliverables, setDeliverables] = useState<any>(null);
  const [invoiceDetails, setInvoiceDetails] = useState<any>(null);
  const [calc, setCalc] = useState({
    subtotal: 0,
    igst: 0,
    sgst: 0,
    cgst: 0,
    total: 0,
  });
  const [editableField, setEditableField] = useState<any>({
    invoiceNo: "",
    invoiceDate: "",
  });
  const [editableFieldError, setEditableFieldError] = useState<any>({
    invoiceNo: { status: false, message: "" }
  })
  const [editInvoiceDate, setEditInvoiceDate] = useState(false)
  const [editInvoiceNumber, setEditInvoiceNumber] = useState(false)
  // const [enableDownload, setEnableDownload] = useState(false);
  const [enableSend, setEnableSend] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [selectedInvoice, setSelectedInvoice] = useState<File | null>(null);
  const targetRef = useRef<HTMLDivElement | null>(null);
  const [invoiceSent, setInvoiceSent] = useState(false);
  const [invoiceId, setInvoiceId] = useState('');

  const [editAllowed, setEditAllowed] = useState(false);
  const [sendLoading, setSendLoading] = useState(false); // For Send button
  const [downloadLoading, setDownloadLoading] = useState(false); // For Download button
  // const [sendWhatsAppInviteActive, setSendWhatsAppInviteActive] = useState(false)
  const [handleSendInvoiceButton, sethandleSendInvoiceButton] = useState(false);
  const handleSendInvoice = () => {
    sethandleSendInvoiceButton(true);
    document
      .getElementsByTagName('body')[0]
      .classList.add('custom-drawer-active');   
  }

  const handleSendInvoiceClose = () => {
    sethandleSendInvoiceButton(false);
    document
      .getElementsByTagName('body')[0]
      .classList.remove('custom-drawer-active');
  };

  useEffect(() => {
    // console.log("deliverables ==> ", deliverables);
    // console.log("invoiceDetails ==> ", invoiceDetails);
  }, [deliverables, invoiceDetails]);


  const getInvoice = async () => {
    let getParams = new URLSearchParams(window.location.search).get('id');
    setInvoiceId(getParams ?? '');
    try {
      if (getParams) {
        const response: any = await invoiceService.getGeneratedInvoice(
          getParams
        );
        if (response.status === gtUtil.Constants.SUCCESS) {
          setDeliverables(response.data.rows[0].deliverables);
          setInvoiceDetails(response.data.rows[0]);
          setEditableField({ invoiceNo: response.data.rows[0].invoice_number, invoiceDate: response.data.rows[0].invoice_dt })

          if (response.data.rows[0].invoice_sent === 'true') {
            setEditAllowed(false);
          } else {
            setEditAllowed(true);
          }
          //calc igst, cgst, sgst
          for (let data of response.data.rows[0].deliverables) {
            setCalc((prev) => {
              prev.cgst = prev.cgst + (data.price * 9) / 100;
              prev.sgst = prev.sgst + (data.price * 9) / 100;
              prev.igst = prev.igst + (data.price * 18) / 100;
              prev.subtotal = prev.subtotal + data.price;
              return { ...prev };
            });
          }
        }
      } else {
        handleNavigation();
      }
    } catch (error) {
      throw new Error(`Error getting invoice :: ${JSON.stringify(error)}`);
    }
  };

  useEffect(() => {
    setCalc({
      subtotal: 0,
      igst: 0,
      sgst: 0,
      cgst: 0,
      total: 0,
    })
    getInvoice();
  }, []);

  //if Some issue the navigate other route
  const handleNavigation = () => {
    const account_type = UserReducer.account_type
      .toString()
      .toLowerCase()
      .trim();
    let navLink = "";
    if (account_type === "influencer" || account_type === "talent partner") {
      navLink = "/influencer/dashboard";
    } else if (account_type === "brand" || account_type === "agency") {
      navLink = "/brand/dashboard";
    }
    navigate(navLink);
  };

  //Update the generated invoice

  async function updateGeneratedInvoice() {
    setLoading(true)
    try {
      const formData = new FormData();
      if (editableField.invoiceNo !== '') {
        let getParams = new URLSearchParams(window.location.search).get("id");

        if (!getParams) {
          throw new Error("Parameter 'id' not found in the URL.");
        }

        formData.append('id', getParams);
        formData.append('invoiceNo', editableField.invoiceNo);
        formData.append('invoiceDate', editableField.invoiceDate);

        const response: any = await invoiceService.updateInvoice(formData);
        const toastType = (response.status === gtUtil.Constants.SUCCESS) ? "success" : "error";
        localService.toastify(response.info, toastType, 1000);
        if (toastType === "success") {
          setCalc({
            subtotal: 0,
            igst: 0,
            sgst: 0,
            cgst: 0,
            total: 0,
          })
          getInvoice()
          setEditableField({
            invoiceNo: "",
            invoiceDate: ""
          });
          setEditInvoiceDate(false)
          setEditInvoiceNumber(false)
        }
      }
      setLoading(false)
    } catch (error) {
      throw new Error(`Error updating invoice :: ${JSON.stringify(error)}`);
    }
  }

  /*...............................Handle Invoice Edit Cancel & Other Icons................ */
  const handleInvoiceDate = () => {
    setEditInvoiceDate(true)
  }

  const handleInvoiceNumber = () => {
    setEditInvoiceNumber(true)
  }

  const handleCrossIcon = (type: any) => {
    if (type === 'date') {
      setEditInvoiceDate(false);
    } else if (type === 'number') {
      setEditInvoiceNumber(false);
    }
  };

  /*...............................Generate PDF................ */
  const options = {
    // default is `save`
    method: "build" as const,
    // default is Resolution.MEDIUM = 3, which should be enough, higher values
    // increases the image quality but also the size of the PDF, so be careful
    // using values higher than 10 when having multiple pages generated, it
    // might cause the page to crash or hang.
    resolution: Resolution.MEDIUM,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: 5,
      // default is 'A4'
      format: 'A4',
      // default is 'portrait'
      orientation: 'portrait' as const,
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: 'image/jpeg' as const,
      qualityRatio: 1
    },
    // Customize any value passed to the jsPDF instance and html2canvas
    // function. You probably will not need this and things can break, 
    // so use with caution.
    overrides: {
      // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
      pdf: {
        compress: true
      },
      // see https://html2canvas.hertzen.com/configuration for more options
      canvas: {
        useCORS: true
      }
    },
  };

  // const generateInvoicePdf = async (actionType: ('Send' | 'Download')) => {
  //   // setLoading(true);
  //   if (actionType === "Send") setSendLoading(true);
  //   if (actionType === "Download") setDownloadLoading(true);
  //   try {
  //     const elements = document.getElementById('invoice-main');
  //     // elements?.classList.add('download-invoice');

  //     const getTargetElement = () => elements;
  //     const pdfInstance = await generatePDF(getTargetElement, options);
  //     // console.log(pdfInstance)
  //     const blob = pdfInstance.output('blob');
  //     const generatedFile = new File([blob], 'invoice.pdf', { type: 'application/pdf' });


  //     if (actionType === "Send") {
  //       // setEnableSend(false);
  //       // console.log('called Genrate Invoice ==> ', enableSend)
  //       // setSelectedInvoice(generatedFile);
  //       await sendInvoice(generatedFile);
  //     }

  //     if (actionType === "Download") {
  //       window.open(URL.createObjectURL(blob), '_blank');
  //     }

  //     // elements?.classList.remove('download-invoice');

  //   } catch (error) {
  //     console.log('Error generating PDF:', error);
  //     throw new Error('Error generating PDF.');
  //   } finally {
  //     setLoading(false);
  //     if (actionType === "Send") setSendLoading(false);
  //     if (actionType === "Download") setDownloadLoading(false);

  //   }
  // }


  // const generateInvoicePdf = async (actionType: "Send" | "Download") => {
  //   if (actionType === "Send") setSendLoading(true);
  //   if (actionType === "Download") setDownloadLoading(true);
  //   try {
  //     const element:any = document.getElementById("invoice-main");
  //     const excludedSection = document.querySelector(".invoice-send");

  //     // Apply fixed width
  //     element?.classList.add("fixed-pdf-width");
  //     excludedSection?.classList.add("exclude-from-pdf");

  //     const canvas = await html2canvas(element, {
  //       scale: 2, // Scale for better quality
  //       useCORS: true,
  //     });

  //     const imgData = canvas.toDataURL("image/jpeg", 1.0);
  //     const pdf = new jsPDF("portrait", "px", "a4");

  //     const pdfWidth = pdf.internal.pageSize.width;
  //     const imgHeight = (canvas.height * pdfWidth) / canvas.width;

  //     let position = 0; // Initial position
  //     pdf.addImage(imgData, "JPEG", 0, position, pdfWidth, imgHeight);

  //     if (actionType === "Download") {
  //       pdf.save("invoice.pdf");
  //     } else if (actionType === "Send") {
  //       const blob = pdf.output("blob");
  //       const file = new File([blob], "invoice.pdf", { type: "application/pdf" });
  //       await sendInvoice(file);
  //     }
  //   } catch (error) {
  //     console.error("Error generating PDF:", error);
  //   } finally {
  //     // Remove the temporary classes
  //     const excludedSection = document.querySelector(".invoice-send");
  //     excludedSection?.classList.remove("exclude-from-pdf");
      
  //     const element:any = document.getElementById("invoice-main");
  //     element?.classList.remove("fixed-pdf-width");

  //     if (actionType === "Send") setSendLoading(false);
  //     if (actionType === "Download") setDownloadLoading(false);
  //   }
  // };

  const [isDownloading, setIsDownloading] = useState(false);

  // const generateInvoicePdf = async (action: 'Download' | 'Send') => {
  //   // Temporarily hide the "invoice-send" div
  //   document.body.classList.add('pdf'); 
  //   const invoiceSendDiv = document.querySelector('.invoice-send') as HTMLElement;
  //   const invoiceElement = document.getElementById('invoice-main') as HTMLElement;
    
  //   const invBankDetDiv = document.querySelector('.inv-bank-det') as HTMLElement;
    
  //   if (invoiceSendDiv) {
  //     invoiceSendDiv.style.display = 'none'; // Hide it
  //     invoiceElement.classList.add("asdfasd")
  //     invBankDetDiv.classList.add("break-avoid" ,"fullpage")
  //   }

  //   setIsDownloading(true);
  //   if (action === "Send") setSendLoading(true);
  //   if (action === "Download") setDownloadLoading(true);
  //   try {
  //     if (invoiceElement) {
  //       // Adding padding or margin directly via JavaScript
  //       // invoiceElement.style.padding = '30px';
  //     }

  //     // Generate the PDF using html2pdf
  //     const pdfBlob = await new Promise<Blob>((resolve, reject) => {
  //       html2pdf() .set({
  //         margin: [5, 5, 20, 5], // [top, left, bottom, right] in mm
  //         pagebreak: { mode: '', before: '.break-before', after: '.break-after', avoid: '.break-avoid' },
  //         jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' },
  //         html2canvas: {
  //           backgroundColor: "#fff",
  //           scale: 1.75,
  //           y: 0,
  //           x: 0,
  //           scrollY: 0,
  //           scrollX: 0,
  //           windowWidth: 800,
  //         },
  //         // jsPDF: { unit: 'px', format: 'a4', orientation: 'portrait', precision: 16 }
  //       })
  //         .from(document.getElementById('invoice-main'))
  //         .output('blob') // Use output('blob') to get a blob
  //         .then(resolve)
  //         .catch(reject);
  //     });

  //     // Handle 'Download' action
  //     if (action === 'Download') {
  //       html2pdf().from(document.getElementById('invoice-main')).save(`${invoiceDetails.invoice_number}.pdf`);
  //     }

  //     // Handle 'Send' action
  //     if (action === 'Send') {
  //       // Create a file from the blob
  //       const file = new File([pdfBlob], `${invoiceDetails.invoice_number}.pdf`, { type: 'application/pdf' });
  //       await sendInvoice(file); // Call sendInvoice function
  //     }
  //   } catch (error) {
  //     console.error('Error generating PDF:', error);
  //     alert('Failed to generate the PDF. Please try again.');
  //   } finally {
  //     // Restore visibility of the "invoice-send" div after PDF generation
  //     if (invoiceSendDiv) {
  //       invoiceSendDiv.style.display = 'block'; // Restore visibility
  //     }
      
  //     setIsDownloading(false);
  //     if (action === "Send") setSendLoading(false);
  //     if (action === "Download") setDownloadLoading(false);
  //     document.body.classList.remove('pdf');
  //   }
  // };


  // const generateInvoicePdf = async (actionType: "Send" | "Download") => {
  //   if (actionType === "Send") setSendLoading(true);
  //   if (actionType === "Download") setDownloadLoading(true);

  //   try {
  //     const element = document.getElementById("invoice-main");
  //     if (!element) throw new Error("Invoice element not found!");

  //     // Temporarily add a class to ensure fixed width
  //     element.classList.add("pdf-fixed-width");

  //     // Generate the canvas using html2canvas
  //     const canvas = await html2canvas(element, {
  //       scale: 2, // High resolution
  //       useCORS: true, // For cross-origin images
  //     });

  //     // Generate the PDF
  //     const pdf = new jsPDF("portrait", "px", "a4");
  //     const a4WidthPx = 450; // Fixed A4 width in pixels
  //     const imageData = canvas.toDataURL("image/jpeg", 1.0);

  //     // Add the image to the PDF
  //     pdf.addImage(imageData, "JPEG", 0, 0, a4WidthPx, (a4WidthPx * canvas.height) / canvas.width);

  //     // Download or send the PDF
  //     if (actionType === "Download") {
  //       pdf.save("invoice.pdf");
  //     } else if (actionType === "Send") {
  //       const blob = pdf.output("blob");
  //       const file = new File([blob], "invoice.pdf", { type: "application/pdf" });
  //       await sendInvoice(file);
  //     }
  //   } catch (error) {
  //     console.error("Error generating PDF:", error);
  //     alert("Failed to generate the PDF. Please try again.");
  //   } finally {
  //     // Remove the class to revert the original styling
  //     const element = document.getElementById("invoice-main");
  //     element?.classList.remove("pdf-fixed-width");

  //     if (actionType === "Send") setSendLoading(false);
  //     if (actionType === "Download") setDownloadLoading(false);
  //   }
  // };



  // useEffect(() => {
  //   if (enableDownload) {
  //     generateInvoicePdf();
  //   }

  //   console.log('enable download ==> ', enableDownload)
  // }, [enableDownload])



  // useEffect(() => {
  //   if (enableSend) {
  //     generateInvoicePdf();
  //   }

  //   console.log('enable send  ==> ', enableSend)
  // }, [enableSend])


  //  /*...............................Send Invoice to Brand......................*/

  // const sendInvoice = async (invoiceFile: File) => {
  //   const formData = new FormData();
  //   // setLoading(true);
  //   setSendLoading(true)

  //   // console.log('process send Invoice ==> ', enableSend)
  //   try {

  //     if (invoiceFile) {
  //       // const email = UserReducer.email_primary;
  //       // let getParams = new URLSearchParams(window.location.search).get('id');
  //       // formData.append('email', email);
  //       // Invoice data
  //       formData.append('invoice_id', invoiceId);
  //       formData.append('file', invoiceFile);
  //       formData.append('path', `accounts/${UserReducer.account_id}/campaign_invoices`)
  //       // User + proposal data
  //       formData.append('user_id', UserReducer.user_id);
  //       formData.append('account_id', UserReducer.account_id);
  //       formData.append('influencer_id', UserReducer.influencer_id);
  //       formData.append('sm_id', invoiceDetails.sm_id);
  //       formData.append('campaign_id', invoiceDetails.campaign_id);
  //       formData.append('proposal_id', invoiceDetails.proposal_id);
  //       formData.append('total_amount', invoiceDetails.total_amount);

  //       const response: any = await invoiceService.sendInvoice(formData);
  //       const toastType = (response.status === gtUtil.Constants.SUCCESS) ? "success" : "error";
  //       // setInvoiceSent(false)
  //       localService.toastify(response.info, toastType, 1000);
  //       setCalc({
  //         subtotal: 0,
  //         igst: 0,
  //         sgst: 0,
  //         cgst: 0,
  //         total: 0,
  //       })
  //       getInvoice();
  //     } else {
  //       console.error("No invoice to send");
  //     }
  //   } catch (error) {
  //     console.log('Error Sending Invoice:', error);
  //     throw new Error('Error Sending Invoice.');
  //   }
  //   finally {
  //     setEnableSend(false)
  //     // setLoading(false);
  //     setSendLoading(false)
  //   }
  // };

  const sendInvoice = async () => {
    // const formData = new FormData();
    // setLoading(true);
    setSendLoading(true)
    // console.log('process send Invoice ==> ', enableSend)
    try {
      if (![null, undefined, ''].includes(invoiceDetails.file_url)) {
        // const email = UserReducer.email_primary;
        // let getParams = new URLSearchParams(window.location.search).get('id');
        // formData.append('email', email);
        // Invoice data
        // formData.append('invoice_id', invoiceId);
        // formData.append('file', invoiceFile);
        // formData.append('path', `accounts/${UserReducer.account_id}/campaign_invoices`)
        // // User + proposal data
        // formData.append('user_id', UserReducer.user_id);
        // formData.append('account_id', UserReducer.account_id);
        // formData.append('influencer_id', UserReducer.influencer_id);
        // formData.append('sm_id', invoiceDetails.sm_id);
        // formData.append('campaign_id', invoiceDetails.campaign_id);
        // formData.append('proposal_id', invoiceDetails.proposal_id);
        // formData.append('total_amount', invoiceDetails.total_amount);

        const response: any = await invoiceService.sendInvoice({invoice_id: invoiceDetails.id, campaign_id: invoiceDetails.campaign_id, influencer_id: invoiceDetails.influencer_id});
        const toastType = (response.status === gtUtil.Constants.SUCCESS) ? "success" : "error";
        // setInvoiceSent(false)
        localService.toastify(response.info, toastType, 1000);
        setCalc({
          subtotal: 0,
          igst: 0,
          sgst: 0,
          cgst: 0,
          total: 0,
        })
        getInvoice();
      } else {
        console.error("No invoice to send");
      }
    } catch (error) {
      console.log('Error Sending Invoice:', error);
      throw new Error('Error Sending Invoice.');
    }
    finally {
      setEnableSend(false)
      // setLoading(false);
      setSendLoading(false)
    }
  };

  //Invoice Column Name
  const invoiceColumnName = [
    {
      name: "S.NO"
    },
    {
      name: "ITEMS / SERVICES"
    },
    {
      name: "HSN / SAC"
    },
    {
      name: "QTY"
    },
    {
      name: "Price Per Item"
    },
    {
      name: "Total Price"
    }
  ];
  //Package Invoice Column Name
  const packageInvoiceColumnName = [
    {
      name: "S.NO"
    },
    {
      name: "ITEMS / SERVICES"
    },
    {
      name: "HSN / SAC"
    },
    {
      name: "QTY"
    }
  ];

  function deliverableItemName(deliverableItem: string) {
    switch (deliverableItem) {
      case "ig_reel":
        return "Reel";
      case "ig_story":
        return "Story";
      case "ig_carousel_image":
        return "Carousel";
      case "ig_static":
        return "Static Post";
      case "available_for_exclusivity":
        return `Exclusivity For ${deliverables.find((item: any) => item.deliverable_item === 'available_for_exclusivity').quantity} Days`;
      case "digital_rights":
        return `Digital Rights For ${deliverables.find((item: any) => item.deliverable_item === 'digital_rights').quantity} Days`;
      case "yt_shorts":
        return "Shorts";
      case "yt_dedicated_video":
        return "Dedicated Video";
      case "yt_integrated_video":
        return "Integrated Video";
      default:
        return "Deliverable"; // or a default value if needed
    }
  }

  function handleInvoiceNumberEdit(e: any) {
    setEditableField({
      ...editableField,
      invoiceNo: e.target.value
    })
    if (e.target.value === '') {
      setEditableFieldError({ invoiceNo: { status: true, message: 'Invoice Number is required' } })
    } else {
      setEditableFieldError({ invoiceNo: { status: false, message: '' } })
    }
  }

  const totalAmount = calc.sgst + calc.cgst + calc.subtotal;
  const amountInWords = numberFormat.convertToWords(totalAmount);

  // const handleBackClick = () => {  
  //   navigate(-1); // Navigate back to the previous page  
  // };

  const handleCloseGenerateInvoice = (sm_id: number, campaign_id: number) => {
    navigate(`/influencer/campaign/sendinvoice?campaignId=${invoiceDetails?.campaign_id}&smId=${invoiceDetails?.sm_id}`);
  }

  return (
    <>
      <div className="invoice-main-container">
        {deliverables && (
          // <div ref={targetRef} className="invoice-container" id="invoice-main">
          <div ref={targetRef} className={`invoice-container ${isDownloading ? 'add-padding' : ''}`} id='invoice-main'>
            <span className="invoice-close" onClick={()=>handleCloseGenerateInvoice(invoiceDetails.sm_id, invoiceDetails.campaign_id)} style={{cursor:'pointer'}}>  
              <strong>Generated Invoice</strong>
              <MaterialSymbol iconName="close" fontweight="200" fontSize="22px" />
            </span>
            {/* NEW ONE */}
            <div className="pdf-container">
              <div className="pdf-header">
                <div className="pdf-header-left">
                  {/* <img src={invoiceDetails?.contract_logo_url ? invoiceDetails?.contract_logo_url : DummyCampaignImage.default_campaign_image} alt="logo" width="60" height="60" style={{ marginBottom: '5px' }} /> */}

                  <div className="pdf-header-left-btm">
                    <strong>{invoiceDetails.contract_organization}</strong><br />
                    <span style={{
                      maxWidth: '250px',
                      display: 'flex',
                      flexWrap: 'wrap'
                    }}>{invoiceDetails.contract_address}</span><br />
                    {/* {invoiceDetails.contract_gstin}<br /> */}
                    {/* {invoiceDetails.contract_state}<br /> */}
                    {/* {invoiceDetails.contract_pan}<br /> */}
                  </div>
                </div>
                <div className="pdf-header-right">
                  <ul
                    className="pdf-header-right-list"
                    style={{
                      borderBottom: "1px solid #979797",
                      paddingBottom: 10,
                      marginBottom: 10
                    }}
                  >
                    <li><i className="bi bi-telephone-fill"></i>{invoiceDetails.contract_mobile}</li>
                    {/* <li><i className="bi bi-envelope-fill"></i><%= @influencer.company.try('email') %></li> */}
                  </ul>
                  <ul className="pdf-header-right-list" style={{ marginBottom: '0' }}>
                    <li><strong>PAN:</strong> {invoiceDetails.contract_pan}</li>
                    <li className="d-none"><strong>TAN:</strong> {invoiceDetails.contract_pan}</li>
                    <li className=""><strong>GSTIN:</strong> {invoiceDetails.contract_gstin}</li>
                  </ul>
                </div>
              </div>
              <div className="invoice-det">
                <div className="invoice-det-left">
                  Invoice
                </div>
                <div className="invoice-det-right">
                  <div className="invoice-det-right-btm-left">
                    Date:&nbsp;
                    <div className="d-flex align-items-center">
                    {editInvoiceDate ? (
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}                          >
                        <MobileDatePicker
                          className='input-style-date invoice-date'
                          format='DD-MM-YYYY'
                          maxDate={dayjs(new Date())}
                          defaultValue={dayjs(editableField.invoiceDate)}
                          onChange={(e: any) => setEditableField((prev: any) => ({ ...prev, invoiceDate: e?.format('YYYY-MM-DD') }))}
                        />
                      </LocalizationProvider>
                    ) : <strong>{(dayjs(invoiceDetails.invoice_dt).format('DD-MMMM-YYYY'))}</strong>}
                    {!editInvoiceDate && editAllowed && (
                      <span data-html2canvas-ignore="true" className=' icon-accepted' onClick={handleInvoiceDate}>
                        <span className="material-symbols-rounded invoice-edit-icon">edit</span>
                      </span>
                    )} 
                      {editInvoiceDate ? (
                      <>
                        <span data-html2canvas-ignore="true" className=' icon-rejected align-self-center' onClick={() => handleCrossIcon('date')} >
                          <IconRejected />
                        </span>
                      </>
                    ) : null}
                    </div>
                    
                  </div>
                  <div className="invoice-det-right-btm-right">
                    <ul>
                      <li><span>Invoice no. :</span>
                      
                      <strong className="d-flex align-items-center">{editInvoiceNumber ? (
                        <TextField
                          id="InvoiceNumber"
                          required
                          className="invoice-number-field"
                          label=""
                          defaultValue=""
                          variant="standard"
                          size="small"
                          name="invoice_no"
                          value={editableField.invoiceNo}
                          onChange={(e) =>
                            handleInvoiceNumberEdit(e)
                          }
                          error={editableFieldError?.invoiceNo?.status}
                          helperText={
                            editableFieldError?.invoiceNo?.message
                          }
                        />
                      ) : (invoiceDetails.invoice_number)}
                        {!editInvoiceNumber && editAllowed && (
                          <span data-html2canvas-ignore="true" className='icon-accepted ' onClick={handleInvoiceNumber}>
                            <span className="material-symbols-rounded invoice-edit-icon">edit</span>
                          </span>
                        )}
                        {editInvoiceNumber ? (
                          <>
                            <span data-html2canvas-ignore="true" className=' icon-rejected' onClick={() => handleCrossIcon('number')} >
                              <IconRejected />
                            </span>
                          </>
                        ) : null}</strong></li>
                      {/* <li><span>PO no. :</span><strong><%= @invoice.po_number %></strong></li> */}
                    </ul>
                  </div>
                </div>
              </div> 
              <div className="invoice-biller-info">
                <div className="invoice-biller-info-left">
                  <strong
                    className="biller-left"
                  >Bill To:</strong><div className="biller-right"><strong>
                    {/* <%= @invoice.client %> */}
                    <strong>
                      {(
                        invoiceDetails.shipping_organization + ""
                      ).toLocaleUpperCase()}
                    </strong>
                  </strong><br />
                    {/* <%= @invoice.address %> */}
                    <span style={{
                      maxWidth: '250px',
                      display: 'flex',
                      flexWrap: 'wrap'
                    }}>{invoiceDetails.shipping_address}</span>
                    {/* <br /> */}
                    {/* <%= "#{@invoice.state} #{@invoice.country} #{@invoice.pincode}" %> */}
                    {/* {invoiceDetails.shipping_state} */}

                    {/* <br /> */}
                    <br />
                    {invoiceDetails.shipping_gstin &&
                      <><strong style={{ marginTop: '10px' }}>GSTIN: &nbsp;  </strong>

                        {invoiceDetails.shipping_gstin}</>
                    }<br />
                  </div>
                </div>
                <div className="invoice-campaign-title fs-14 mt-3">
                  <strong className="">Campaign Name:</strong> {invoiceDetails.campaign_title}
                </div>
                {/* <div className="invoice-biller-info-right">
                         <strong>Campaign Name:</strong>{invoiceDetails.campaign_title}<br />
                        
                        {/*<!-- <strong>Delivery Date:</strong> 8-05-2021  --> 
                        
                      </div> */}
              </div>
              <table className="prev-inv-table table mt-2">
                {/* <thead>
        <tr>
          <th scope="col">No.</th>
          <th scope="col">Particulars</th>
          <th scope="col">HSN/SAC</th>  
          <th scope="col">Quantity</th>
          <th scope="col">Price per item(INR)</th>
          <th scope="col">Total price(INR)</th>
        </tr>
      </thead> */}
                {/* <tbody> */}

                {!deliverables.find((item: any) => item.deliverable_item === "Package Cost 1") ? (
                  <InvoiceTableComponent className="table-individual-costing"  columns={invoiceColumnName}                    >
                    {deliverables.map((data: any, index: number) => {
                      return (
                        <TableRow>
                          <TableCell align="left">{index + 1}</TableCell>
                          <TableCell align="left">
                            
                            <strong>{deliverableItemName(data.deliverable_item)}</strong>
                            {/* <span className="d-flex d-md-none mt-1"><strong className="me-1">HSN/SAC:</strong>
                            998361</span> */}
                            <div className="d-flex d-md-none">
                               <div className="d-flex align-items-center">
                               <span>
                               {data.deliverable_item === 'available_for_exclusivity' || data.deliverable_item === 'digital_rights' ? (
                              ''
                            ) : (
                              data.quantity
                            )}
                            {(data.deliverable_item !== 'available_for_exclusivity' && data.deliverable_item !== 'digital_rights')  && <>x</>} {methods.decimalFormat(
                                (['digital_rights', 'available_for_exclusivity'].includes(data.deliverable_item) ? (data.price / 1) : (data.price / data.quantity))
                              )}
                            </span>
                              </div>
                            </div>
                            
                          </TableCell>
                          <TableCell align="left">998361</TableCell>
                          <TableCell align="left">
                            {data.deliverable_item === 'available_for_exclusivity' || data.deliverable_item === 'digital_rights' ? (
                              '--'
                            ) : (
                              data.quantity
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <CurrencyRupee style={{ fontSize: '16px' }} />
                            {methods.decimalFormat(
                              (['digital_rights', 'available_for_exclusivity'].includes(data.deliverable_item) ? (data.price / 1) : (data.price / data.quantity))
                            )}
                          </TableCell>
                          {/* <TableCell align="left" >
                                {(methods.roundToTwoDecimalPlaces(data.price * 18) / 100)}
                                <span style={{ marginLeft: "10px" }}>(18%)</span>
                              </TableCell> */}
                          <TableCell align="right">

                            <CurrencyRupee style={{ fontSize: '16px' }} />
                            {methods.roundToTwoDecimalPlaces(data.price)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </InvoiceTableComponent>
                ) : <InvoiceTableComponent className="table-package-costing"  columns={packageInvoiceColumnName}                    >
                  {deliverables
                    .filter((data: any) => data.deliverable_item !== 'Package Cost 1')
                    .map((data: any, index: number) => {
                      return (
                        <TableRow>
                          <TableCell align="left">{index + 1}</TableCell>
                          <TableCell align="left">
                            {deliverableItemName(data.deliverable_item)}
                             <span className="d-flex d-md-none mt-1"><strong className="me-1">HSN/SAC:</strong>
                            998361</span>
                          </TableCell>
                          <TableCell align="left">998361</TableCell>
                          <TableCell align="right">
                            {data.deliverable_item === 'available_for_exclusivity' || data.deliverable_item === 'digital_rights' ? (
                              '--'
                            ) : (
                              data.quantity
                            )}
                          </TableCell>


                        </TableRow>
                      );
                    })}
                </InvoiceTableComponent>}
                <div className="summary-table" style={{ width: '300px', float: 'right' }}>
                  <Table className="">

                    <TableRow>
                      <TableCell align="right" colSpan={3}>
                        <strong>Subtotal:</strong>
                      </TableCell>
                      <TableCell align="right" ></TableCell>
                      <TableCell align="right" colSpan={3}>
                        <CurrencyRupee className="rupee-icon" /> {methods.decimalFormat(calc.subtotal)}
                      </TableCell>
                    </TableRow>
                    {/* <TableRow>
                      <TableCell align="left">Taxable Amount</TableCell>
                      <TableCell align="left" colSpan={5}></TableCell>
                      <TableCell align="left"><CurrencyRupee className="rupee-icon" /> {methods.decimalFormat(calc.subtotal)}</TableCell>
                    </TableRow> */}
                    {(invoiceDetails.contract_state !== invoiceDetails.shipping_state) ? (
                      <>
                        <TableRow>
                          <TableCell align="right" colSpan={3}>CGST <span>(9%)</span></TableCell>
                          <TableCell align="left" ></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="right"><CurrencyRupee className="rupee-icon" /> {methods.decimalFormat(calc.cgst)}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right" colSpan={3}>SGST <span>(9%)</span></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="right"><CurrencyRupee className="rupee-icon" /> {methods.decimalFormat(calc.sgst)}</TableCell>
                        </TableRow>
                      </>) :
                      (
                        <TableRow>
                          <TableCell align="right" colSpan={3}>IGST <span>(18%)</span></TableCell>
                          <TableCell align="left" ></TableCell>
                          <TableCell align="right" colSpan={3}><CurrencyRupee className="rupee-icon" /> {methods.decimalFormat(calc.igst)}</TableCell>
                        </TableRow>
                      )
                    }

                    {/* <TableRow>
                  <TableCell align="left">IGST</TableCell>
                  <TableCell align="left" colSpan={3}></TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left"><CurrencyRupee className="rupee-icon" /> {calc.sgst + calc.cgst}</TableCell>
                </TableRow> */}
                    <TableRow>
                      <TableCell colSpan={7}></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={7}></TableCell>
                    </TableRow>
                    <TableRow className="total-row">

                      <TableCell align="right" className="cell-total" colSpan={3}><strong>Total Amount</strong></TableCell>

                      <TableCell align="left"></TableCell>
                      <TableCell align="right" colSpan={3}><CurrencyRupee className="rupee-icon" /><strong>{calc.sgst + calc.cgst + calc.subtotal}</strong></TableCell>
                    </TableRow>
                  </Table>
                </div>

                {/* </tbody> */}

              </table>
              <span className="d-block ps-3 ps-lg-0 mb-md-3 "  style={{ borderBottom: '1px solid #ddd', paddingBottom: '10px', paddingTop: '30px', textTransform:'capitalize' }}>In Words: Rs. {amountInWords} only</span>

              <div className="inv-bank-det mb-2 ps-3 ps-lg-0">
                {invoiceDetails?.account_number &&
                  <>
                    <strong className="pl-4 py-2 d-block">Bank Account Details:</strong>
                    Account Holder Name: &nbsp; {invoiceDetails?.account_holder_name}
                    <br />
                    Account Type: &nbsp; {invoiceDetails?.account_type}
                    <br />

                    Bank Name: &nbsp;  {invoiceDetails?.bank_name}
                    <br />

                    Bank Address: &nbsp; {invoiceDetails?.address}
                    <br />

                    Account no.: &nbsp; {invoiceDetails?.account_number}
                    <br />

                    IFSC Code: &nbsp; {invoiceDetails.ifsc_code}
                    <br />
                    MICR Code: &nbsp; {invoiceDetails.micr_code}
                  </>}
                <div className="inv-sign pt-5 pe-3 pe-lg-0">
                  <span className="d-block">Auth. Signatory</span>
                  {/* <img src="<%= @influencer.company.try('signature_url') %>" alt="user-signature" width="100" height="50" /> */}

                  <strong className="d-block pb-md-5">{invoiceDetails?.contract_organization}</strong>
                </div>
              </div>
            </div>
            <div data-html2canvas-ignore="true"
              className='invoice-send d-flex justify-content-end  mt-2'
              style={{ borderTop: '1px solid #d7d7d7' }}
            >
              {/* <Button
                className="btn btn-outline-primary me-2"
                // disabled={sendLoading}
                sx={{ ml: 2 }}
                onClick={handleBackClick}
              >
                Back
              </Button> */}
              {(editInvoiceNumber || editInvoiceDate) 
                ?
                  <Button
                    className='btn btn-primary me-2 '
                    onClick={updateGeneratedInvoice}
                  >
                    Save
                  </Button> 
                : 
                (
                  // <Button
                  //   className='btn btn-primary me-2'
                  //   onClick={() => downloadFile(invoiceDetails.file_url)}
                  // >
                  //   {downloadLoading ? "Downloading..." : "Download"}
                  // </Button>
                  <Button
                    className="btn btn-primary me-2"
                    onClick={() => { /* No custom JS required */ }}
                  >
                    <a 
                      href={invoiceDetails.file_url} 
                      target="_blank" 
                      download 
                      style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                      {downloadLoading ? "Downloading..." : "Download"}
                    </a>
                  </Button>

                )
              }

              {/* {(!editInvoiceNumber && !editInvoiceDate && editAllowed) && (
                <LoadingButton
                  disabled={loading}
                  className='btn btn-outline-primary '
                  loading={loading}
                  // onClick={() => generateInvoicePdf('Send')}
                  onClick={() => {
                    generateInvoicePdf('Send');
                    setInvoiceSent(true);
                  }}>

                  Send
                </LoadingButton>
              )} */}
              {(!editInvoiceNumber && !editInvoiceDate && editAllowed) && (
                <Button
                  className={`btn ${sendLoading ? "btn-disabled" : "btn-outline-primary"}`}
                  disabled={sendLoading}
                  // onClick={() => generateInvoicePdf('Send')}
                  onClick={handleSendInvoice}
                >
                  {sendLoading ? "Sending..." : "Send"}
                </Button>
              )}
            </div>
          </div>

        )}
      </div>
      {handleSendInvoiceButton && createPortal(
        <ConfirmPopup 
          extraClass="confirm-send-whatsapp" 
          confirmPopupInnerText="Are you sure you want to send this invoice?" 
          openPopup={handleSendInvoiceButton} 
          confirmClosePopup={handleSendInvoiceClose} 
          confirmClickAction={
            ()=>{
              setInvoiceSent(true);
              sendInvoice();
            }
          }
        drawerTitle="" />, document.body
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  UserReducer: state.UserReducer.UserData,
});

const mapDispatchToProps = (dispatch: any) => ({
  loading: (data: any) => dispatch(Loading(data)),
});

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(PrintInvoice);

export { connectToRedux as PrintInvoice };
