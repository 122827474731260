import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from "chart.js";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

// Define types for the data structure
interface BrandErChartProps {
  data: { all: number; paid: number; organic: number }; // Data containing 'all', 'paid', and 'organic'
}

const BrandErChart: React.FC<BrandErChartProps> = ({ data }) => {
  const chartConfig = {
    labels: ["Engagement Rate"], // Common label for all bars
    datasets: [
      {
        label: "All",
        data: [data.all],
        backgroundColor: "#63ABFD", // Red color for 'All'
        borderColor:'#165BAA',
        borderWidth:2,
        borderRadius: 5,
        barThickness: 20, // Adjusted height of the bars
        clip: 20,  // Prevents clipping
        barPercentage: 0.6, // Adjust width inside category
        categoryPercentage: 0.5 // Adjust spacing between bars
      },
      {
        label: "Paid",
        data: [data.paid],
        backgroundColor: "#E697FF", // Blue color for 'Paid'
        borderColor:'#A155B9',
        borderWidth:2,
        borderRadius: 5,
        barThickness: 20,
        clip: 20, 
      barPercentage: 0.6,
      categoryPercentage: 0.5,
      },
      {
        label: "Organic",
        data: [data.organic],
        borderColor:'#189661',
        borderWidth:2,
        backgroundColor: "#2BFFA6", // Yellow color for 'Organic'
        borderRadius: 5,
        barThickness: 20,
        clip: 20, 
      barPercentage: 0.6,
      categoryPercentage: 0.5,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false, 
    indexAxis: "y" as const, // Set explicitly to 'y' for horizontal bar chart
    plugins: {
      legend: {
        display: false, // Display legend for the three categories
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        max: 5, // Assuming engagement rates are percentages
        display:false
      },
      y:{display:false},
    },
    plotOptions: {
      bar: {
        borderRadius: '50%',
        dataLabels: {
          enabled: true
        },
        groupPadding: 0.1
      }
    },
    layout: {
        padding: {
          bottom: 0, // Adds padding at the bottom of the chart
          right:20
        },

      },
      
  };

  return (
    <div style={{height:'88px', width:'100%'}}>
    <Bar data={chartConfig} options={chartOptions} />
    </div>
  )
};

export default BrandErChart;
