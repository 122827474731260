import React from "react";
// import { Pie } from "react-chartjs-2";
import { Doughnut } from "react-chartjs-2";
import { Loading } from "../../../redux/action";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';


import connect from "react-redux/es/components/connect";
import "./brand-data.scss";
import BrandErChart from "./brand-er-chart";

ChartJS.register(ArcElement, Tooltip, Legend);

interface Props {
  UserReducer: any;
  loading: (data: any) => void;
  data: any;
}
const ComparisionTable: React.FC<Props> = (props: Props) => {
  const donutChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Removes the legend
      },
    },
    cutout: '70%', // This will create a donut effect by cutting out the center
  };

  const generateChartData = (engagement: any) => ({
    labels: ["Nano", "Micro", "Micro Plus", "Macro", "Macro Plus", "Mega", "Mega Plus"],

    datasets: [
      {
        data: [
          engagement.Nano,
          engagement.Micro,
          engagement.Macro,
          engagement.Mega,
          engagement.Mega,
        ],
        backgroundColor: ["#FFA5CB", "#FFD232", "#63ABFD", "#1CF7C2", "#F45DCE", "#6CDBF3", "#48B4CB"],
        hoverBackgroundColor: ["#A53364", "#A6891F",  "#36A2EB", "#189661", "#D859B8", "#165BAA", "#4BC0C0", "#3F9AAE"],
      },
    ],
  });



  return (
    
      <section className="brand-insight-highchart brand-insight-highchart-comparison">
        <div className="brand-insight-highchart-top">
          <strong className="brand-insight-highchart-title d-flex flex-column">Comparison with other players in the market
          </strong>
        </div>
        <div className="brand-insight-highchart-data">
          <p style={{ fontSize: '18px', fontWeight: 400, fontFamily: 'Open Sans', lineHeight: '28px', color: '#101828' }}></p>
          <div className="comparison-table d-flex">
            <div className="header-column">
              <div className="header-cell" style={{ height: "140px", visibility: 'hidden' }}>Company</div>
              <div className="header-cell">Followers</div>
              <div className="header-cell">Post Frequency</div>
              <div className="header-cell" style={{ height: '88px' }}>ER
                <div className="d-flex gap-2 flex-wrap">
                  <div className="d-flex gap-1 align-items-baseline">
                    <div style={{ width: '20px', height: '11px', backgroundColor: '#165BAA' }}>

                    </div>
                    <p className="mb-0">All</p>
                  </div>
                  <div className="d-flex gap-1 align-items-baseline"><div style={{ width: '20px', height: '11px', backgroundColor: '#A155B9' }}></div><p className="mb-0">Paid</p></div>
                  <div className="d-flex gap-1 align-items-baseline"><div style={{ width: '20px', height: '11px', backgroundColor: '#189661' }}></div><p className="mb-0">Organic</p></div>
                </div>
              </div>
              <div className="header-cell">Budget
                <p className="mb-0" style={{ color: '#787878', fontSize: '14px' }}>(Monthly/Annually)</p></div>
              {/* <div className="header-cell">EMV 
              <p style={{color:'#787878',fontSize:'14px'}}> (Last 30 Days)</p></div> */}
              {/* <div className="header-cell">ROI</div> */}
              <div className="header-cell" style={{ minHeight: '190px' }}>Influencer Engagement Ratio
                <p style={{ color: '#787878' }}>last 90 days</p>
                <div className="d-flex gap-2 flex-wrap">
                  <div className="d-flex gap-1 " style={{minWidth:'100px'}}>
                    <div style={{ width: '20px', height: '20px', backgroundColor: '#FFA5CB',  }}>

                    </div>
                    <p style={{ color: "#5D5D5D", lineHeight:'0.8' }}>Nano <span style={{ paddingTop:'5px', display: 'block', color: '#5D5D5D', fontWeight: '400', marginBottom:0 }}>(1k-10k)</span></p>

                  </div>
                  <div className="d-flex gap-1 " style={{minWidth:'100px'}}><div style={{ width: '20px', height: '20px', backgroundColor: '#FFD232' }}></div><p style={{ marginBottom:0, color: "#5D5D5D", lineHeight:'0.8' }}>Micro<span style={{ paddingTop:'5px', display: 'block', color: '#5D5D5D', fontWeight: '400' }}>(10K-50K)</span></p></div>
                  <div className="d-flex gap-1 " style={{minWidth:'100px'}}><div style={{ width: '20px', height: '20px', backgroundColor: '#63ABFD' }}></div><p style={{ marginBottom:0, color: "#5D5D5D", lineHeight:'0.8' }}>Micro Plus<span style={{ paddingTop:'5px', display: 'block', color: '#5D5D5D', fontWeight: '400' }}>(50K-100K)</span></p></div>
                  <div className="d-flex gap-1 " style={{minWidth:'100px'}}><div style={{ width: '20px', height: '20px', backgroundColor: '#1CF7C2' }}></div><p style={{  color: "#5D5D5D", lineHeight:'0.8' }}>Macro<span style={{ paddingTop:'5px', display: 'block', color: '#5D5D5D', fontWeight: '400' }}>(100K-500K)</span></p></div>
                  <div className="d-flex gap-1 " style={{minWidth:'100px'}}><div style={{ width: '20px', height: '20px', backgroundColor: '#F45DCE' }}></div><p style={{ color: "#5D5D5D", lineHeight:'0.8' }}>Macro Plus<span style={{ paddingTop:'5px', display: 'block', color: '#5D5D5D', fontWeight: '400' }}>(500K-1M)</span></p></div>
                  <div className="d-flex gap-1 " style={{minWidth:'100px'}}><div style={{ width: '20px', height: '20px', backgroundColor: '#6CDBF3' }}></div><p style={{ marginBottom:0, color: "#5D5D5D", lineHeight:'0.8' }}>Mega<span style={{ paddingTop:'5px', display: 'block', color: '#5D5D5D', fontWeight: '400' }}>(1M-5M)</span></p></div>
                  <div className="d-flex gap-1 " style={{minWidth:'100px'}}><div style={{ width: '20px', height: '20px', backgroundColor: '#48B4CB' }}></div><p style={{ color: "#5D5D5D", lineHeight:'0.8' }}>Mega Plus<span style={{ paddingTop:'5px', display: 'block', color: '#5D5D5D', fontWeight: '400' }}>(5M+)</span></p></div>
                </div>
              </div>
            </div>
            {props.data.map((item: any) => (
              <div key={item.ref_id} className="header-column" style={{ marginRight: '5px', border: '1px solid #C6DCE3', borderRadius: '10px 10px 0 0' }}>
                <div className="header-cell" style={{ height: '140px', backgroundColor: '#F1F8FB', display: 'flex', justifyContent: 'center', alignItems: 'center', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
                  <img src={item.gcp_profile_image_url} alt="brand-logo" style={{ width: '70px', height: '70px', objectFit: 'cover', borderRadius: '50%', marginBottom:'10px' }} />
                  {item.company}
                </div>
                <div className="header-cell" style={{ borderBottom: '1px solid #EAECF0' }}>{item.followers}</div>
                <div className="header-cell" style={{ borderBottom: '1px solid #EAECF0' }}>{item.postFrequency}</div>
                <div className="header-cell" style={{ borderBottom: '1px solid #EAECF0' }}>
                  {/* {item.erData.all}% / {item.erData.paid}% / {item.erData.organic}% */}
                  <BrandErChart data={item.erData} />
                </div>
                <div className="header-cell" style={{ borderBottom: '1px solid #EAECF0' }}>{(item.totalAmount / 3).toFixed(2)}/{(item.totalAmount * 4)}</div>
                <div className="" style={{ width: "100%", height: "170px", display: 'flex', justifyContent: 'center', paddingTop: '16px' }}>
                  {/* <Pie data={generateChartData(item.influencerEngagement)} /> */}
                  <Doughnut data={generateChartData(item.influencerEngagement)} options={donutChartOptions} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(mapStateToProps, mapDispatchToProps)(ComparisionTable);

export { connectToRedux as ComparisionTable };
