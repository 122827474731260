import { Skeleton, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { DefaultUserProfile } from '../../../config/config';
import { Link } from 'react-router-dom';
import InstagramIcon1 from '../../../components/icons/instagram-icon1';
import MaterialSymbol from '../../../components/icons/material-symbol';
import dayjs from "dayjs";

type Props = {
  influencerData: any
  loading: boolean;
  email?: any;
  phone?: any;
  location?: any;
  category?: any;
  language?:any;
  subscription_status?:any;
}
const InfluencerProfileLeft = ({ influencerData, email, phone, loading, location,language, category, subscription_status }: Props) => {
  const [showMoreText, setShowMoreText] = useState(false)
  const handleMoreText = () => {
    setShowMoreText((prev: boolean) => !prev)
  }
  const dob = influencerData?.detail?.[0]?.dob;
  const shouldRenderAge = dob && dob !== '1970-01-01';
  return (
    <>
      {/* // FOR DESKTOP VIEW */}
      <div className='influencer-profile__left-inner d-none d-lg-flex'>
        <div className='influencer-profile__image'>
          {loading ? <Skeleton width={132} height={132} variant='circular' /> : <img
            src={
              influencerData &&
              influencerData.profile_detail &&
              influencerData.profile_detail.length > 0
              && ((influencerData.profile_detail[0].gcp_profile_image_url ? influencerData.profile_detail[0].gcp_profile_image_url : influencerData.profile_detail[0].profile_image_url)
                ||
                DefaultUserProfile?.influencer_unknown_default)
            }

            alt="Influencer Profile"
          />
          }
          <div className='influencer-profile__image--curve'></div>
          <div className='influencer-profile__image--curve-left'></div>
        </div>
        <div className='influencer-user__info'>
          {loading ? (
            <Skeleton height={57} />
          ) : (
            <h3>{influencerData?.profile_detail?.[0]?.insta_name}</h3>
          )}
          <span className='fs-14'>
            {loading ? (
            <Skeleton style={{display:'inline-flex'}} width={20} height={20} />
          ) : influencerData?.agegender_detail?.[0]?.age &&
              influencerData.agegender_detail[0].age !== '1970-01-01' && (
                <>
                  {influencerData.agegender_detail[0].age}
                  {influencerData.agegender_detail[0].gender !== 'Other' && influencerData.agegender_detail[0].age.trim() !== '' && ', '}
                </>
              )}

            {loading ? (
              <Skeleton width={50} style={{display:'inline-flex', marginLeft:
                '10px'
              }} />
            ) : (
              influencerData?.agegender_detail?.[0]?.gender !== 'Other' &&
              influencerData?.agegender_detail?.[0]?.gender
            )}
          </span>
        </div>
        
        <div className='influencer-user__location'>
          <div className='d-flex' style={{marginBottom : loading ? "1rem" : "", alignItems : !loading ? "center" : ""}}>{loading ? <Skeleton height={12} variant='circular' width={12} style={{marginRight:
                '16px', }} /> : subscription_status.status && <p className='d-flex align-items-center'><Tooltip arrow 
          title={subscription_status.status == 'non_subscribed' ? 'Non - subscriber' : subscription_status.status == 'active' ? 'Active subscription' : 'Expired subscription'}>
            <span className={`me-3 ms-1 discover_subscription_dot discover_subscription_dot_${subscription_status.status}`}></span></Tooltip></p>}
          {
            loading ? <Skeleton height={21} width={150} /> : subscription_status.plan_start_date && 
            <p className='d-flex align-items-center'>
             
              <Tooltip arrow 
                   title='Plan date'><span>
                  {`${dayjs(subscription_status.plan_start_date).format('D MMM YYYY')} - ${dayjs(subscription_status.plan_end_date).format('D MMM YYYY')}`}
              </span></Tooltip>
            </p>
          }
          </div>  
          {(loading || (email && email.length > 0)) && <p className='d-flex align-items-center'>
            {loading ? <Skeleton width={20} height={24} /> : <MaterialSymbol iconName='mail' fontweight='200' color='#929292' iconFilled />} <span className='ms-2'>{loading ? <Skeleton width={150} height={24} />  : email}</span></p>}

            
          {(loading || (phone != 0 && phone)) && 
            <p className='d-flex align-items-center'>
              {loading ? <Skeleton width={20} height={24} /> : <MaterialSymbol iconName='call' fontweight='200' color='#929292' iconFilled />}
              <span className='ms-2'>{loading ? <Skeleton width={150} height={24} />  : phone}</span>
            </p>
          }
          {(loading || (location && location.length > 0)) && <p className='d-flex align-items-center'>{loading ? <Skeleton width={20} height={24} /> : <MaterialSymbol iconName='location_on' fontweight='200' color='#929292' iconFilled />}<span className='ms-2'>{loading ? <Skeleton width={150} height={24} />  : location}</span></p>}


  {(loading || category.some((item:any) => !!item)) && (
  <div className='influencer-user__category'>
    {category[0] ? (
      loading ? (
        <Skeleton height={26} width={50} />
      ) : (
        <span>{category[0]}</span>
      )
    ) : (
      loading && <Skeleton height={26} width={50} />
    )}
    {category[1] ? (
      loading ? (
        <Skeleton height={26} width={50} />
      ) : (
        <span>{category[1]}</span>
      )
    ) : (
      loading && <Skeleton height={26} width={50} />
    )}
    {category[2] ? (
      loading ? (
        <Skeleton height={26} width={50} />
      ) : (
        <span>{category[2]}</span>
      )
    ) : (
      loading && <Skeleton height={26} width={50} />
    )}
    {category[3] ? (
      loading ? (
        <Skeleton height={26} width={50} />
      ) : (
        <span>{category[3]}</span>
      )
    ) : (
      loading && <Skeleton height={26} width={50} />
    )}
  </div>
)}



          {(loading || (language[0] || language[1] || language[2] || language[3])) &&
            <div className='influencer-user__category'>
              {loading ? <Skeleton height={26} width={50} /> :language[0] && <span>{language[0]}</span>}
              {loading ? <Skeleton height={26} width={50} /> :language[1] && <span>{language[1]}</span>}
              {loading ? <Skeleton height={26} width={50} /> :language[2] && <span>{language[2]}</span>}
              {loading ? <Skeleton height={26} width={50} /> :language[3] && <span>{language[3]}</span>}
            </div>
          }

        </div>
        <div className={`influencer-user__intro ${showMoreText ? "more-text" : ""}`}>
          <p className="mb-0">{loading ? <Skeleton height={72} /> : <>{influencerData?.profile_detail?.[0]?.biography}</>}</p>
          {loading ? <Skeleton height={24} /> : influencerData?.profile_detail?.[0]?.biography && influencerData?.profile_detail?.[0]?.biography.length > 85 && <button onClick={handleMoreText} className='btn btn-text-link'>{showMoreText ? "less" : "more"}</button>}
        </div>
      </div>

      {/* // FOR MOBILE VIEW */}
      <div className='influencer-profile__left-inner influencer-profile__left-inner-mobile d-lg-none'>
        <div className='influencer-user-profile'>
          <div className='influencer-profile__image influencer-user-profile-left'>
            {loading ? <Skeleton width={88} height={88} variant='circular' /> : <img className='d-flex'
              src={
                influencerData &&
                influencerData.profile_detail &&
                influencerData.profile_detail.length > 0
                && ((influencerData.profile_detail[0].gcp_profile_image_url ? influencerData.profile_detail[0].gcp_profile_image_url : influencerData.profile_detail[0].profile_image_url)
                  ||
                  DefaultUserProfile?.influencer_unknown_default)
              }

              alt="Influencer Profile"
            />
            }
             
          </div>
          
          <div className='influencer-user-profile-right d-flex d-lg-none flex-column align-items-start'>
            <h3>{loading ? <Skeleton width={200} /> : influencerData && influencerData?.profile_detail?.[0]?.insta_name}</h3>
            <div className='d-flex'>
              <InstagramIcon1 width="20px" height="20px" />
              <Link
                className="influencer-profile__social-chan"
                to={`https://www.instagram.com/${influencerData && influencerData?.profile_detail?.[0]?.username}`}
                target="_blank"
              >
                <span className="influ-prof-info-social-username d-flex justify-content-center align-items-baseline">
                  {loading ? <Skeleton width={150} /> : influencerData?.profile_detail?.[0]?.username}
                </span>
              </Link>
              </div>
               <div className='influencer-user__info'>
              
              
               {(loading || (email && email.length > 0)) && <p className='d-flex align-items-center mb-0 mb-lg-3'><MaterialSymbol iconName='mail' fontweight='200' color='#929292' iconFilled /> <span className='ms-lg-2'>{loading ? <Skeleton width={200} /> : email}</span></p>}
          {(loading || (phone != 0 && phone)) && 
            <p className='d-flex align-items-center mb-0 mb-lg-3'>
              <MaterialSymbol iconName='call' fontweight='200' color='#929292' iconFilled />
              <span className='ms-lg-2'>{loading ? <Skeleton width={200} /> : phone}</span>
            </p>
          }
            </div>
            
            </div>
           
       
        </div>
        <div className='' style={{borderTop:'1px solid #444', borderBottom:'1px solid #444', padding:'1rem 0'}}>
          <span className='fs-14'>
                  {loading ? <Skeleton width={50} /> : influencerData?.agegender_detail?.[0]?.age ? `${influencerData?.agegender_detail?.[0]?.age}, ` : "NA"}
                  <>{loading ? <Skeleton width={100} /> : influencerData?.agegender_detail?.[0]?.gender}</>
                </span>
          {(location && location.length > 0) && <p className='d-flex align-items-center mb-1 mb-lg-3'><MaterialSymbol className='d-none d-lg-flex' iconName='location_on' fontweight='200' color='#929292' iconFilled /><span className='ms-lg-1'>{loading ? <Skeleton width={200} /> : location}</span></p>}

            {
            subscription_status.plan_start_date && 
            <p className='d-flex align-items-center mb-0 mb-lg-3'>
              <Tooltip arrow 
          title={subscription_status.status == 'non_subscribed' ? 'Non - subscriber' : subscription_status.status == 'active' ? 'Active subscription' : 'Expired subscription'}>
            <span className={`discover_subscription_dot discover_subscription_dot_${subscription_status.status}`}></span></Tooltip>
              <MaterialSymbol iconName='workspace_premium' fontweight='200' color='#929292' iconFilled />
              <Tooltip arrow 
                   title='Plan date'><span>
                  {loading ? <Skeleton /> : `${dayjs(subscription_status.plan_start_date).format('D MMM YYYY')} - ${dayjs(subscription_status.plan_end_date).format('D MMM YYYY')}`}
              </span></Tooltip>
            </p>
          }
        </div>
        <p className="mb-0">{loading ? <Skeleton /> : influencerData?.profile_detail?.[0]?.biography}</p>
        <div className='influencer-user__intro'>
             <div className='influencer-user-profile-right'>
            
            
            <div className='influencer-user__location'>
         
          
         
          
          {(loading || (category[0] || category[1] || category[2] || category[3])) &&
            <div className='influencer-user__category'>
              {(loading || category[0]) && <span>{loading ? <Skeleton /> : category[0]}</span>}
              {(loading || category[1]) && <span>{loading ? <Skeleton /> : category[1]}</span>}
              {(loading || category[2]) && <span>{loading ? <Skeleton /> : category[2]}</span>}
              {(loading || category[3]) && <span>{loading ? <Skeleton /> : category[3]}</span>}
            </div>
          }
          {(loading || (language[0] || language[1] || language[2] || language[3])) &&
            <div className='influencer-user__category'>
              {(loading || language[0]) && <span>{(loading ? <Skeleton /> : language[0])}</span>}
              {(loading || language[1]) && <span>{(loading ? <Skeleton /> : language[1])}</span>}
              {(loading || language[2]) && <span>{(loading ? <Skeleton /> : language[2])}</span>}
              {(loading || language[3]) && <span>{(loading ? <Skeleton /> : language[3])}</span>}
            </div>
          }

        </div>
          </div>
          
          
        </div>
      </div>
    </>
  )
}

export default InfluencerProfileLeft
