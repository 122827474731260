import React, { useState, useEffect } from "react";
// import "./detailed-analytics.scss";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Loading } from "../../../redux/action";
import { connect } from "react-redux";
import { AnalyticsService } from "../../../core/services/analytics.service";
import "./analytics.scss";
import { Tab,Skeleton } from "@mui/material";
import { SortingColumnsType } from "../../../../src/components/table-component/table"
import AddAnalytics from "./add_analytics_brand";
import AnalyticsDashboard from "./components/analytics-dashboard";
import { MostEngagingPosts } from "./components/most-engaging-posts";
import { MostEngagingInfluencer } from "./components/most-engaging-influencer";
import UpgradeBrandPopUp from "../../../components/popup/upgrade-brand-popup";
import ROI from "./roi";
import DetailedInsightsInfluencer from "./components/detailed-insights-influencer";
import DetailedInsightsPost from "./components/detailed-insights-post";
import dayjs from "dayjs";
import CommonPopUp from "../../../components/popup/common-popup";

import { createPortal } from "react-dom";
import { FormValidation } from "../../../core/utility";
import MaterialSymbol from "../../../components/icons/material-symbol";
import ConfirmPopup from "../../../components/confirm-popup/confirm-popup";
import { toast } from "react-toastify";
import InstagramEmbed from "../../../components/instagram-embed/instagram-embed";
import SampleDetailedAnalytics from "./components/sample-detailed-analytics";
import FormShareAnalytics from "./components/form-share-analytics";

import { WhatsappIcon, WhatsappShareButton } from "react-share";
import { LocalService } from "../../../core/services";
import { sampleMostEngagingInfluencers, sampleInfluencerDetailedData, samplePostDetailedData, sampleMostEngagingPosts } from '../../../data/data';
import * as gtUtil from "../../../core/utility";

interface Props {
  UserReducer: any;
  UserID: any;
  loading: (data: any) => void;
  selectCampaign: any;
  getSubscriptionDetail: any;
}

const analyticsService = new AnalyticsService();
const localService = new LocalService();
const columnDisplayNamePostDetailedData = {
  er: "ER",
  video_plays: "Plays",
  likes: "Likes",
  comments: "Comments",
  created_at: "Date",
};
const columnDisplayNameInfluencerDetailedData = {
  total_post: "Post",
  total_er: "ER",
  video_plays: "Plays",
  total_likes: "Likes",
  total_comments: "Comments",
};

const PostDetailedData: SortingColumnsType[] = [
  {
    name: "Post",
    columnSize: "35%",
  },
  {
    name: "",
    columnSize: "8%",
  },
  {
    name: "ER",
    columnSize: "14%",
  },
  {
    name: "Views",
    columnSize: "10%",
  },
  {
    name: "Likes",
    columnSize: "10%",
  },
  {
    name: "Comments",
    columnSize: "10%",
  },
  {
    name: "Posted on",
    columnSize: "15%",
  },

];

const InfluencerDetailedData: SortingColumnsType[] = [
  {
    name: "Influencer",
    columnSize: "35%",
  },
  {
    name: "Post",
    columnSize: "10%",
  },
  {
    name: "ER",
    columnSize: "14%",
  },
  {
    name: "Views",
    columnSize: "10%",
  },
  {
    name: "Likes",
    columnSize: "10%",
  },
  {
    name: "Comments",
    columnSize: "10%",
  },
];

interface ErrorTypes {
  status: boolean;
  message: string;
}


const DetailedAnalytics: React.FC<Props> = (props: Props) => {
  const formValidation = new FormValidation();
  const [value, setValue] = useState("1");
  const [value2, setValue2] = useState("1");
  const [value4, setValue4] = useState("1");
  const [
    analyticsCumulativeDataIgNumericData,
    setAnalyticsCumulativeDataIgNumericData,
  ] = useState<any>([]);
  const [
    analyticsCumulativeDataIgMediaData,
    setAnalyticsCumulativeDataIgMediaData,
  ] = useState<any>([]);
  const [postFilterData, setPostFilterData] = useState<any>([]);
  const [influencerFilterData, setInfluencerFilterData] = useState<any>([]);
  const [creativeData, setCreativeData] = useState();
  const [graphData, setGraphData] = useState<any>([]);
  const [campaignId, setCampaignId] = useState(0);
  const [campaignPlatform, setCampaignPlatform] = useState("");
  const [smId, setSmId] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [totalPost, setTotalPost] = useState<any>(0);
  const [openUpgradeBrandModal, setOpenUpgradeBrandModal] = useState(false);
  const [addedLinksDetails, setAddedLinksDetails] = useState<any>([]);
  const [buttonClass, setButtonClass] = useState<string>(
    "btn btn-primary disabled"
  );
  const [openModal, setOpenModal] = useState(false);
  const [deleteUrlId, setDeleteUrlId] = useState(null);
  const [sharedUserList, setSharedUserList] = useState<any>([]);

  // -----------------Open Model-----------------------
  const [openModalAddPost, setOpenModalAddPost] = useState<boolean>();

  // -------------
  const urlParams = new URLSearchParams(window.location.search);
//        
  // -----------
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleChange4 = (event: React.SyntheticEvent, newValue: string) => {
    setValue4(newValue);
  };

  // -----------------------------------------------------------------------------------------------

  const handleOpenModalAnalytics = (
    campaign_id: number,
    campaign_platform: string
  ) => {
    setOpenModalAddPost(true);
    setCampaignId(campaign_id);
    setCampaignPlatform(campaign_platform);
  };
  const [openModalROI, setOpenModalROI] = useState<boolean>(false);

  useEffect(() => {
    analyticsData();
    getPostLinkData();
  }, []);

 useEffect(() => {
    // Trigger Instagram embed processing when data is updated
     if (window.instgrm?.Embeds) {
      window.instgrm.Embeds.process();
    }
  }, [addedLinksDetails, postFilterData]);

  const analyticsData = async () => {
    let campaign_id = props?.selectCampaign?.campaign_id;
    const res: any = await analyticsService.brandAnalytics({
      campaign_id: campaign_id,
      sm_id: null,
    });
    if (res.status === gtUtil.Constants.SUCCESS) {
      setAnalyticsCumulativeDataIgMediaData(
        res.data.rows[0].analytics_data_ig_media[0]
      );
      if (res?.data?.rows[0]?.analytics_data_ig_numeric.length > 0) {
        setAnalyticsCumulativeDataIgNumericData(
          res.data.rows[0].analytics_data_ig_numeric[0]
        );
      }
      setPostFilterData(res.data.rows[0].post_data_content);
      setInfluencerFilterData(res.data.rows[0].influencer_data_content);
      setGraphData(res.data.rows[0].graph_data_array);
      setCreativeData(res.data.rows[0].no_of_creatives);
      setTotalCost(res.data.rows[0].amount_spent);
      setTotalPost(res.data.rows[0].total_posts);
    }
  };

  const getPostLinkData = async () => {
    const campaignId = props?.selectCampaign?.campaign_id;
    const res = await analyticsService.getAnalyticsLinks(campaignId);
    if (res.data.status) {
      setAddedLinksDetails(res.data.rows);

      //Today Date
      let currentDate = dayjs().format("YYYY-MM-DD HH:mm");
      // let currentDate = "2024-08-09 09:50"
      // Add 72 hours to the refreshed_at timestamp
      let refreshedAtTimestamp = dayjs(addedLinksDetails[0]?.refreshed_at).add(
        72,
        "hour"
      );
      // Format the new timestamp as "YYYY-MM-DD HH:mm"
      const newRefreshTimeStamp =
        refreshedAtTimestamp.format("YYYY-MM-DD HH:mm");

      if (
        currentDate >= newRefreshTimeStamp &&
        addedLinksDetails[0]?.refreshed_count === 1
      ) {
        setButtonClass("btn btn-primary");
      } else if (
        currentDate >= newRefreshTimeStamp &&
        addedLinksDetails[0]?.refreshed_count === 2
      ) {
        setButtonClass("btn btn-primary");
      } else if (
        addedLinksDetails[0]?.refreshed_count === 3 ||
        addedLinksDetails[0]?.refreshed_count === 1
      ) {
        setButtonClass("btn btn-primary disabled");
      }
    }
  }


  const refreshAnalytics = async () => {
    const campaign_id = props?.selectCampaign?.campaign_id;
    const res = await analyticsService.analyticsRefreshInight({ campaign_id });

    if (res.data.status) {
      setOpenModal(true);
    }
    getPostLinkData();
  }


  const likesOptions = {
    chart: {
      type: "line",
    },
    title: {
      text: "Likes Over Time",
    },
    xAxis: {
      type: "datetime",
      categories: graphData.map((entry: any) => entry.date),
    },
    yAxis: {
      title: {
        text: "Likes",
      },
    },
    series: [
      {
        name: "Date",
        data: graphData.map((entry: any) => entry.likes),
      },
    ],
    exporting: {
      enabled: false,
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            "printChart",
            //'downloadPNG',
            "downloadJPEG",
            "downloadPDF",
            //'downloadSVG',
            "downloadCSV",
            "downloadXLS",
          ],
        },
      },
    },
  };

  const playsOptions = {
    chart: {
      type: "line",
    },
    title: {
      text: "Plays Over Time",
    },
    xAxis: {
      type: "datetime",
      categories: graphData.map((entry: any) => entry.date),
    },
    yAxis: {
      title: {
        text: "Total Views",
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Total Views",
        data: graphData.map((entry: any) => entry.video_plays),
      },
    ],
    exporting: {
      enabled: false,
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            "printChart",
            //'downloadPNG',
            "downloadJPEG",
            "downloadPDF",
            //'downloadSVG',
            "downloadCSV",
            "downloadXLS",
          ],
        },
      },
    },
  };
  const commentOptions = {
    chart: {
      type: "line",
    },
    title: {
      text: "Comments Over Time",
    },
    xAxis: {
      type: "datetime",
      categories: graphData.map((entry: any) => entry.date),
    },
    yAxis: {
      title: {
        text: "Total Comments",
      },
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        name: "Total Comments",
        data: graphData.map((entry: any) => entry.comments),
      },
    ],
    exporting: {
      enabled: false,
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            "printChart",
            //'downloadPNG',
            "downloadJPEG",
            "downloadPDF",
            //'downloadSVG',
            "downloadCSV",
            "downloadXLS",
          ],
        },
      },
    },
  };
  const erOptions = {
    chart: {
      type: "line",
    },
    title: {
      text: "ER Over Time",
    },
    xAxis: {
      type: "datetime",
      categories: graphData.map((entry: any) => entry.date),
      format: "{value:.2f}",
    },
    yAxis: {
      title: {
        text: "Average ER %",
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Average ER %",
        data: graphData.map((entry: any) => parseFloat(entry.er.toFixed(2))),
      },
    ],
    tooltip: {
      pointFormat: "Avg ER: {point.y}%",
    },
    exporting: {
      enabled: false,
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            "printChart",
            //'downloadPNG',
            "downloadJPEG",
            "downloadPDF",
            //'downloadSVG',
            "downloadCSV",
            "downloadXLS",
          ],
        },
      },
    },
  };


  const handleOpenUpgradeBrandModal = (value: boolean) => {
    setOpenUpgradeBrandModal(true);
  };

  function handleClosePopUps() {
    setOpenUpgradeBrandModal(false);
    setOpenModal(false);
  }

  const handleDeleteRecord = async () => {
    try {
      if (deleteUrlId) {
        const response = await analyticsService.analyticsDeleteUrl({ url_id: deleteUrlId });
        if (response.data.status) {
          toast.success(response.message)
          analyticsData();
          getPostLinkData();

        } else {
          toast.error(response.message)
        }
      }
    } catch (error) {
      console.log(`handleDeleteRecord :: ${error}`)
    }
  }

  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const deleteconfirmClose = () => {
    setDeleteUrlId(null)
    
  }

  const handleOpenDeleteURL = () => {
    setOpenDeletePopup(true)
    const bodyClass = document.getElementsByTagName('html')[0];
    if (bodyClass) {
      bodyClass.classList.add("bodyActive");
    }
  }

    const handleCloseDeleteURL = () => {
      setOpenDeletePopup(false)
      deleteconfirmClose();
      const bodyClass = document.getElementsByTagName('html')[0];
      if (bodyClass) {
        bodyClass.classList.remove("bodyActive");
      }
    } 

  const [openAddUser, setOpenAddUser] = useState(false)
  const openAddShareUser = () => {
    setOpenAddUser(true);
  };
  const closeAddShareUser = () => {
    setOpenAddUser(false);
  };

  const [openInitalSharePopup, setOpenInitalSharePopup] = useState(false)
   const [openWhatsAppPopup, setOpenWhatsAppPopup] = useState(false)
  const openInitialSharePopup = () => {
    setOpenInitalSharePopup(true);
  };
  const closeInitialSharePopup = () => {
    setOpenInitalSharePopup(false);
  };
  const closeWhatsAppSharePopup = () => {
    setOpenWhatsAppPopup(false);
  };
  const [inviteInput, setInviteInput] = useState("");
  const [errorEmail, setErrorEmail] = useState<ErrorTypes>();
  const [userShareList, setUserShareList] = useState<any[]>([])
  const [inviteEnabled, setInviteEnabled] = useState<boolean>(false)
  const handleEmailChange = async (e:any) => {
    const value = e.target.value;
    setInviteInput(value);

    // Validate the email format
    let isValid = formValidation.GetEmailControlIsValid(value);
    setErrorEmail(isValid);
    if (isValid.status) {
      setInviteEnabled(true);

      // Check if the email already exists in the database
      try {
        const response = await analyticsService.checkEmailExists({
          campaign_id: props?.selectCampaign?.campaign_id,
          email: inviteInput
        });

        if (response.exists) {
          setErrorEmail({
            status: true,
            message: response.message,
          });
          setInviteEnabled(false);
        }
      } catch (error) {
        console.error("Error checking email existence", error);
      }
    } else {
      setInviteEnabled(false);
    }
  };


  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter' && inviteEnabled) {
      handleSubmitInvite(e); // Submit if Enter is pressed and input is valid
    }
  };
  const handleSubmitInvite = async (e:any) => {
    e.preventDefault();
    try{
      if(!inviteInput) {
        setErrorEmail({
          status: true,
          message: "Email is required",
        })
        return
      }
      const emailExists = userShareList.some(
        (user: { email: string }) => user.email === inviteInput
      );

      if (emailExists) {
        setErrorEmail({
          status: true,
          message: "Email already in the list",
        });
        return;
      }
      // Check if the email exists in the database
      const response = await analyticsService.checkEmailExists({
        campaign_id: props?.selectCampaign?.campaign_id,
        email: inviteInput
      });
      if (response.exists) {
        setErrorEmail({
          status: true,
          message: response.message,
        });
        return;
      }
      setUserShareList((prevList: any) => [
        ...prevList,
        { id: new Date().getTime(), email: inviteInput }
      ]);
      
      setInviteInput("");
      setInviteEnabled(false)
    }
    catch(err){
      console.log("error", err)
    }
    
    
  }

  const [openDeleteInfluencer, setOpenDeleteInfluencer] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);

  const [openSharedDeleteUser, setOpenSharedDeleteUser] = useState(false);
  const handleOpenDeleteInfluencer = (id: number) => {
    setOpenDeleteInfluencer(true)
    setSelectedUserId(id)
    document
      .getElementsByTagName('body')[0]
      .classList.add('custom-drawer-active');

  }
  const handleOpenSharedDeleteUser = (id:number) => {
    setOpenSharedDeleteUser(true)
    setSelectedUserId(id)
    document
      .getElementsByTagName('body')[0]
      .classList.add('custom-drawer-active');
  
  }
  const handleOpenSharedDeleteUserClose = () => {
    setOpenSharedDeleteUser(false);
    document
      .getElementsByTagName('body')[0]
      .classList.remove('custom-drawer-active');
  }
  // const handleDeleteInfluencer = (id: number) =>{
  //   const deletedInfluencer = userShareList.filter(user => {
  //     return user.id !== id
  //   })
  //   setUserShareList(deletedInfluencer)
  // }

  const handleDeleteInfluencer = (id: number) => {
    const updatedList = userShareList.filter((user) => user.id !== id);
    setUserShareList(updatedList);
    setSelectedUserId(id)
  };
  const handleDeleteInfluencerClose = () => {
    setOpenDeleteInfluencer(false);
    document
      .getElementsByTagName('body')[0]
      .classList.remove('custom-drawer-active');
  };

  
  
  const handleSendSharedList = async () => {
    // Prepare the payload with campaignId and userShareList
    const campaign_id = props?.selectCampaign?.campaign_id;
    const platformType = props?.selectCampaign?.campaign_platform;
    if (!campaign_id || !userShareList || userShareList.length === 0) {
      console.error("Invalid data: Campaign ID or user share list is missing.");
      return; // Early return if validation fails
    }
    const planID = Number(urlParams.get('planID'));
    const payload = {
      campaign_id, planID,  userShareList, platformType
    };
    // Send the payload to the analytics service
    await analyticsService.analyticsShareLinks(payload);
    const response = await analyticsService.analyticsSharedList(campaign_id);
    
    setSharedUserList(response)
    if (props?.selectCampaign?.campaign_id) {
      fetchSharedList();
    }
    setUserShareList([]);
  };

  const fetchSharedList = async () => {
    const campaign_id = props?.selectCampaign?.campaign_id;
    const urlParams = new URLSearchParams(window.location.search);
    const planID = Number(urlParams.get('planID'));
    if (!campaign_id) return; // Prevent fetching if campaignId is undefined
    const data= {campaign_id, planID}
    // Fetch the shared list using the campaignId
    const res = await analyticsService.analyticsSharedList(data);
    try {
      if (res.data.status) {
        setSharedUserList(res.data.rows)
      }
    }
    catch (error) {
      console.log("FETCHING USER SHARED LIST", error)
    }
  };
  useEffect(() => {
    if (props?.selectCampaign?.campaign_id) {
      fetchSharedList();
    }
  }, [props?.selectCampaign?.campaign_id]);


  const handleDeleteSharedUser = async (id: number) => {
    const campaign_id = props?.selectCampaign?.campaign_id;
    try {
      const res = await analyticsService.deleteSharedUser({ campaign_id: campaign_id, id: id });
      if (props?.selectCampaign?.campaign_id) {
        fetchSharedList(); // Only call fetchSharedList when campaignId is defined
      }
      // Handle success response
    } catch (error) {
      console.log("Error deleting user:", error);
      // Handle error response
    }
  };
let shareCampaignId = props?.selectCampaign?.campaign_id;
let sharePlatformType = props?.selectCampaign?.campaign_platform;
const [shareURL,setShareURL] = useState("")
const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchShareURL = async () => {
      try {
        const res = await analyticsService.analyticsSharePublicLink({
          campaign_id: shareCampaignId,
          campaignPlatform: sharePlatformType,
        });

        if (res?.data?.status) {
          const url = res?.data?.url;
          setShareURL(url || ""); // Update the URL state
        } else {
          console.error("Error fetching URL:", res?.data?.message);
        }
      } catch (error) {
        console.error("Error while fetching WhatsApp share URL:", error);
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchShareURL(); // Call the async function
  }, [shareCampaignId, sharePlatformType]);
  const handleCopyShareLink = async () =>{
    if (!shareCampaignId || !sharePlatformType) {
    console.error("Campaign ID or Platform Type is missing.");
    return;
  }

  try {
    const res = await analyticsService.analyticsSharePublicLink({
      campaign_id: shareCampaignId,
      campaignPlatform: sharePlatformType,
    });

    if (res.data.status) {
      const url = res.data.url;
      setShareURL(url); // Update the URL state
      navigator.clipboard.writeText(url);
      localService.toastify('Link Copied', 'success', 1000);
    } else {
      console.error("Error fetching URL:", res.data.message);
    }
  } catch (error) {
    console.error("Error while fetching WhatsApp share URL:", error);
  }
    
  }

  return (
    <>
      {openModalAddPost && (
        <AddAnalytics
          open={openModalAddPost}
          onClose={() => setOpenModalAddPost(false)}
          campaign_id={campaignId}
          post_platform={campaignPlatform}
          sm_id={smId}
          totalPost={totalPost}
          getSubscriptionDetail={props.getSubscriptionDetail}
          handleOpenUpgradeBrandModal={handleOpenUpgradeBrandModal}
          campaignType={props.selectCampaign.campaign_type}
          onUpdatePostLinks={getPostLinkData}
        />
      )}
      {openModal && (
        <CommonPopUp
          modalTitle={""}
          modalContent=""
          openModal={openModal}
          onClose={handleClosePopUps}
          className="modal-medium mx-auto"
        >
          <div className="">
            <div>
              <p>It takes a few minutes to get the data.Till we do the task,
                sit back and relax or just have a cup of tea.</p>
            </div>

            <div className='popup-footer' style={{ marginTop: "32px" }}>
              <div className='row'>
                <div className='col text-end'>
                  <a className="btn btn-primary ms-2 btn-mui" onClick={handleClosePopUps}>
                    Ok
                  </a>
                </div>
              </div>
            </div>
          </div>
        </CommonPopUp>
      )}
      <div className="brand-analytics detailed-analytics">
        <div className="detailed-analytics-content">
          <div className="button-div">
            <span
              className="btn btn-primary add"
              onClick={(e) => {
                handleOpenModalAnalytics(
                  props.selectCampaign.campaign_id,
                  props.selectCampaign.campaign_platform
                );
              }}
            >
              Add Post
            </span>

            {props.getSubscriptionDetail &&
              props.getSubscriptionDetail.plan_id === 9 && (
                <button
                  className={buttonClass}
                  onClick={refreshAnalytics}
                >
                  Refresh Insight
                </button>
              )}
            <div className="d-flex ms-auto ">
              <a href="#" className="btn btn-outline-primary" onClick={() => setOpenModalROI(true)}> ROI Generator</a>
              <span className="cursor-pointer btn btn-primary ms-2 d-flex align-items-center" onClick={openInitialSharePopup}>
                <MaterialSymbol className="me-1" iconName="share" fontweight="200" fontSize="18px" />
                SHARE
              </span>
            </div>
          </div>
          <div className="upper-grid">
            <AnalyticsDashboard
              postFilterData={postFilterData}
              analyticsCumulativeDataMediaData={
                analyticsCumulativeDataIgMediaData
              }
              campaign_platform={
                props?.selectCampaign?.campaign_platform
              }
              analyticsCumulativeDataNumericData={
                analyticsCumulativeDataIgNumericData
              }
              totalCost={totalCost}
              totalPost={addedLinksDetails?.length}
            />
          </div>
        </div>
        
       {((addedLinksDetails && addedLinksDetails.length > 0) && postFilterData.length === 0 && !openWhatsAppPopup) && (
        <>
          <strong className="d-block mb-2">Post Links:</strong>
          <div className="overflow-hidden" style={{zIndex:'1 !important'}}>
            <div className="post-links row">
              {addedLinksDetails && addedLinksDetails?.map((postLink:any)=>{
              return <div className="col-md-3">{loading ? <Skeleton /> : <InstagramEmbed embedUrl={postLink?.post_url} />}</div>
              })}
            </div>
          </div>
        </>
        )}
        {postFilterData.length === 0 && addedLinksDetails.length === 0 && <>
        
        <strong className="pb-3 d-block">Sample analytics report <span className="fs-12">(Once the links are added, the analytics will be visible as shown below)</span></strong>
        <SampleDetailedAnalytics sampleMostEngagingInfluencers={sampleMostEngagingInfluencers} sampleInfluencerDetailedData={sampleInfluencerDetailedData} samplePostDetailedData={samplePostDetailedData} sampleMostEngagingPosts={sampleMostEngagingPosts} platform={props.selectCampaign?.campaign_platform} /></>}
        {(() => {
          if (postFilterData.length > 0 && addedLinksDetails.length > 0) {
            return (
              <>
                <div className="toggle-button-div sec-stockchart-w-tab">
                  <TabContext value={value}>
                    <TabList className="nav sub-tabs" onChange={handleChange}>
                      <Tab
                        className="nav-link"
                        label={`Total Views`}
                        value="1"
                        icon={
                          <span className="material-symbols-rounded">
                            play_arrow
                          </span>
                        }
                        style={{
                          textTransform: "none",
                          fontSize: "16px",
                          flexGrow: 1,
                        }}
                      />
                      <Tab
                        className="nav-link"
                        label={`Total Likes`}
                        value="2"
                        icon={
                          <span className="material-symbols-rounded">
                            favorite
                          </span>
                        }
                        style={{
                          textTransform: "none",
                          fontSize: "16px",
                          flexGrow: 1,
                        }}
                      />
                      <Tab
                        className="nav-link"
                        label={`Total Comments`}
                        value="3"
                        icon={
                          <span className="material-symbols-rounded">
                            chat_bubble
                          </span>
                        }
                        style={{
                          textTransform: "none",
                          fontSize: "16px",
                          flexGrow: 1,
                        }}
                      />
                      <Tab
                        className="nav-link"
                        label={`ER`}
                        value="4"
                        icon={
                          <span className="material-symbols-rounded">
                            percent
                          </span>
                        }
                        style={{
                          textTransform: "none",
                          fontSize: "16px",
                          flexGrow: 1,
                        }}
                      />
                    </TabList>

                    <TabPanel value="1" className="px-0">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={playsOptions}
                      />
                    </TabPanel>
                    <TabPanel value="2" className="px-0">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={likesOptions}
                      />
                    </TabPanel>
                    <TabPanel value="3" className="px-0">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={commentOptions}
                      />
                    </TabPanel>
                    <TabPanel value="4" className="px-0">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={erOptions}
                      />
                    </TabPanel>
                  </TabContext>
                </div>

                
                  <div className="d-flex flex-column">
                    <strong className="me-auto mb-1">
                      Most Engaging Posts
                    </strong>
                    <>
                      {(() => {
                        const sortedData = [...postFilterData]
                          .sort((a: any, b: any) =>
                            b.selectedValue > a.selectedValue
                              ? 1
                              : b.selectedValue < a.selectedValue
                              ? -1
                              : 0
                          )
                          .slice(0, 5);
                        return (
                          <MostEngagingPosts
                            sortedData={sortedData}
                            selectCampaign={props?.selectCampaign}
                          />
                        );
                      })()}
                    </>
                    {/* )} */}
                  </div>
                  
                    <div className="analytics-most-engaging">
                      <div className="d-flex flex-column">
                        <strong className="mb-1">
                          Most Engaging Influencers
                        </strong>
                        {(() => {
                          const sortedData = influencerFilterData
                            .sort((a: any, b: any) =>
                              b.total_er > a.total_er
                                ? 1
                                : b.total_er < a.total_er
                                ? -1
                                : 0
                            )
                            .slice(0, 5);
                          return (
                            <MostEngagingInfluencer
                              sortedData={sortedData}
                              selectCampaign={props?.selectCampaign}
                            />
                          );
                        })()}
                      </div>
                    </div>
                    <TabContext value={value2}>
                      <TabPanel value="1" className='px-0'>
                        <div className="toggle-button-div3">
                          <TabContext value={value4}>
                            <>
                              <TabList
                                onChange={handleChange4}
                                className="filter-tab-list nav sub-tabs mb-3"
                              >
                                <Tab className='nav-link'
                                  label={`Posts`}
                                  value="1"
                                  style={{ textTransform: "none", fontSize: "16px" }}
                                />
                                <Tab className='nav-link'
                                  label={`Influencers`}
                                  value="2"
                                  style={{ textTransform: "none", fontSize: "16px" }}
                                />
                              </TabList>
                            </>
                            <TabPanel value="1" className='px-0 pt-0'>
                              <div className="post-table">
                                <>
                                  <DetailedInsightsPost columnDisplayName={columnDisplayNamePostDetailedData}
                                    orderByColumnName=""
                                    inputDataSource={postFilterData}
                                    sortingColumnType={PostDetailedData}
                                    renderData={postFilterData}
                                    setRenderData={(e: any) => setPostFilterData(e)}
                                    selectCampaign={props?.selectCampaign}
                                    selectDeleteRecord={setDeleteUrlId}
                                    handleOpenDeleteURL={handleOpenDeleteURL} />
                                </>

                              </div>
                            </TabPanel>
                            <TabPanel value="2" className='px-0 pt-0'>
                              <div className="influencer-table">

                                <DetailedInsightsInfluencer columnDisplayName={columnDisplayNameInfluencerDetailedData}
                                  orderByColumnName=""
                                  inputDataSource={influencerFilterData}
                                  sortingColumnType={InfluencerDetailedData}
                                  renderData={influencerFilterData}
                                  setRenderData={(e: any) => setInfluencerFilterData(e)}
                                  selectCampaign={props?.selectCampaign} />
                              </div>
                            </TabPanel>
                          </TabContext>
                        </div>
                      </TabPanel>
                    </TabContext>
              </>
            );
          }
        })()}
        {openUpgradeBrandModal && (
          <UpgradeBrandPopUp
            openUpgradeBrandModal={openUpgradeBrandModal}
            onClose={() => handleClosePopUps()}
            popupMinWidth="630px"
          ></UpgradeBrandPopUp>
        )}
      </div>
      
      {openModalROI && <ROI open={openModalROI} likes={analyticsCumulativeDataIgNumericData.total_likes} er={analyticsCumulativeDataIgNumericData.total_er} plays={analyticsCumulativeDataIgNumericData.total_plays} views={0} comments={0} user_id={props.UserReducer.user_id} account_id={props.UserReducer.account_id} brand_id={props.selectCampaign.brand_id} influencer_id={props.UserReducer.influencer_id} campaign_id={props.selectCampaign.campaign_id} platform={"Instagram"} onClose={() => setOpenModalROI(false)} />}
     {deleteUrlId && createPortal(
        <ConfirmPopup extraClass="post-analytics-delete-popup"
          confirmPopupInnerText="Are you sure, you want to delete this post."
          openPopup={openDeletePopup}
          confirmClosePopup={
            handleCloseDeleteURL // Call the function to close the popup
          }
          confirmClickAction={handleDeleteRecord}
          drawerTitle=""
        />,
        document.body
      )}
      
      {createPortal(
        <CommonPopUp 
        className="modal-medium share-container"
          openModal={openAddUser}
          modalTitle="Share"
          onClose={closeAddShareUser}
      >
        <FormShareAnalytics userShareList={userShareList} handleEmailChange={handleEmailChange} errorEmail={errorEmail} inviteEnabled={inviteEnabled} handleOpenDeleteInfluencer={handleOpenDeleteInfluencer} handleSubmitInvite={handleSubmitInvite} handleKeyPress={handleKeyPress} inviteInput={inviteInput} handleSendSharedList={handleSendSharedList} handleOpenSharedDeleteUser={handleOpenSharedDeleteUser} sharedUserList={sharedUserList}  />
          
        
        </CommonPopUp>
      , document.body)}
      {/* SHARE Popup */}
      {createPortal(
        <CommonPopUp 
        className="modal-medium share-container"
          openModal={openInitalSharePopup}
          modalTitle="Share"
          onClose={closeInitialSharePopup}
      >
        <div className="analytic-share-option d-flex align-items-center mb-3">
          <strong className="analytic-share-option-title">Share via:</strong>
          <div className="analytic-share-option-container">
            <button disabled onClick={openAddShareUser} className="btn btn-outline-primary d-inline-flex">
              <MaterialSymbol iconName="email" fontweight="200" />
              Email
            </button>
            <span className="btn btn-outline-primary d-inline-flex react-share-btn">
            <WhatsappShareButton
                url={shareURL || ""}
                className='Demo__some-network__share-button'
                disabled={!shareURL}
                onClick={() => {
                console.log("WhatsApp share clicked");
              }}
              >
                <WhatsappIcon style={{ color: '#25D366' }} />
                <span className='share-titles'>WhatsApp</span>
              </WhatsappShareButton>
            </span>
            
            <button onClick={handleCopyShareLink} className="btn btn-outline-primary d-inline-flex">
              <MaterialSymbol iconName="link" fontweight="200" />              
              Copy Link
            </button>
          </div>
        </div>
        </CommonPopUp>
      , document.body)} 
      {/* SHARE WHATSAPP Popup */}
      
        <CommonPopUp 
        className="modal-medium share-container"
          openModal={openWhatsAppPopup}
          modalTitle="Share"
          onClose={closeWhatsAppSharePopup}
      >
        <strong className="d-block mb-2">Share via</strong>
        <div className="d-flex gap-3 mb-3">
            <WhatsappShareButton
              url={shareURL}
              className='Demo__some-network__share-button'
            >
              <WhatsappIcon style={{ color: '#25D366' }} />
              <span className='share-titles'>WhatsApp</span>
            </WhatsappShareButton>
        </div>
          
        
        </CommonPopUp>
       
      <> {selectedUserId !== null && createPortal(
        <ConfirmPopup popupId="delete-influencer" extraClass="delete-added-influencer" confirmPopupInnerText="Are you sure want to delete the invited influencer." openPopup={openDeleteInfluencer} confirmClosePopup={handleDeleteInfluencerClose} confirmClickAction={() => handleDeleteInfluencer(selectedUserId!)} drawerTitle="" />, document.body)
      }</>
      <> {selectedUserId !== null && createPortal(
        <ConfirmPopup popupId="delete-influencer" extraClass="delete-added-influencer" confirmPopupInnerText="Are you sure want to delete the shared influencer." openPopup={openSharedDeleteUser} confirmClosePopup={handleOpenSharedDeleteUserClose} confirmClickAction={() => handleDeleteSharedUser(selectedUserId)} drawerTitle="" />, document.body)
      }</>
     
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
    UserID: state.UserID.userID,
    selectCampaign: state.InfSelectCamp.campaign,
    getSubscriptionDetail: state.Subscription.subscription_detail,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailedAnalytics);

export { connectToRedux as DetailedAnalytics };
